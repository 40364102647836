import React from "react";
import {colors} from "config/StyleGuide";
import Icon from "components/shared/Icon";
import styled from "@emotion/styled";

const FilterMenuItem = ({ filter, onAdd, onClear, isActive, subValue }) => {
  const toggleFilter = () =>
    !isActive(filter) ? onAdd(filter) : onClear(filter);

  return (
    <Link onClick={toggleFilter} key={filter.id}>
      <Container>
        <Value active={isActive(filter)}>{filter.name}</Value>
        {subValue && <SubValue>{subValue}</SubValue>}
        {isActive(filter) && <CloseIcon />}
      </Container>
    </Link>
  );
};

export const Link = styled.div`
  text-decoration: none;
  color: black;

  margin-top: -2px;

  &:hover {
    cursor: pointer;
  }
  user-select: none;
`;

export const Value = styled.span`
  font-family: ${({ active }) => (active ? "SlateProBold" : "SlateProBook")};
  font-size: 14px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  user-select: none;
`;

export const SubValue = styled.span`
  font-family: SlateProBookItalic;
  font-size: 14px;
  color: ${colors.gray};
  letter-spacing: 0.02em;
  display: inline-block;
  padding: 0 5px;
  text-transform: lowercase;
  user-select: none;
`;

export const CloseIcon = ({ onClick }) => (
  <StyledCloseIcon onClick={onClick}>
    <Icon type={"FilterClose"} size={12} fill={colors.warmGraphite} />
  </StyledCloseIcon>
);

// todo nir: i found this color inside the svg, but it's not in the styleguide
const StyledCloseIcon = styled.div`
  padding-left: 5px;
  display: inline-block;
  padding-top: 1px;
`;

export const Container = styled.li`
  display: flex;
  align-items: center;
  flex-direction: row;

  padding: 4px 0;
  &:last-child {
    padding-bottom: 10px;
  }
`;

export default FilterMenuItem;
