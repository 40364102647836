import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${colors.white};
  border: 1px solid ${colors.gray};
  z-index: 1000;

  &:before {
    position: absolute;
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${colors.coolGray};
    top: -12px;
    ${({ left }) =>
      left &&
      `
    left: ${left}px
    `};
    ${({ right }) =>
      right &&
      `
    right: ${right}px
    `};
  }

  &:after {
    position: absolute;
    content: "";
    top: -12px;
    ${({ left }) =>
      left &&
      `
    left: ${left}px
    `};
    ${({ right }) =>
      right &&
      `
    right: ${right}px
    `};
    border-left: 12px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 14px solid ${colors.white};
  }
`;

export const MenuItem = styled(Link)`
  font-family: SlateProRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 0.15em;
  padding: 10px 20px;
  color: ${colors.gray};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${colors.coolGray};
  &:hover {
    color: ${colors.warmGraphite};
  }
  &:last-child {
    border-bottom: none;
  }
  cursor: pointer;
  text-decoration: none;
  user-select: none;
`;

export const ExternalMenuItem = MenuItem.withComponent("a");

export default MenuWrapper;
