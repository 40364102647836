import React from "react";
import Rule from "components/shared/Rule";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";

const EventSatchelHeader = ({ satchelOwner }) => {
  return (
    <>
      <div className="col-2 offset-5">
        <SatchelOf>"The Collection Of"</SatchelOf>
      </div>
      <div className="col-8 offset-2">
        <SatchelName>{satchelOwner}</SatchelName>
      </div>
      <div className="col-10 offset-1">
        <Rule height={1} color={colors.reed} />
      </div>
    </>
  );
};

// todo nir: fix styles (they are missing in invision)
const SatchelOf = styled.div`
  text-align: center;
  font-size: 20px;
  color: ${colors.warmGraphitea};
  margin-top: 20px;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

// todo nir: fix styles (they are missing in invision)
const SatchelName = styled.div`
  text-align: center;
  background-color: ${colors.white};
  height: 70px;
  font-size: 70px;
  margin-bottom: 80px;
`;

export default EventSatchelHeader;
