import styled from "@emotion/styled";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {AuthContext} from "components/context-providers/Auth";
import {PageContainer, PageContent8Columns, PageRowWithPadding, PageSideBar} from "components/shared/Layouts";
import {colors} from "config/StyleGuide";
import React, {useContext, useState} from "react";
import AccountMenu from "../account/shared/AccountMenu";
import EditProfile from "./EditProfile/EditProfile";
import MyProfileCollection from "./MyProfileCollection";
import ViewProfile from "./ViewProfile";

const Profile = ({location}) => {
  useBreadCrumbs({label: "Profile", path: location.pathname});

  const {currentUser, setCurrentUser} = useContext(AuthContext);
  const [isEditting, setIsEditting] = useState(false);

  const onEditProfileClick = () => setIsEditting(true);

  const onSaveClick = ({profilePicture, data}) => {
    console.log(data);

    setCurrentUser({ ...currentUser, ...data });
    setIsEditting(false);
  };
  const onCancelClick = () => setIsEditting(false);

  return (
      <PageContainer>
        <PageRowWithPadding>
          <PageSideBar>
            <AccountMenu selectedItemName="profile"/>
          </PageSideBar>
          <PageContent8Columns>
            <ContentWrapper>
              {isEditting ? (
                  <EditProfile
                      currentUser={currentUser}
                      onSave={onSaveClick}
                      onCancel={onCancelClick}
                  />
              ) : (
                  <ViewProfile
                      onEditProfileClick={onEditProfileClick}
                      currentUser={currentUser}
                  />
              )}
            </ContentWrapper>
          </PageContent8Columns>
        </PageRowWithPadding>
        <PageRowWithPadding>
          <div className="col-8 offset-2">
            <MyProfileCollection/>
          </div>
        </PageRowWithPadding>
      </PageContainer>
  );
};

const ContentWrapper = styled.div`
  background-color: ${colors.white};
  height: 100%;
`;

export default Profile;
