import React from 'react';
import {UnstyledLink} from "../../../shared/UnstyledLink";
import satchelsListItemImage from "../../Satchels/satchels_list_item.png";
import {getPronoun} from "../../../../services/filters-utils";
import styled from "@emotion/styled";
import {colors} from "../../../../config/StyleGuide";

const PersonSatchelLink = ({person}) => {

    if(!person.satchel_id) return null;

    return (
        <ContentColumnWrapper>
            <UnstyledLink to={`/satchels/${person.satchel_id}`}>
                <Image src={satchelsListItemImage} alt=""/>
                <Name>{`Go to ${getPronoun(person.gender).toLowerCase()}`}<br/>Satchel</Name>
            </UnstyledLink>
        </ContentColumnWrapper>
    );
};

const ContentColumnWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  height: 200px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
`;


const Name = styled.div`
  font-family: SangBleuVersaillesRegular,sans-serif;
  font-size: 22px;
  letter-spacing: 0.1em;
  line-height: 30px;
  color: ${colors.white};
  position: absolute;
  left: 50%;
  top: 50%;
  width: 140px;
  transform: translate(-50%, -50%);
`;

export default PersonSatchelLink;