import React from "react";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import {colors} from "../../../../config/StyleGuide";

const FILES_IDS = [2851, 2862];

const BenStrip = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1] = Files;

  return (
    <StripContainer backgroundColor="#EDEDE2">
      <MediaViewer />
      <RowOverlay>
        <div className="col-1" style={{ marginTop: "250px" }}>
          <StripLegend title="Ben" color={colors.warmGraphite} anchor="ben">
            <Section>
              <div>left:</div>
              <div>Massachusetts, USA</div>
              <YearLabel>1959</YearLabel>
            </Section>
            <Section>
          <span id={"ben"}/>
              <div>right:</div>
              <div>Antarctica</div>
              <YearLabel>2007</YearLabel>
            </Section>
          </StripLegend>
        </div>
      </RowOverlay>
      <RowOverlay>
        <div className="col-8" style={{ marginTop: "150px", position: "absolute", right: 118 }}>
          <ImagesWrapper>
            <div style={{ width: "350px" }}>
              <File0 />
            </div>
            <div style={{ width: "570px" }}>
              <File1 />
            </div>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default BenStrip;
