import React, {useRef} from "react";
import styled from "@emotion/styled";
import MediaActions from "components/shared/media-actions/MediaActions";
import DownloadMediaAction from "components/shared/media-actions/DownloadMediaAction";
import ToggleMediaFullScreenAction from "components/shared/media-actions/ToggleMediaFullScreenAction";
import {getFileType, getMediaFileUrl, getPreviewUrl} from "../../../services/media-file.utils";
import LaunchPdfButton from "../../shared/LaunchPdfButton";

const FilePreview = ({mediaFile}) => {
  const imageRef = useRef(null);

  const previewUrl = getPreviewUrl(mediaFile);
  const fileUrl = getMediaFileUrl(mediaFile);
  const mediaType = getFileType(mediaFile);

  const toggleFullScreen = () => {
    switch (mediaType) {
      case 2:
      case 5:
        // both image and PDF files will be opened on a new tab
        window.open(fileUrl);
        break;
      case 4:
        window.open(fileUrl);
        break;
      default:
        void 0;
    }
  };

  return (
      <div className="col-4 offset-1">
        <ImageWrapper>

          {mediaType !== 4 && (
              <MediaActions>
                <ToggleMediaFullScreenAction onClick={toggleFullScreen}/>
                <DownloadMediaAction media={fileUrl}/>
              </MediaActions>
          )}

          <Image ref={imageRef} src={previewUrl} alt=""/>

          <ButtonWrapper>
            {mediaType === 4 && (
                <PdfButtonWrapper>
                  <LaunchPdfButton
                      label={"launch video"}
                      onClick={() => window.open(fileUrl)}
                  />
                </PdfButtonWrapper>
            )}

            {mediaType === 5 && (
                <PdfButtonWrapper>
                  <LaunchPdfButton
                      label={"launch pdf"}
                      onClick={() => window.open(fileUrl)}
                  />
                </PdfButtonWrapper>
            )}
          </ButtonWrapper>
        </ImageWrapper>
      </div>
  );
};

const ButtonWrapper = styled.div`  
  padding-right: 15%;
`

const ImageWrapper = styled.div`
  margin-top: 70px;
  position: relative;  
`;

const Image = styled.img`
  width: 396px;
  height: auto;
`;

const PdfButtonWrapper = styled.div`
  display: flex;
  padding: 25px 0 ;
  align-items: center;
  justify-content: center;
`

export default FilePreview;
