import React, {useEffect, useRef} from "react";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import mapboxgl from 'mapbox-gl';
import flyHome from './images/fly_home_icon.png';

const token = process.env.REACT_APP_MAPBOX_TOKEN;

const InteractiveMap = ({lon, lat, zoom}) => {
  const mapRef = useRef();
  const mapInstance = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = token;
    const coords = [lon, lat];

    const map = new mapboxgl.Map({
      container: mapRef.current,
      style: 'mapbox://styles/bgoldhagen/ck0o6fyy017no1cnw456pbk3v',
      center: coords,
      zoom: zoom,
      interactive: true,
      boxZoom: true,
      dragPan: true
    });

    map.addControl(new mapboxgl.NavigationControl());
    map.dragPan.enable();

    new mapboxgl.Marker()
        .setLngLat(coords)
        .addTo(map);

    map.dragPan.enable();
    mapInstance.current = map;
  }, [lon, lat]);

  const reCenter = () => {
    mapInstance.current.flyTo({
      center:[lon, lat],
      zoom: zoom,
    })
  };

  if(!lon || !lat) return <NoCoordinates>No Coordinates</NoCoordinates>

  return (
      <>
        <FlyHomeIcon src={flyHome} alt="" onClick={reCenter}/>
        <MapContainer ref={mapRef}/>
        </>
  )
};

const FlyHomeIcon = styled.img`
  position: absolute;
  z-index: 9999;
  cursor: pointer;
`;

const NoCoordinates = styled.span`
  width: 100%;
  text-align: center;
  font-size: 48px;
  color: ${colors.vermillion};
  opacity: 0.4;
`;

const MapContainer = styled.div`  
  width: 100%;
  height: 100%;
  outline: none;
  position: absolute; 
  z-index: 999; 
  background-color: ${colors.lightGray};
`;


export default InteractiveMap;
