import React from 'react';
import Menu from "../../../shared/Menu";
import {sortByName} from "../../../../services/filters-utils";
import {ENTITIES} from "../../../../config/constants";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";
import {useResource} from "../../../../hooks/useResource";

const RelatedPlacesFilter = ({place, onAdd, onClear, isActive}) => {
  const [childLocations] = useResource({path: `locations/children/?location_id=${place.id}`});

  const renderLocation = (location) => {
    const locationFilter = {...location, entity: ENTITIES.LOCATION};

    return (
        <FilterMenuItemTree onAdd={onAdd}
                            onClear={onClear}
                            isActive={isActive}
                            filter={locationFilter}
                            entity={ENTITIES.LOCATION}
                            key={location.id}
        />
    )
  };

  return (
      <>
        {childLocations && (
            <Menu label={"Related places"} itemLength={childLocations.length}>
              {childLocations.sort(sortByName).map(location =>
                  renderLocation(location)
              )}
            </Menu>
        )}
      </>
  );
};

export default RelatedPlacesFilter;