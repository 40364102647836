import React from "react";
import styled from "@emotion/styled";

import {ReactComponent as Album} from "./icons/media_icons/album.svg";
import {ReactComponent as Article} from "./icons/media_icons/article.svg";
import {ReactComponent as Letter} from "./icons/media_icons/letter.svg";
import {ReactComponent as AudioIcon} from "./icons/media_icons/audio.svg";
import {ReactComponent as Blog} from "./icons/media_icons/blog.svg";
import {ReactComponent as Book} from "./icons/media_icons/book.svg";
import {ReactComponent as Doc} from "./icons/media_icons/doc.svg";
import {ReactComponent as Drawing} from "./icons/media_icons/drawing.svg";
import {ReactComponent as FamTree} from "./icons/media_icons/fam-tree.svg";
import {ReactComponent as GovDoc} from "./icons/media_icons/gov-doc.svg";
import {ReactComponent as Interview} from "./icons/media_icons/interview.svg";
import {ReactComponent as Manuscript} from "./icons/media_icons/manuscript.svg";
import {ReactComponent as Map} from "./icons/media_icons/map.svg";
import {ReactComponent as News} from "./icons/media_icons/news.svg";
import {ReactComponent as Photo} from "./icons/media_icons/photo.svg";
import {ReactComponent as Postcard} from "./icons/media_icons/postcard.svg";
import {ReactComponent as Pub} from "./icons/media_icons/pub.svg";
import {ReactComponent as Video} from "./icons/media_icons/video.svg";
import {ReactComponent as Website} from "./icons/media_icons/website.svg";

import {ReactComponent as FilterToggle} from "./icons/Filter_Toggle.svg";
import {ReactComponent as BoxedCloseFilter} from "./icons/BoxedCloseFilter.svg";
import {ReactComponent as Arrow} from "./icons/cta_arrow.svg";
import {ReactComponent as Magnifier} from "./icons/icon-search.svg";
import {ReactComponent as Favorite} from "./icons/fave-sel.svg";
import {ReactComponent as NextActive} from "./icons/carousel-arrow-right-active.svg";
import {ReactComponent as PrevActive} from "./icons/carousel-arrow-left-active.svg";

import {ReactComponent as HeartFill} from "./icons/social_icons/heart-fill.svg";
import {ReactComponent as HeartLine} from "./icons/social_icons/heart-line.svg";
import {ReactComponent as ShareFill} from "./icons/social_icons/share-fill.svg";
import {ReactComponent as ShareLine} from "./icons/social_icons/share-line.svg";

import {ReactComponent as FilterClose} from "./icons/filter-close-new.svg";
import {ReactComponent as ClearSearch} from "./icons/clear-search.svg";
import {ReactComponent as Close} from "./icons/close.svg";
import {ReactComponent as CollectionArrow} from "./icons/collection-arrow.svg";
import {ReactComponent as PlaceSearch} from "./icons/place-search.svg";

import {ReactComponent as Download} from "./icons/download.svg";
import {ReactComponent as FullScreen} from "./icons/full-scrren.svg";

import {ReactComponent as OpenViewerDetails} from "./icons/mediaviewer-contract.svg";
import {ReactComponent as CloseViewerDetails} from "./icons/mediaviewer-expand.svg";
import {ReactComponent as Link} from "./icons/link.svg";
import {ReactComponent as BackToTop} from "./icons/backtotop.svg";
import {ReactComponent as BirthdayCake} from "./icons/birthday_cake.svg";

import {ReactComponent as Satchel} from "./icons/satchel.svg";
import {ReactComponent as Attachment} from './icons/add-attachment.svg';

import {ReactComponent as RemoveSocialLink} from './icons/remove-input.svg';

import {ReactComponent as Confirm} from './icons/confirm.svg';
import {ReactComponent as Alert} from './icons/alert.svg';
import {ReactComponent as Error} from './icons/error.svg';


const iconMap = {
  Confirm,
  Alert,
  Error,
  Attachment,
  OpenViewerDetails,
  CloseViewerDetails,
  Download,
  FullScreen,
  PlaceSearch,
  CollectionArrow,
  FilterClose,
  ClearSearch,
  "Audio Recording": Audio,
  Audio: AudioIcon,
  'audio': AudioIcon,
  Satchel,
  Book: Book,
  Blog: Blog,
  email: Letter,
  Close: Close,
  Correspondence: Letter,
  Document: Doc,
  Drawing: Drawing,
  Artwork: Drawing,
  "Family Tree": FamTree,
  "Government Document": GovDoc,
  Website: Website,
  Interview: Interview,
  Manuscript: Manuscript,
  Map: Map,
  Photo: Photo,
  Photograph: Photo,
  "Photo Album": Album,
  "photo albm": Album,
  Postcard: Postcard,
  publication: Pub,
  Newspaper: News,
  Magazine: Pub,
  Article: Article,
  Video: Video,
  video: Video,
  AppliedFilterClose: Close,
  FilterToggle: FilterToggle,
  BoxedCloseFilter: BoxedCloseFilter,
  Arrow: Arrow,
  Magnifier: Magnifier,
  Favorite: Favorite,
  NextActive: NextActive,
  PrevActive: PrevActive,
  Note: Letter,
  HeartFill,
  HeartLine,
  ShareFill,
  ShareLine,
  Share: ShareLine,
  Link: Link,
  BackToTop,
  BirthdayCake,
  RemoveSocialLink
};

const Icon = props => {
  const {
    width = 24,
    height = 24,
    fill,
    hoverStroke,
    className,
    onClick,
    onMouseIn,
    onMouseOut,
    onMouseOver,
    style,
    type,
    stroke,
    size
  } = props;

  const Component =  type ? iconMap[type.trim()] : null;

  if (!Component) {
    return null;
  }

  return (
    <StyledIcon
      svgWidth={size || width}
      svgHeight={size || height}
      fill={fill}
      className={className}
      hoverStroke={hoverStroke}
      onClick={onClick}
      onMouseOver={onMouseOver}
      style={style}
      stroke={stroke}
      onMouseEnter={onMouseIn}
      onMouseLeave={onMouseOut}
    >
      <Component />
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};

  & svg {
    width: ${({ svgWidth }) =>
      svgWidth ? `${svgWidth}px !important` : "auto"};
    height: ${({ svgHeight }) =>
      svgHeight ? `${svgHeight}px !important` : "auto"};
  }

  & * {
    stroke: ${({ stroke }) => (stroke ? `${stroke} !important` : "")};
    fill: ${({ fill }) => (fill ? `${fill} !important` : "")};
  }

  &:hover * {
    stroke: ${({ hoverStroke }) =>
      hoverStroke ? `${hoverStroke} !important` : ""};
  }
`;
export default Icon;
