import React, {useEffect, useState} from 'react';
import Menu from "../../../shared/Menu";
import {ENTITIES, EPOCHS} from "../../../../config/constants";
import {mediaRangeWithinPersonAgeRange} from "../../../../services/filters-utils";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";
import {useResource} from "../../../../hooks/useResource";
import {useStagesWithinRange} from "../../../../hooks/useStagesWitinRange";

const ClanStagesFilter = ({clan, onAdd, onClear, isActive}) => {
  const [clanAgeRange, isLoading] = useResource({path:`/clans/clan_life_during_range?clan_id=${clan.id}`});
  const stagesWithinRange = useStagesWithinRange(clanAgeRange);

  if(isLoading || !stagesWithinRange.length) return null;

  const renderStages = (stage, idx) => {
    const stageFilter = {
      ...stage,
      entity: ENTITIES.STAGES
    };

    return (
        <FilterMenuItemTree
            key={stageFilter.id + idx}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={stageFilter}
            entity={ENTITIES.STAGES}
        />
    );
  };

  return (
      <Menu label={`their stages`} itemLength={EPOCHS.length}>
        {stagesWithinRange.map((stage, idx) => renderStages(stage, idx))}
      </Menu>
  );
};

export default ClanStagesFilter;
