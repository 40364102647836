import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import Rule from "./Rule";
import {UnstyledLink} from "./UnstyledLink";
import footerLogo from './images/T3T-Logo-Lockup-footer60px.png';

//todo: set the width to take 12 cols
const Footer = () => (
  <FooterContainer>
    <div className="container">
      <div className="row pt-5">
        <div className="col-12">
          <Rule height={1} color={colors.coolGray} />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-8">
          <img src={footerLogo} alt=""/>
        </div>

        <div className="col-4">
          <LinksContainer>
            <FooterLink to="/faq">faq</FooterLink>
            <FooterLink to="/about">about</FooterLink>
            <FooterLink to="/contact">contact</FooterLink>
          </LinksContainer>
        </div>
      </div>
    </div>
  </FooterContainer>
);

const FooterContainer = styled.div`
  background-color: ${colors.white};
  height: 350px;
`;

const LinksContainer = styled.div`
  font-family: SlateProMedium, sans-serif;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${colors.coolGray};

  display: flex;
  justify-content: flex-end;
`;

const FooterLink = styled(UnstyledLink)`
  color: ${colors.coolGray};
  margin-left: 200px;
  &:first-of-type {
    margin-left: 0;
  }
`;

export default Footer;
