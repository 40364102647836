import styled from "@emotion/styled";

export const Cleared = styled.div`
  &:before {
    content: "";
    clear: both;
    display: table;
  }
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

export const Container = styled(Cleared)`
  width: 100%;
  max-width: 1400px;
  min-width: 1400px;
  margin: 0 auto;
`;

export const Row = styled(Cleared)``;

export const Col = styled.div`
  width: 100%;
  float: left;
  min-height: 1px;
`;

export const Col1 = styled(Col)`
  width: 8.33333%;
`;

export const Offset1 = Col1;

export const Col2 = styled(Col)`
  width: 16.66667%;
`;

export const Offset2 = Col2;

export const Col3 = styled(Col)`
  width: 25%;
`;

export const Offset3 = Col3;

export const Col4 = styled(Col)`
  width: 33.33333%;
`;
export const Col5 = styled(Col)`
  width: 41.66667%;
`;
export const Col6 = styled(Col)`
  width: 50%;
`;
export const Col7 = styled(Col)`
  width: 58.33333%;
`;
export const Col8 = styled(Col)`
  width: 66.66667%;
`;
export const Col9 = styled(Col)`
  width: 75%;
`;
export const Col10 = styled(Col)`
  width: 83.33333%;
`;
export const Col11 = styled(Col)`
  width: 91.66667%;
`;
export const Col12 = styled(Col)`
  width: 100%;
`;
