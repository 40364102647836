import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";

const NoResults = ({children}) => {
  return (
      <TextContainer>
        {children}
      </TextContainer>
  );
};

const TextContainer = styled.div`
  font-family: SlateProRegular, sans-serif;
  color: ${colors.warmGraphite};  
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 75px;
`;

export default NoResults;
