import React from 'react';
import {useCollection} from "../../../hooks/useCollection";
import {PageContainer} from "../../shared/Layouts";
import DebugMediaFileGrid from "../../shared/MediaFiles/DebugMediaFilesGrid";

const AllMediaFiles = () => {
  const {collection: mediaFile, hasMore, loadMore} = useCollection("/media_files");

  console.log('all media files');
  
  return (
      <PageContainer>
        <DebugMediaFileGrid
          mediaFiles={mediaFile}
          hasMore={hasMore}
          loadMore={loadMore} />
      </PageContainer>
  );
};

export default AllMediaFiles;
