import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";

const FILES_IDS = [2864, 2863, 2847, 2848];

const PlantEarthStrip = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1, File2, File3] = Files;
  return (
    <StripContainer backgroundColor="#BE9E88" height={1350}>
      <span id={"plantEarth"}/>
      <MediaViewer />
      <RowOverlay>
        <div className="col-1" style={{ marginTop: "915px" }} >
          <StripLegend title="Planet Earth" color={colors.white} anchor={"plantEarth"}>
            <Section>
              <div>left side:</div>
              <div>Mojave Desert (Rhyolite Nevada)</div>
              <YearLabel>2019</YearLabel>
              <YearLabel>Record High Summer Temp (134.0°F/ 56.6°C)</YearLabel>
            </Section>
            <Section>
              <div>right:</div>
              <div>Antarctica (Deception Island)</div>
              <YearLabel>1995</YearLabel>
              <YearLabel>Record Low Winter Temp (-27.8°F/ -18.0°C)</YearLabel>
            </Section>
          </StripLegend>
        </div>
      </RowOverlay>
      <RowOverlay>
        <div className="col-10 offset-1">
          <ImagesWrapper>
            <LeftSide>
              <div
                style={{
                  marginBottom: "25px",
                  width: "570px"
                }}
              >
                <File0 />
              </div>
              <div style={{ width: "450px" }}>
                <File1 />
              </div>
            </LeftSide>
            <RightSide>
              <div
                style={{
                  marginBottom: "25px",
                  width: "450px"
                }}
              >
                <File2 />
              </div>
              <div style={{ width: "570px" }}>
                <File3 />
              </div>
            </RightSide>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 170px;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  align-items: flex-end;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default PlantEarthStrip;
