import React, {useContext, useState} from "react";
import apiRequest from "services/api";
import Rule from "components/shared/Rule";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {PageBackground, PageContainer, PageContent, PageRow, PageSideBar, Row} from "components/shared/Layouts";
import {
  FormActionsContainer,
  FormGroup,
  FormLabel,
  FormWrapper,
  Input,
  SubmitButton,
  TextArea
} from "components/shared/FormElements";
import {H1} from "components/shared/style-guide/typography";
import StaticPageHeader from "components/shared/StaticPageHeader";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {AuthContext} from "../context-providers/Auth";
import MessageAlert from "../shared/MessageAlert";

const Contact = ({location}) => {
  const {currentUser} = useContext(AuthContext);
  const [alert, setAlert] = useState(null);

  useBreadCrumbs({label: "Contact", path: location.pathname});

  const initState = {
    name: '',
    email: '',
    message: ''
  };

  const [state, setState] = useState(initState);

  const onSubmit = async (event) => {
    event.preventDefault();

    if(!state.name || !state.email || !state.message) {
      setAlert({type: 'Error', message: 'All fields are required.'});
      return;
    }

    const formData = {
      name: state.name,
      email_address: state.email,
      message: state.message
    };

    const response = await apiRequest({
      path: `/members/${currentUser.id}/contact`,
      method: "POST",
      data: formData
    });

    if(response.status === 200) {
      setAlert({type: 'Confirm', message: 'Thank you, your message has been sent!'});
      setState(initState);
    }
  };

  return (
      <PageBackground>
        <PageContainer>
          <Row>
            <ContentColumn className="col-6 offset-3">
              {alert && (
                  <div style={{marginTop: 14}}>
                    <MessageAlert type={alert.type} message={alert.message}/>
                  </div>
              )}
              <StaticPageHeader>
                <H1>Contact Us</H1>
              </StaticPageHeader>
              <Rule height={3} color={colors.warmGraphite}/>
              <Subtitle>
                Your thoughts are important to us. Send comments, questions, inquiries about contributing to the
                archive, whatever is on your mind. Someone will get back to you.
              </Subtitle>
            </ContentColumn>
          </Row>
          <Row>
            <div className="col-4 offset-4">
              <StyledFormWrapper noValidate onSubmit={onSubmit}>
                <FormGroup>
                  <FormLabel htmlFor="name">your name</FormLabel>
                  <Input
                      id="name"
                      placeholder="enter your name"
                      value={state.name}
                      onChange={e => setState({...state, name: e.target.value})}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="email">email address</FormLabel>
                  <Input
                      id="email"
                      value={state.email}
                      placeholder="enter your email address"
                      onChange={e => setState({...state, email: e.target.value})}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="message">your message</FormLabel>
                  <StyledTextArea
                      id="message"
                      placeholder="Type your message here..."
                      value={state.message}
                      onChange={e => setState({...state, message: e.target.value})}
                  />
                </FormGroup>
                <FormActionsContainer>
                  <SubmitButton>send message</SubmitButton>
                </FormActionsContainer>
              </StyledFormWrapper>
            </div>
          </Row>
          <PageRow>
            <PageSideBar/>
            <PageContent>
              <Row/>
            </PageContent>
            <PageSideBar/>
          </PageRow>
        </PageContainer>
      </PageBackground>
  );
};

const ContentColumn = styled.div`
  color: ${colors.warmGraphite};
`;

const Subtitle = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 24px;
  line-height: 40px;
  padding-top: 30px;
  padding: 30px 0;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  height: 200px;
`;

const StyledFormWrapper = styled(FormWrapper)`
  padding-bottom: 500px;
`;

export default Contact;
