import {first, get} from "lodash/fp";

export const getCurrentUserInitials = currentUser => {
    return `${first(currentUser.first_name) + first(currentUser.last_name)}`;
}


export const getCurrentUserFullName = currentUser =>
    `${currentUser.first_name}  ${currentUser.last_name}`;


// todo: remove unsued code
// export const getBirthdayString = ({birth_date}) => {
//     const [birthDay] = birthDay ? JSON.parse(JSON.parse(birth_date)) : [""];
//     return new Date(`${birthDay.month}/${birthDay.day}/${birthDay.year}`).toLocaleDateString();
// };

export const getProfilePicture = get(["picture", "thumbnail", "url"]);
