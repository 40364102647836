import React, {useState} from "react";
import MediaThumbnailMeta from "components/shared/MediaThumbnailMeta";
import Thumbnail from "components/shared/Thumbnail";
import styled from "@emotion/styled";
import MediaViewer from "components/shared/media-viewer/MediaViewer";
import {Row} from "components/shared/Layouts";

// todo eilan: consider removing since every consumer actually uses <MediaFilesGrid/>
const MediaFiles = ({ mediaFiles, hasMore, loadMore }) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  return (
    <>
      {currentFileIndex !== null && (
        <MediaViewer
          onCloseModal={() => setCurrentFileIndex(null)}
          mediaFiles={mediaFiles}
          currentFileIndex={currentFileIndex}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      )}

      <Row>
        <div className="col-12">
          <Row>
            {mediaFiles.map((file, fileIndex) => {
              return (
                <React.Fragment key={file.id + fileIndex + (Math.random() * 32) }>
                  <div className="col-3">
                    <ItemContainer
                      onClick={() => setCurrentFileIndex(fileIndex)}>
                      {/* todo nir: add isInMyCollection prop to each mediaFiler to indicate if it's in my collection or not */}
                      <Thumbnail
                        isInMyCollection={file.favorite}
                        file={file}
                      />
                      <MediaThumbnailMeta file={file} />
                    </ItemContainer>
                  </div>
                  <SpaceColumn className="col-1" />
                </React.Fragment>
              );
            })}
          </Row>
        </div>
      </Row>
    </>
  );
};

export default MediaFiles;

const ItemContainer = styled.div`
  margin-bottom: 60px;
  text-decoration: none;
  color: #000;
  flex: 1;
`;

const SpaceColumn = styled.div`
  pointer-events: none;
`;
