import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {colors} from "config/StyleGuide";
import React from "react";
import background from "components/pages/auth/shared/auth-bacground.png";
import {useResource} from "hooks/useResource";
import {LoaderContainer, PageBackground, PageContainer, PageRowWithVerticalPadding} from "components/shared/Layouts";
import CollectionHeader from "./CollectionHeader";
import {useFacetsFilters} from "hooks/filters/useFacetFilters";
import {useGenreFilters} from "hooks/filters/useGenreFilters";
import {useCollection} from "hooks/useCollection";
import MediaFilesGrid from "components/shared/MediaFiles/MediaFilesGrid";
import Loading from "../../shared/Loading";

const CollectionPage = ({ match, location }) => {
  const collectionId = match.params.id;
  const [collection] = useResource({ path: `/collections/${collectionId}` });

  useBreadCrumbs({ label: collection, path: location.pathname });

  const { GenreFilters, genresQueryString } = useGenreFilters({
    path: `/collections/genres/?collection_id=${collectionId}`
  });

  const {
    facetsQueryString,
    isFiltersApplied
  } = useFacetsFilters();

  const buildQuery = (...queries) => queries.join("&");

  const query = buildQuery(
    genresQueryString,
    facetsQueryString,
    `&collection_id=${collectionId}`
  );

  const { collection: mediaFiles, loadMore, hasMore, loading } = useCollection(
    "/collections/media",
    query
  );

  if(!collection) return (
      <LoaderContainer>
        <Loading/>
      </LoaderContainer>
  );

  return (
    <PageBackground>
      <PageContainer>
        <CollectionHeader
          collection={collection}
          backgroundImage={background}
          backgroundColor={colors.ink}
          textColor={colors.white}
        />
        <PageRowWithVerticalPadding>
          <div className="col-2" />

          <MediaFilesGrid
            mediaFiles={mediaFiles}
            hasMore={hasMore}
            loadMore={loadMore}
            loading={loading}
            isFiltersApplied={isFiltersApplied}
          >
            <GenreFilters />
          </MediaFilesGrid>
        </PageRowWithVerticalPadding>
      </PageContainer>
    </PageBackground>
  );
};

export default CollectionPage;
