import React, {useRef, useState} from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {Input} from "components/shared/FormElements";
import Icon from "components/shared/Icon";
import {useSearch} from "hooks/useSearch";

const ENTER_KEY_CODE = 13;

const HeaderSearch = () => {
  const { submitSearch } = useSearch();

  const [searchValue, setSearchValue] = useState("");
  const searchBoxRef = useRef(null);

  const loseFocus = () => searchBoxRef.current && searchBoxRef.current.blur();

  const handleSearchQuery = ({ which: pressedKeyCode, target }) => {
    if (pressedKeyCode === ENTER_KEY_CODE) {
      submitSearch(target.value);
      setSearchValue("");
      loseFocus();
    }
  };

  const onChange = e => setSearchValue(e.target.value);

  return (
    <SearchContainer>
      <SearchIcon type="Magnifier" height={34} width={20} />
      <SearchInput
        ref={searchBoxRef}
        value={searchValue}
        placeholder="Search"
        onKeyDown={handleSearchQuery}
        onChange={onChange}
      />
    </SearchContainer>
  );
};

// todo: cursor: pointer not working
const SearchIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 1;
  pointer-events: none;
`;

const SearchContainer = styled.div`
  position: relative;
  text-align: right;
  user-select: none;
`;

const SearchInput = styled(Input)`
  z-index: 5;
  color: ${colors.warmGraphite};
  width: 38px;
  margin-left: 0;

  border: 1px solid ${colors.coolGray};
  border-radius: 36px;
  background-color: transparent;

  ::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus {
    width: 220px;
    padding-right: 32px;
    margin-left: -220px;

    ::-webkit-input-placeholder {
      color: ${colors.placeholderGray};
    }
  }

  transition: all 0.15s ease-in-out;
`;

export default HeaderSearch;
