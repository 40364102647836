import React from "react";
import manInky from "components/shared/images/default-man-inky.png";
import womanInky from "components/shared/images/default-woman-inky.png";

import {Space} from "components/shared/FormElements";

import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {Description, InkyThumbnailTitle} from "../../../shared/style-guide/typography";

import * as moment from 'moment';
import {getBirthAndDeathYears} from "../../../../services/filters-utils";

const PersonInky = ({ person, small = false }) => {
  const defaultInky = person.gender === "Female" ? womanInky : manInky;

  // extract years as a backup if the date string includes the 'X' char and can't be parse by moment
  const {birthYear, deathYear} = getBirthAndDeathYears({date_of_birth: person.date_of_birth, date_of_death: person.date_of_death});

  // render correctly if the date is not valid
  let birthDate;
  if(!person.date_of_birth || person.date_of_birth.includes('X')) {
    birthDate = birthYear
  } else {
    birthDate = moment(person.date_of_birth).format('MMMM Do YYYY');
  }

  let deathDate = null;
  if(!person.date_of_death || person.date_of_death.includes('X')) {
    deathDate = deathYear
  }
  else {
    deathDate = moment(person.date_of_death).format('MMMM Do YYYY')
  }


  const renderMilestones = () => {
    return (
      <MilestonesContainer style={{textAlign: 'center'}}>
        {person.date_of_birth && <> b. {birthDate} </>}
        <br/>
        {person.date_of_death && <>d. {deathDate}</>}
        <br />
        {person.death_circumstance && (
          <span style={{ textTransform: "uppercase" }}>
            {person.death_circumstance}
          </span>
        )}
      </MilestonesContainer>
    );
  };

  return (
    <PersonInkyContainer>
      <Inky url={person.inky.url || defaultInky} small={small} />

      <PersonInkyThumbnailTitle small={small}>
        {person.display_name}
      </PersonInkyThumbnailTitle>

      <Space value={"4px"} />
      <PersonDate small={small}>{renderMilestones()}</PersonDate>
      <Space value="6px" />
      <Description>{person.caption || "No caption"}</Description>

    </PersonInkyContainer>
  );
};

const MilestonesContainer = styled.div`
  text-transform: uppercase;
`

const PersonInkyThumbnailTitle = styled(InkyThumbnailTitle)`
  font-size: 24px;
  letter-spacing: 0;
  line-height: 34px;
  color: ${colors.warmGraphite};
  text-align: center;
`;

const PersonInkyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`;

const Inky = styled.div`
  background: url(${({ url }) => url}) no-repeat;
  background-size: cover;
  height: ${({ small }) => (small ? "222px" : "340px")};
  width: ${({ small }) => (small ? "214px" : "328px")};
  margin-bottom: 20px;
`;

const PersonDate = styled.div`
  font-family: SlateProMedium, sans-serif;
  font-size: 10px;
  line-height: 14.4px;
  letter-spacing: 1.36px;
  color: ${colors.warmGraphite};
`;

export default PersonInky;
