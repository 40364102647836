import React, {useEffect} from "react";
import {PageContainer, Row} from "components/shared/Layouts";
import styled from "@emotion/styled";
import ViewfinderHeader from "./ViewfinderHeader";
import StaticPageHeader from "components/shared/StaticPageHeader";
import WomanAndChildStrip from "./Strips/WomanAndChildStrip";
import JustMarriedStrip from "./Strips/JustMarriedStrip";
import BenStrip from "./Strips/BenStrip";
import BoysStrip from "./Strips/BoysStrip";
import EleanorStrip from "./Strips/EleanorStrip";
import SoldiersStrip from "./Strips/SoldiersStrip";
import BicyclesStrip from "./Strips/BicyclesStrip";
import PlantEarthStrip from "./Strips/PlanetEarthStrip";
import BoatStrip from "./Strips/BoatStrip";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";

const Viewfinder = ({location}) => {
  useBreadCrumbs({label: "Viewfinder", path: location.pathname});

  useEffect(() => {
    const {hash} = window.location;

    if(hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if(element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        }
      }, 0);
    }
  }, []);

  return (
      <PageContainer>
        <Row>
          <div className="col-10 offset-1">
            <StaticPageHeader>
              <ViewfinderHeader/>
            </StaticPageHeader>
          </div>
        </Row>

        <StripsContainer>
          <WomanAndChildStrip/>
          <JustMarriedStrip/>
          <BenStrip/>
          <BoysStrip/>
          <EleanorStrip/>
          <SoldiersStrip/>
          <BicyclesStrip/>
          <PlantEarthStrip/>
          <BoatStrip/>
        </StripsContainer>
      </PageContainer>
  );
};

const StripsContainer = styled.div`
  margin-top: 100px;
`;

export default Viewfinder;
