import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {Link} from "react-router-dom";
import {map} from "lodash/fp";

const menuItems = [
  { name: "profile", to: "/profile", label: "My Profile" },
  { name: "account", to: "/account", label: "My Account" }
];

const AccountMenu = ({ selectedItemName }) => {
  return (
    <Menu>
      {map(
        menuItem => (
          <MenuItem
            isSelected={selectedItemName === menuItem.name}
            key={menuItem.name}
            to={menuItem.to}
          >
            {menuItem.label}{" "}
          </MenuItem>
        ),
        menuItems
      )}
    </Menu>
  );
};

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.2em;
  font-size: 18px;
  font-family: SlateProRegular;
  color: ${colors.warmGraphite};
  text-transform: uppercase;
  padding-top: 10px;
  line-height: 1.5;
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  color: ${({ isSelected }) =>
    isSelected ? colors.vermillion : colors.warmGraphite};
  border-color: ${({ isSelected }) =>
    isSelected ? colors.vermillion : colors.warmGraphite};
  border-style: solid;
  margin-bottom: 30px;
  border-bottom-width: ${({ isSelected }) => (isSelected ? "2px" : 0)};
  padding-bottom: ${({ isSelected }) => (isSelected ? 0 : "2px")};

  &:hover,
  &:active {
    padding-bottom: 0;
    border-bottom-width: 2px;
  }
`;

export default AccountMenu;
