import React, {useRef} from "react";
import {colors} from "config/StyleGuide";
import NameInitialsCircle from "../NameInitialsCircle";
import styled from "@emotion/styled";

const EditProfilePicture = ({
                              currentProfilePicture,
                              initials,
                              onChangeProfilePicture
                            }) => {
  const profileImageFileRef = useRef(null);

  const onUploadImageClick = async event => {
    const newProfileImageFile = event.target.files[0];
    const formData = new FormData();

    formData.append("file", newProfileImageFile);
    formData.append("upload_preset", 'from-client');

    const response = await fetch('https://api.cloudinary.com/v1_1/dq2r482qh/image/upload', {
      method: 'POST',
      body: formData
    });

    const result = await response.json();

    onChangeProfilePicture(result.url);
  };

  const onSubmit = event => {
    profileImageFileRef.current.click();
    event.stopPropagation();
    event.preventDefault();
  };

  return (
      <React.Fragment>
        <NameInitialsCircle
            radius={250}
            fontSize={130}
            initials={initials}
            imageSource={currentProfilePicture}
        />
        <form onSubmit={onSubmit}>
          <FileInput
              type="file"
              accept="image/*"
              ref={profileImageFileRef}
              onChange={onUploadImageClick}
          />
          <UploadImageButton>+ upload a new image</UploadImageButton>
        </form>
      </React.Fragment>
  );
};

const UploadImageButton = styled.button`
  font-family: SlateProMedium;
  font-size: 12px;
  letter-spacing: 0.15em;
  margin-top: 25px;
  color: ${colors.navy};
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;
  border: 0;
`;

const FileInput = styled.input`
  display: none;
`;

export default EditProfilePicture;
