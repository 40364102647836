import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {PageRow} from "../../../shared/Layouts";
import {Link} from "react-router-dom";

const SearchResultsCategorySelection = ({ category, query, resultByTypeCount }) => {
  const isActive = categoryName => {
    return (category === categoryName).toString();
  };

  const getLink = categoryName => {
    return `/search/${categoryName}?query=${query}`;
  };

  const renderCategoryCount = (category) => {
    return <span>({resultByTypeCount[category] || 0 })</span>
  };

  return (
    <PageRow>
      <div className="col">
        <Container>
          <SearchCategory to={getLink("media")} active={isActive("media")}>
            media {renderCategoryCount('MediaFile')}
          </SearchCategory>
          <SearchCategory to={getLink("people")} active={isActive("people")}>
            people {renderCategoryCount('Person')}
          </SearchCategory>
          <SearchCategory to={getLink("places")} active={isActive("places")}>
            places {renderCategoryCount('Location')}
          </SearchCategory>
          <SearchCategory to={getLink("clans")} active={isActive("clans")}>
            clans {renderCategoryCount('Clan')}
          </SearchCategory>
          <SearchCategory to={getLink("collection")} active={isActive("collections")}>
            collections {renderCategoryCount('Collection')}
          </SearchCategory>
        </Container>
      </div>
    </PageRow>
  );
};

const Container = styled.div`
  font-family: SlateProRegular;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.2em;
  font-size: 18px;
  text-transform: uppercase;
`;

const SearchCategory = styled(Link)`
  text-decoration: none;
  line-height: 2em;
  cursor: pointer;
  margin-right: 50px;
  border-style: solid;
  padding-bottom: 2px;

  color: ${({ active }) =>
    active === "true" ? colors.vermillion : colors.warmGraphite};

  border-bottom-width: ${({ active }) => (active === "true" ? "2px" : 0)};

  padding-bottom: ${({ active }) => (active === "true" ? "0" : "2px")};

  border-color: ${({ active }) =>
    active === "true" ? colors.vermillion : colors.warmGraphite};

  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &:active {
    border-color: ${colors.vermillion};
    border-bottom-width: 2px;
    border-style: solid;
    padding-bottom: 0;
    color: ${colors.vermillion};
  }

  &:active {
    border-color: ${colors.vermillion};
  }
`;

export default SearchResultsCategorySelection;
