import React from "react";
import {Row} from "components/shared/Layouts";
import {UnstyledLink} from "../../../shared/UnstyledLink";
import MemberInky from "./MemberInky";
import {map} from "lodash/fp";

import styled from "@emotion/styled";

const MemberInkyGrid = ({ members }) => {
  return (
    <InkyContainer>
      <Row>
        {map(
          member => (
            <React.Fragment key={member.id}>
              <div className="col-3" key={member.id}>
                <UnstyledLink to={`/members/${member.id}`}>
                  <MemberInky member={member} small={true} />
                </UnstyledLink>
              </div>
              <div className="col-1" />
            </React.Fragment>
          ),
          members
        )}
      </Row>
    </InkyContainer>
  );
};

const InkyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export default MemberInkyGrid;
