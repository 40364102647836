import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";
import Icon from "../../shared/Icon";
import {useMyCollection} from "../../../hooks/useMyCollectionStatus";
import ShareModal from "../../shared/ShareModal";
import {getFileShareUrl} from "../../../services/media-file.utils";

const FileOptions = ({mediaFile}) => {
  const {isInCollection, addOrRemoveFromMyCollection} = useMyCollection(mediaFile, mediaFile.favorite);

  return (
    <div className="col-1 offset-1">
      <Divider top={70} />
      <IconsContainer>
        <ShareModal
            linkUrl={getFileShareUrl(mediaFile)}
            shareIconFill={colors.warmGraphite}
            iconSize={23}
        />
        <StyledHeartIcon type={isInCollection ? "HeartFill" : "HeartLine"}
                         fill={colors.black}
                         onClick={addOrRemoveFromMyCollection}
                         size={23} />
      </IconsContainer>
      <Divider top={360} />
    </div>
  );
};


const StyledHeartIcon = styled(Icon)`
margin-left: 10px;
`;

const Divider = styled.div`
  width: 82px;
  height: 6px;
  background-color: ${colors.warmGraphite};
  margin-top: ${({ top }) => top}px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 25px;
`;

export default FileOptions;
