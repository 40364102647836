import React, {useRef, useState} from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Typeahead} from "react-bootstrap-typeahead";
import {debounce} from "lodash";
import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";
import Icon from "../Icon";
import {first, flow, get} from "lodash/fp";

const AutoComplete = ({
  labelKey,
  fetchSuggestions,
  onSubmit,
  placeholder
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [clearButtonVisible, toggleClearButton] = useState(false);
  const typeaheadRef = useRef();

  const debouncedCall = debounce(async value => {
    if (value.length < 3) return;
    setSuggestions(await fetchSuggestions(value));
  }, 300);

  // validate length and debounce results
  const handleInputChange = async newValue => {
    toggleClearButton(newValue.length > 0);

    if (!newValue.length) {
      setSuggestions([]);
    }

    debouncedCall(newValue);
  };

  const handleSubmit = ({ which, target }) => {
    if (which === 13) {
      onSubmit(target.value);
      setSuggestions([]);
      typeaheadRef.current.getInstance().blur();
    }
  };

  const clear = () => {
    // This works, DON'T TOUCH IT!
    typeaheadRef.current.getInstance().clear();
    toggleClearButton(false);
    setSuggestions([]);
    typeaheadRef.current.getInstance().focus();
    onSubmit("");
  };

  const onChange = async selectValues => {
    const value = flow(
      first,
      get("display_name")
    )(selectValues);

    setSuggestions(await fetchSuggestions(value));
    onSubmit(value);
  };

  return (
    <>
      <StyledTypeahead
        ref={typeaheadRef}
        id="auto-select"
        onKeyDown={handleSubmit}
        labelKey={labelKey}
        options={suggestions}
        placeholder={placeholder}
        onChange={onChange}
        onInputChange={handleInputChange}
        isEmpty={!suggestions.length}
      />
      {clearButtonVisible && (
        <ClearButton type={"ClearSearch"} onClick={clear} />
      )}
    </>
  );
};

const ClearButton = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 10px;
`;

const StyledTypeahead = styled(Typeahead)`
  input {
    font-family: SlateProRegular, sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
    width: 100%;
    height: 38px;
    border-radius: 19px;
    border-width: 0;
    padding-left: 20px;
    box-sizing: border-box;
    outline: none;
    background-color: ${colors.white};
    color: ${colors.warmGraphite};
    margin-bottom: 5px;
    box-shadow: 0 0 10px 10px rgba(186, 175, 158, 0.05);

    ::-webkit-input-placeholder {
      color: ${colors.coolGray};
    }
  }

  .rbt-input-hint {
    display: none;
  }

  button.close {
    visibility: hidden;
    &:after {
      visibility: visible;
      content: "x";
      font-size: 18px;
      position: absolute;
      right: 15px;
      top: 5px;
      cursor: pointer;
    }
  }

  ul {
    background-color: ${colors.white};
    padding: 5px 0;
    width: calc(100% - 30px) !important;
    left: 15px !important;
    top: -2px !important;
    display: ${props => (props.isEmpty ? "none" : "block")} !important;
  }

  ul > li {
    padding: 8px 0 8px 10px;
  }

  ul > li.active,
  ul > li:hover {
    background: ${colors.stone};
    cursor: pointer;
  }

  ul > li > a {
    font-family: SlateProRegular, sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: ${colors.warmGraphite};
    letter-spacing: 0.02px;
  }

  ul > li > a > mark {
    font-family: SlateProBold;
  }
`;

export default AutoComplete;
