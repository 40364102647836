import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";
import Tags from "../../shared/Tags";
import RelatedPeopleTags from "../../shared/RelatedPeopleTags";
import history from "../../../config/history";
import {useSearch} from "hooks/useSearch";

const Details = ({ mediaFile }) => {
  const { submitSearch } = useSearch();

  const {
    genre,
    display_date,
    title,
    keywords,
    short_description,
    verbose_description
  } = mediaFile;

  const getFileKeywords = () => {
    return keywords[0].split(";").map(keyword => ({
      label: keyword,
      value: keyword
    }));
  };

  const onPersonSelect = personId => {
    history.push(`/people/${personId}`);
  };

  return (
    <div className="col-5 mb-5">
      <ContentContainer>
        <DisplayDate>
          {genre || "No Genre"} | {display_date || "No display date"}
        </DisplayDate>
        <Title>{title || "No title"}</Title>
        <ShortDescription>
          {short_description || "No Short description"}
        </ShortDescription>
        <VerboseDescription>
          {verbose_description || "No Verbose description"}
        </VerboseDescription>

        {!!keywords.length && (
          <Tags
            large={true}
            label={"Keywords"}
            tags={getFileKeywords()}
            onTagSelect={submitSearch}
          />
        )}

        {!!mediaFile.people.length && (
          <RelatedPeopleTags
            label={"people"}
            people={mediaFile.people}
            onTagSelect={onPersonSelect}
          />
        )}
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled.div`
  padding-top: 165px;
`;

const DisplayDate = styled.div`
  font-family: SlateProBook;
  text-transform: uppercase;
  margin: 20px 0;
  color: ${colors.reed};
  font-size: 14px;
  letter-spacing: 1.5px;
`;

const Title = styled.div`
  font-size: 42px;
  font-family: SangBleuVersaillesBook;
  color: ${colors.warmGraphite};
  margin-bottom: 45px;
`;

const ShortDescription = styled.div`
  font-family: SangBleuVersaillesBookItalic;
  font-size: 20px;
  color: ${colors.warmGraphite};
  margin-bottom: 45px;
  line-height: 34px;
`;

const VerboseDescription = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 45px;
`;

export default Details;
