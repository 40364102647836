import React, {useContext, useState} from "react";
import {AuthContext} from "components/context-providers/Auth";
import apiRequest from "services/api";
import {
  AccountCancelButton,
  AccountInput,
  AccountLabel,
  AccountSubmitButton,
  ErrorLabel,
  FormButtonsWrapper,
  SuccessLabel
} from "./shared/AccountFormElements";
import {FormWrapper} from "components/shared/FormElements";
import {useFormState} from "react-use-form-state";

const AccountPassword = () => {
  const [formState, { password }] = useFormState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState(false);
  const {setCurrentUser } = useContext(AuthContext);
  const [error, setError] = useState(null);

  const onSubmitChangePassword = async event => {
    event.preventDefault();

    if (!isExpanded) {
      return setIsExpanded(true);
    }

    const {
      currentPassword,
      newPassword,
      newPasswordConfirmation
    } = formState.values;

    if (!currentPassword || !newPassword || !newPasswordConfirmation) {
      return setError("Invalid arguments");
    }

    if (newPassword !== newPasswordConfirmation) {
      return setError("Password and confirm password do not match");
    }

    setError(null);

    const response = await apiRequest({
      path: "/auth/password",
      method: "PUT",
      data: {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation
      }
    });

    if (response.status && response.status !== 200) {
      return setError("Something went wrong. Please try again");
    }

    setIsPasswordChangeSuccess(true);
    setIsExpanded(false);

    return setCurrentUser(response.data.data);
  };

  const onCancelClick = () => {
    setIsExpanded(false);
    setError(null);
    formState.clear()
  };

  return (
    <>
      {isPasswordChangeSuccess && (
        <SuccessLabel>Your password has been successfully updated.</SuccessLabel>
      )}
      {error && <ErrorLabel>{error}</ErrorLabel>}
      {!isExpanded && <AccountLabel>*******</AccountLabel>}
      <FormWrapper onSubmit={onSubmitChangePassword}>
        {isExpanded && (
          <div>
            <AccountInput
              placeholder="enter your current password"
              {...password("currentPassword")}
              invalid={!!error}
            />
            <AccountInput
              placeholder="enter your new password"
              {...password("newPassword")}
              invalid={!!error}
            />
            <AccountInput
              placeholder="re-enter your new password"
              {...password("newPasswordConfirmation")}
              invalid={!!error}
            />
          </div>
        )}

        <FormButtonsWrapper>
          <AccountSubmitButton>change password</AccountSubmitButton>
          {isExpanded && (
            <AccountCancelButton onClick={onCancelClick}>
              cancel
            </AccountCancelButton>
          )}
        </FormButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default AccountPassword;
