import React, {useState} from "react";
import Icon from "components/shared/Icon";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {map} from "lodash/fp";
import {openInNewWindow, Wrapper} from "./link-utils";

const PhotoPersonYear = ({
  mainPhoto,
  personPhoto,
  year,
  alignPerson,
  width,
  hoverTitle,
  hoverTextLines,
  imageId
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Wrapper
      onClick={() => openInNewWindow(imageId)}
      width={width}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <MainImage src={mainPhoto} />
      <PersonImage src={personPhoto} alignPerson={alignPerson} />
      <Year alignPerson={alignPerson}>{year}</Year>
      {isHovered && (
        <HoverWrapper>
          <HoverActions>
            <HoverActionIcon
              size={20}
              type="Share"
              fill={colors.white}
              onClick={() => alert("Share click")}
            />
            <HoverActionIcon
              size={20}
              type="HeartLine"
              fill={colors.white}
              onClick={() => alert("favorite click")}
            />
          </HoverActions>
          <HoverTitle>{hoverTitle}</HoverTitle>
          <HoverTextLines>
            {map(
              textLine => (
                <div key={textLine}>{textLine}</div>
              ),
              hoverTextLines
            )}
          </HoverTextLines>
        </HoverWrapper>
      )}
    </Wrapper>
  );
};

PhotoPersonYear.defaultProps = {
  alignPerson: "right"
};

// const Wrapper = styled(UnstyledLink)`
//   display: block;
//   position: relative;
//   width: ${({ width }) => `${width}px` || "100%"};
// `;

const MainImage = styled.img`
  width: 100%;
  height: auto;
`;

const transformPersonRight = "translate(50%, 50%)";
const transformPersonLeft = "translate(-50%, 50%)";
const PersonImage = styled.img`
  position: absolute;
  bottom: 0;
  right: ${({ alignPerson }) => (alignPerson === "right" ? 0 : "auto")};
  left: ${({ alignPerson }) => (alignPerson === "right" ? "auto" : 0)};
  transform: ${({ alignPerson }) =>
    alignPerson === "right" ? transformPersonRight : transformPersonLeft};
  z-index: 3000;
`;
// todo nir: the design says 'make sure using oldstyle figures' - what does it mean ?

const transformTextRight = "rotate(-90deg) translateY(100%)";
const transformTextLeft = "rotate(-90deg) translateY(-100%)";
const Year = styled.div`
  font-size: 36px;
  font-family: SangBleuVersaillesRegular;
  position: absolute;
  right: ${({ alignPerson }) => (alignPerson === "right" ? 0 : "auto")};
  left: ${({ alignPerson }) => (alignPerson === "right" ? "auto" : 0)};
  bottom: 50%;
  transform: ${({ alignPerson }) =>
    alignPerson === "right" ? transformTextRight : transformTextLeft};
  color: ${colors.warmGraphite};
  z-index: 1000;
`;

const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  padding: 0 20px;
`;

const HoverActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HoverActionIcon = styled(Icon)`
  margin-right: 5px;
`;

const HoverTitle = styled.div`
  text-transform: uppercase;
  font-family: SlateProRegular;
  font-size: 14px;
  letter-spacing: 0.2em;
  margin-bottom: 10px;
  margin-top: 20px;
`;

//todo eilam: add font family SangBleuVersailles Medium
const HoverTextLines = styled.div`
  font-family: SangBleuVersailles;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export default PhotoPersonYear;
