import React, {useContext, useReducer, useState} from "react";
import apiRequest from "services/api";
import {AuthContext} from "components/context-providers/Auth";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";

import familyPic from "./shared/family_Ink_header.png";

import erichPic from "./shared/erich_panel.png";
import {ErrorMessage, FormGroup, Input} from "components/shared/FormElements";
import {BaseButton, FormActionsContainer, FormLabel, Space} from "../../shared/FormElements";
import {PageContainer, Row} from "../../shared/Layouts";
import {BlackMarker} from "../../shared/BlackMarker";
import {BodyCopy, Description as DescriptionText, H6, HeroTitle} from "../../shared/style-guide/typography";
import Footer from "../../shared/Footer";
import Header from "../../shared/header/Header";
import proustQuote from "./shared/proust_quote.png";
import MessageAlert from "../../shared/MessageAlert";

const newUserReducer = (userData, action) => {
  switch (action.type) {
    case "set":
      return {...userData, [action.field]: action.payload};
    case "reset":
      return {};
    default:
      return userData;
  }
};

const Register = () => {
  const [newUser, dispatch] = useReducer(newUserReducer, {});
  const {setCurrentUser} = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [apiError, setSetApiError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [required, setRequired] = useState(null);

  const setUserInfo = ({target}) =>
      dispatch({
        type: "set",
        field: target.name,
        payload: target.value
      });

  const register = async () => {
    const {first_name, last_name, email, confirmEmail, password, passwordConfirm} = newUser;

    if(email !== confirmEmail || !email || !confirmEmail) {
      setError('Check your email and try again.');
      return;
    } else {
      setError(null);
    }

    if(!first_name || !last_name) {
      setRequired('First and last names are required ');
      return;
    } else {
      setRequired(null);
    }

    if(!password || !passwordConfirm || (password !== passwordConfirm)) {
      setPasswordError('Invalid password, Check the fields ');
      return;
    } else {
      setPasswordError(null);
    }

    const response = await apiRequest({
      path: "/auth",
      method: "POST",
      data: {
        first_name,
        last_name,
        email,
        password: password,
        confirm_success_url: `${process.env.REACT_APP_REDIRECT_URL_HOST}/pending`,
        redirect_url: `${process.env.REACT_APP_REDIRECT_URL_HOST}/pending`,
      },
    });

    if(response.data.status === "error") {
      setError(null);
      setRequired(null);
      setSetApiError("Something went wrong. Verify your info and try again.");
      return
    }

    setCurrentUser(response.data.data);
  };

  return (
      <>
        <Header/>
        <Description>
          <PageContainer>
            <div>
              <Row>
                <div className="col-4">
                  <DescriptionTextContainer>
                    <UpperBlackMarker/>
                    <Space value="40px"/>
                    Over{" "}
                    <DescriptionTextContainerBold>
                      259,000
                    </DescriptionTextContainerBold>{" "}
                    documents, photographs, and other memorabilia have been
                    painstakingly collected to tell the story <br/> of life,
                    death, great triumph, crippling heartbreak, humanity, and,{" "}
                    ultimately, <br/> a{" "}
                    <DescriptionTextContainerItalic>
                      family.
                    </DescriptionTextContainerItalic>
                  </DescriptionTextContainer>
                </div>
                <div className="col-8" style={{height: "480px"}}>
                  <FamilyInky src={familyPic} alt=""/>
                </div>
              </Row>
            </div>
          </PageContainer>
        </Description>

        <Registration>
          <PageContainer>
            <div>
              <Row>
                <div className="col-6 offset-3">
                  <RegistrationText>
                    <HeroTitle>Join TapThreeTimes</HeroTitle>

                    <RegistrationDescriptionText>
                      At the moment, TapThreeTimes is a private garden. <br/> New
                      participants can gain access via approval by the
                      administrator. <br/> Please submit this form to request
                      entry.
                    </RegistrationDescriptionText>
                  </RegistrationText>
                </div>
              </Row>

              <Row>
                <div className="col-6 offset-3">
                  <Row>
                    <div className="col-6">
                      <FormGroup>
                        <StyledFormLabel>First name</StyledFormLabel>
                        <Space value="8px"/>
                        <Input
                            placeholder="First name"
                            name="first_name"
                            holderColor={colors.black}
                            onChange={setUserInfo}
                            invalid={required}
                        />
                        {required && <ErrorMessage>{required}</ErrorMessage>}
                      </FormGroup>
                    </div>

                    <div className="col-6 ml-0 pl-3">
                      <FormGroup>
                        <StyledFormLabel>last name</StyledFormLabel>
                        <Space value="8px"/>
                        <Input
                            placeholder="Last name"
                            name="last_name"
                            holderColor={colors.black}
                            plaeholder="Last name"
                            onChange={setUserInfo}
                            invalid={required}
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <div>
                        <StyledFormLabel>Email</StyledFormLabel>
                        <Space value="8px"/>
                        <Input
                            placeholder="Email"
                            name="email"
                            holderColor={colors.black}
                            onChange={setUserInfo}
                            invalid={!!error}
                        />
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <FormGroup>
                        <StyledFormLabel>confirm email address</StyledFormLabel>
                        <Space value="8px"/>
                        <Input
                            type="text"
                            name="confirmEmail"
                            holderColor={colors.black}
                            placeholder="Re-enter email address"
                            onChange={setUserInfo}
                            invalid={!!error}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </FormGroup>
                    </div>


                    <div className="col-6 ml-0">
                      <FormGroup>
                        <StyledFormLabel>password</StyledFormLabel>
                        <Space value="8px"/>
                        <Input
                            type="password"
                            name="password"
                            holderColor={colors.black}
                            placeholder="Choose password"
                            onChange={setUserInfo}
                            invalid={!!passwordError}
                        />
                        {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
                      </FormGroup>
                    </div>

                    <div className="col-6 ml-0 pl-3">
                      <FormGroup>
                        <StyledFormLabel>Confirm password</StyledFormLabel>
                        <Space value="8px"/>
                        <Input
                            type="password"
                            name="passwordConfirm"
                            holderColor={colors.black}
                            placeholder="Re-enter password"
                            onChange={setUserInfo}
                            invalid={!!passwordError}
                        />
                      </FormGroup>

                    </div>


                  </Row>

                  <Row>
                      {apiError && (
                    <div className={"col-12 pt-4"}>
                          <MessageAlert message={apiError} type="Error" />
                    </div>
                      )}
                    <div className="col-5 offset-3">

                      <FormActionsContainer>
                        <BaseButton onClick={register}>
                          submit account application
                        </BaseButton>
                      </FormActionsContainer>
                    </div>
                  </Row>
                </div>
              </Row>
            </div>
          </PageContainer>
        </Registration>

        <Quote>
          <PageContainer>
            <div>
              <Row>
                <div className="col-6">
                  <QuoteImageContainer>
                    <QuoteTextImage src={proustQuote}/>
                    <QuoteBgImage src={erichPic}/>
                  </QuoteImageContainer>
                </div>

                <div className="col-6">
                  <QuoteTextContainer>
                    <QuoteTextParagraph>
                      At TapThreeTimes, it is our greatest wish that by navigating
                      through the lives of those represented here, you will
                      discover something about your own experience of life.
                    </QuoteTextParagraph>
                    <QuoteTextParagraph>
                      May seeing the world through the eyes of our contributors
                      <br/> help you see to greater horizon.
                    </QuoteTextParagraph>

                    <Signature>
                      {/* todo: get an image for this  */}
                      <HandWrite>Ben Goldhagen</HandWrite>
                      <H6>
                        ben goldhangen <br/> founder & head custodian, t3t
                      </H6>
                    </Signature>
                  </QuoteTextContainer>
                </div>
              </Row>
            </div>
          </PageContainer>
        </Quote>


        <Questions>
          <PageContainer>
            <div>
              <QuestionsContainer>
                <HeroTitle>Have additional questions?</HeroTitle>
                <div className="col-4 offset-4">
                  <QuestionsButton>contact administrator</QuestionsButton>
                </div>
              </QuestionsContainer>
            </div>
          </PageContainer>
        </Questions>

        <Footer/>
      </>
  );
};

const FamilyInky = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const UpperBlackMarker = styled(BlackMarker)`
  position: absolute;
  top: 0;
  left: 0;
`;

const QuestionsContainer = styled.div`
  font-family: SangBleuVersailles;
  font-size: 48px;
  text-align: center;
  padding: 100px;
  color: ${colors.white};
`;

const QuestionsButton = styled(BaseButton)`
  margin: 50px 0;
`;

const Signature = styled.div`
  width: 254px;
  text-align: left;
`;

const Description = styled.div`
  position: relative;
  height: 480px;
  background-color: ${colors.parchment};
`;

const DescriptionTextContainer = styled.div`
  font-family: SangBleuVersaillesRegular, serif;
  color: ${colors.warmGraphite};
  font-size: 22px;
  line-height: 36px;
  position: relative;
  text-align: left;
`;

const DescriptionTextContainerBold = styled(DescriptionTextContainer)`
  font-family: SangBleuVersaillesBold, serif;
  display: inline-block;
`;

const DescriptionTextContainerItalic = styled(DescriptionTextContainer)`
  font-family: SangBleuVersaillesBookItalic, serif;
  display: inline-block;
`;

const Quote = styled.div`
  height: 440px;
  background-color: ${colors.parchment};
  overflow: hidden;
`;

const QuoteImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
`;

const QuoteTextImage = styled.img`
  position: absolute;
  z-index: 99;
  top: 100px;
`;

const QuoteBgImage = styled.img`
  position: absolute;
`;

const QuoteTextContainer = styled.div`
  padding: 90px 100px 90px 130px;
  color: ${colors.warmGraphite};
  font-family: SangBleuVersaillesBook, serif;
  font-size: 18px;
  text-align: left;
`;

const QuoteTextParagraph = styled(BodyCopy)`
  line-height: 1.7em;
  padding-bottom: 40px;
`;

const Registration = styled.div`
  padding-top: 50px;
  min-height: 630px;  
  background-color: ${colors.ink};
`;

const Questions = styled.div`
  height: 350px;
  background-color: ${colors.ink};
`;

const RegistrationText = styled.p`
  color: ${colors.white};
  text-align: center;  
  padding: 0 90px;
  font-size: 16px;
  line-height: 1.6em;
`;

const HandWrite = styled.span`
  font-family: Northwell, sans-serif;
  display: inline-block;
  font-size: 40px;
  padding: 5px;
`;

const RegistrationDescriptionText = styled(DescriptionText)`
  margin: 24px 0;
  color: ${colors.white};
`;

const StyledFormLabel = styled(FormLabel)`
color: ${colors.white};
margin-top: 10px;

`

export default Register;
