import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import Icon from "components/shared/Icon";
import {getFileShareUrl, getFileType, getThumbnailUrl} from "services/media-file.utils";
import hoverGrade from "./images/hover_grad-1px.png";
import ShareModal from "./ShareModal";
import {useMyCollection} from "hooks/useMyCollectionStatus";

const Thumbnail = ({ file, wide, isInMyCollection }) => {
  const {isInCollection, addOrRemoveFromMyCollection} = useMyCollection(file, isInMyCollection);

  if (!file) return null;

  const thumbnailHasPlayIcon = getFileType(file) === "Video";

  return (
    <ThumbnailCanvas wide={wide}>
      <ImageContainer url={getThumbnailUrl(file)} wide={wide}>
        {thumbnailHasPlayIcon && (
          <PlayIcon fill={colors.errorRed} type="Video" size={20} />
        )}
        <BackgroundImg />

        <SocialIcons>
          <ShareModal linkUrl={getFileShareUrl(file)} iconSize={20} />

          <ClickableIcon
            type={isInCollection ? "HeartFill" : "HeartLine"}
            size={20}
            fill={colors.white}
            onClick={addOrRemoveFromMyCollection}
          />
        </SocialIcons>
      </ImageContainer>

      <IconContainer>
        <Icon type={file.genre} fill={colors.coolGray} width={20} height={20} />
      </IconContainer>
    </ThumbnailCanvas>
  );
};

const BackgroundImg = styled.div`
  background: url(${hoverGrade}) repeat-x;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const ThumbnailCanvas = styled.div`
  background-color: ${colors.white};
  height: 268px;
  width: ${({ wide }) => (wide ? 390 : 260)}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  box-shadow: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px 10px rgba(186, 175, 158, 0.05);
    transform: translateY(-3px);
  }
`;

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 14px 10px;
  align-self: flex-end;
`;

const ImageContainer = styled.div`
  background: ${colors.thumbnailBackground} url(${props => props.url}) no-repeat
    center/contain;
  margin: 10px;
  height: 200px;
  width: ${({ wide }) => (wide ? 362 : 232)}px;
  position: relative;

  & * {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover * {
    opacity: 1;
  }
`;

const SocialIcons = styled.div`
  position: absolute;
  width: 48px;
  right: 18px;
  top: 18px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
`;

const PlayIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
`;

export default Thumbnail;
