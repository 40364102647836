import React from 'react';
import Menu from "../../../shared/Menu";
import {ENTITIES} from "../../../../config/constants";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";
import {sortByName} from "../../../../services/filters-utils";
import {useResource} from "../../../../hooks/useResource";

const MediaFilesRelatedPlacesFilter = ({query, onAdd, onClear, isActive}) => {
  const [places] = useResource({path: `/media_files/places?query=${query}`});

  const renderRelatedPlace = (relatedPlace) => {
    const placeFilter = {
      ...relatedPlace,
      entity: ENTITIES.LOCATION
    };

    return (
        <FilterMenuItemTree
            key={relatedPlace.id}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={placeFilter}
            entity={ENTITIES.LOCATION}
        />
    );
  };

  if(!places || !places.length) return null;

  return (
      <Menu
          label={`Related Places`}
          itemsLength={places.length}
      >

        {places.sort(sortByName).map(place =>
            renderRelatedPlace(
                place,
                onAdd,
                onClear,
                isActive,
                ENTITIES.LOCATION
            )
        )}
      </Menu>
  );
};

export default MediaFilesRelatedPlacesFilter;
