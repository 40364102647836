import React, {useContext, useState} from "react";
import apiRequest from "services/api";
import Modal from "react-modal";
import {AuthContext} from "components/context-providers/Auth";
import ProfileAccountForm from "components/pages/members/profile/EditProfile/ProfileAccountForm";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {NavyLink} from "components/shared/style-guide/typography";
import {EditFormLabel, FormWrapper, Input, SubmitButton} from "components/shared/FormElements";
import ModalOverlayStyles from "../../shared/ModalOverlayStyles";
import MessageAlert from "../../shared/MessageAlert";

const modalStyle = {
  content: {
    width: "500px",
    height: "750px",
    top: "90px",
    right: "auto",
    left: "50%",
    transform: "translateX(-50%)",
    maxHeight: "100%",
    overflowY: "auto",
  },
  overlay: ModalOverlayStyles
};

const FieldWrapper = ({label, children}) => {
  return (
      <StyledFieldWrapper>
        <EditFormLabel>{label !== "name" && label}</EditFormLabel>
        {children}
      </StyledFieldWrapper>
  );
};

const isUserDataComplete = currentUser => {
  return currentUser.agreed_to_terms
};

const FirstTimeUserPopup = () => {
  const {currentUser, setCurrentUser} = useContext(AuthContext);
  const [accountDetails, setAccountDetails] = useState(currentUser);

  const [isModalOpen, setIsModalOpen] = useState(
      !isUserDataComplete(currentUser)
  );
  const closeModal = () => setIsModalOpen(false);

  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const toggleTerms = () => setIsTermsAgreed(!isTermsAgreed);
  const [showTermsError, setShowTermsError] = useState(false);

  const onSubmit = async event => {
    event.preventDefault();

    if(!isTermsAgreed) {
      setShowTermsError(true);
      return;
    }

    const data = {
      ...accountDetails,
      birth_date: accountDetails.birth_date,
      social_links: (accountDetails.social_links || []).filter(link => link.url.length > 0),
      agreed_to_terms: true
    };

    await apiRequest({
      path: `/members/${currentUser.id}`,
      method: "PUT",
      data
    });

    setCurrentUser(data);
    closeModal();
  };

  const onFormChange = formData => {
    setAccountDetails(formData);
  };

  return (
      <Modal
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={modalStyle}
      >
        <Wrapper>
          <Title>Welcome to Tap3Times!</Title>
          <Subtitle>
            Before starting your journey, please let us know a little bit about
            you.
          </Subtitle>
          <StyledFormWrapper onSubmit={onSubmit}>
            <ProfileAccountForm
                currentUser={accountDetails}
                onFormChange={onFormChange}
                FieldWrapper={FieldWrapper}
            />
            <TermsWrapper>
              <StyledInput
                  name="terms"
                  id="terms"
                  type="checkbox"
                  checked={isTermsAgreed}
                  onChange={toggleTerms}
              />
              <TermsLabel htmlFor="terms">
                I agree to T3T's <NavyLink to="/privacy">privacy policy</NavyLink>{" "}
                and <NavyLink to="/terms">terms of service</NavyLink>.
              </TermsLabel>
            </TermsWrapper>

            {showTermsError && (
              <MessageAlert type={'Alert'} message={"Please agree to T3T’s privacy policy and terms of service"} />
            )}

            <SubmitWrapper>
              <StyleSubmitButton onClick={onSubmit}>
                save changes
              </StyleSubmitButton>
            </SubmitWrapper>
          </StyledFormWrapper>
        </Wrapper>
      </Modal>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFormWrapper = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const StyledFieldWrapper = styled.div`
  margin-bottom: 25px;
  color: ${colors.tempGrayColor};
`;

const Title = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 22px;
  letter-spacing: 0.02em;
  color: ${colors.wramGraphite};
  margin-bottom: 20px;
`;
const Subtitle = styled.div`
  text-align: center;
  font-family: SlateProRegular;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 23px;
  color: ${colors.coolGray};
  margin-bottom: 20px;
  padding: 0 30px;
`;
const StyleSubmitButton = styled(SubmitButton)`
  width: 250px;
  text-align: center;
`;

const TermsLabel = styled.label`
  font-family: SlateProRegular;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 23px;
  color: ${colors.wramGraphite};
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledInput = styled(Input)`
  width: auto;
  margin-right: 10px;
`;

const SubmitWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FieldLabel = styled.div`
  font-family: SlateProMedium;
  font-size: 12px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  text-transform: uppercase;
`;
export default FirstTimeUserPopup;
