import React from "react";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";
import {colors} from "../../../../config/StyleGuide";

const FILES_IDS = [2852, 2846];

const EleanorStrip = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1] = Files;
  return (
    <StripContainer>
      <MediaViewer />
      <RowOverlay>
        <div className="col-1 offset-1" style={{ marginTop: "85px" }} >
          <StripLegend title="Bicycles" color={colors.warmGraphite} anchor={"bicycles"}>
            <Section>
              <div>Munich, Germany</div>
              <YearLabel>1970</YearLabel>
            </Section>
            <Section>
              <div>Hudson, NY, USA</div>
              <YearLabel>2003</YearLabel>
            </Section>
          </StripLegend>
        </div>
      </RowOverlay>
      <RowOverlay>
        <div className="col-6" style={{ marginTop: "145px", position: "absolute", right: 330 }}>
          <ImagesWrapper>
            <div
              style={{
                marginRight: "25px",
                marginTop: "145px",
                width: "330px"
              }}
            >
              <File0 />
        <span id={"bicycles"}/>
            </div>
            <div style={{ width: "330px" }}>
              <File1 />
            </div>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export default EleanorStrip;
