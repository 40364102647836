import React from "react";
import {colors} from "config/StyleGuide";
import Icon from "components/shared/Icon";
import styled from "@emotion/styled";
import {sortByName} from "../../services/filters-utils";

const FilterMenuItemTree = ({filter, onAdd, onClear, isActive, entity}) => {
  const toggleFilter = selectedFilter => {
    const entityFilter = {...selectedFilter, entity};

    return !isActive(entityFilter)
        ? onAdd(entityFilter)
        : onClear(entityFilter);
  };

  const isChildVisible = (child) => {
    if(typeof child.visible === "undefined") return true;
    return child.visible;
  };

  const renderChildren = (child, idx, margin) => {
    return (
        <div style={{position: "relative"}} key={child.id + idx + child.name}>

          {isChildVisible(child) && (
              <Link onClick={() => toggleFilter(child)} key={child.id}>
                <Container>
                  <SubValue active={isActive(child)} left={margin}>{child.name}</SubValue>
                  {isActive(child) && <CloseIcon/>}
                </Container>
              </Link>
          )}
          {child.children && child.children.sort(sortByName).map((child, idx) => {
            return isChildVisible(child) ? renderChildren(child, idx, margin + 20) : renderChildren(child, idx, margin);
          })}
        </div>
    )
  };

  return (
      <>
        <Link onClick={() => toggleFilter(filter)} key={filter.id}>
          <Container>
            <Value active={isActive(filter)}>{filter.name}</Value>
            {isActive(filter) && <CloseIcon/>}
          </Container>
        </Link>

        {filter.children && filter.children.sort(sortByName).map((child, idx) => {
          return isChildVisible(child) ? renderChildren(child, idx, 20) : renderChildren(child, idx, 0);
        })}
      </>
  );
};

export const Link = styled.div`
  text-decoration: none;
  color: black;

  margin-top: -2px;

  &:hover {
    cursor: pointer;
  }
  user-select: none;
`;

export const Value = styled.span`
  font-family: ${({active}) => (active ? "SlateProBold" : "SlateProBook")};
  font-size: 14px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  user-select: none;
`;

export const SubValue = styled(Value)`
  display: block;
  margin-left: ${({left}) => left}px;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const CloseIcon = ({onClick}) => (
    <StyledCloseIcon onClick={onClick}>
      <Icon type={"FilterClose"} size={12} fill={colors.warmGraphite}/>
    </StyledCloseIcon>
);

const StyledCloseIcon = styled.div`
  padding-left: 5px;
  display: inline-block;
  padding-top: 1px;
`;

export const Container = styled.li`
  display: flex;
  align-items: center;
  flex-direction: row;

  padding: 4px 0;
  &:last-child {
    padding-bottom: 4px;
  }
`;

export default FilterMenuItemTree;
