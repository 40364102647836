import React from "react";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";
import {colors} from "../../../../config/StyleGuide";

const FILES_IDS = [2855, 2859];

const EleanorStrip = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1] = Files;
  return (
    <StripContainer>
      <MediaViewer />
      <RowOverlay>
        <div className="col-1 offset-1" style={{ marginTop: "145px" }} >
          <StripLegend title="Eleanor" color={colors.warmGraphite} anchor="eleanor">
            <Section>
              <div>left:</div>
              <div>18 years old</div>
              <YearLabel>1925</YearLabel>
            </Section>
            <Section>
              <div>right:</div>
              <div>Happy 92nd Birthday, her last</div>
              <YearLabel>1998</YearLabel>
            </Section>
          </StripLegend>
        </div>
      </RowOverlay>
      <RowOverlay>
        <div className="col-7" style={{ marginTop: "110px", position: "absolute", right: 118 }}>
          <ImagesWrapper>
            <div
              style={{
                marginRight: "60px",
                marginTop: "85px",
                width: "335px"
              }}
            >
              <File0 />
              <span id={"eleanor"}/>
            </div>
            <div style={{ width: "335px" }}>
              <File1 />
            </div>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export default EleanorStrip;
