import {useEffect, useRef} from "react";

export function useDynamicGenres(facetFilters, genresFilters, updater) {
  const lastFiltersLength = useRef(facetFilters.length);

  useEffect(() => {
    if (facetFilters.length !== lastFiltersLength.current) {
      updater(genresFilters);
      lastFiltersLength.current = facetFilters.length;
    }
  }, [genresFilters]);
}
