import styled from "@emotion/styled";
import React from "react";
import SocialMediaInput from "./SocialMediaInput";

const EMPTY_SOCIAL_MEDIA_ENTRY = {type: "website", url: ""};

const SocialMediaLinks = ({ links, onChange }) => {
  let webLinks = links || [EMPTY_SOCIAL_MEDIA_ENTRY];

  if(typeof links === 'string') {
    webLinks = JSON.parse(links);
  }

  const onLinkChange = ({ index, url, type }) => {
    const newLinks = Object.assign([], links, { [index]: { url, type } });
    onChange(newLinks);
  };

  const onLinkRemove = (index, url, type) => {
    const newLinks = links.slice(0, index).concat(links.slice(index + 1));
    onChange(newLinks)
  };

  return (
    <Wrapper>
      {webLinks.map((link, index) => (
        <ItemWrapper key={index}>
          <SocialMediaInput
            type={link.type}
            url={link.url}
            onChange={({ url, type }) => onLinkChange({ index, url, type })}
            onRemove={(type, url) => onLinkRemove(index, url, type)  }
          />
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemWrapper = styled.div`
  margin-bottom: 10px;  
`;

export default SocialMediaLinks;
