import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import Icon from "components/shared/Icon";
import ShareModal from "../../../shared/ShareModal";

export const YearLabel = ({ children }) => (
  <StyledYearLabel>{children}</StyledYearLabel>
);

export const Section = ({ children }) => (
  <StyledSection>{children}</StyledSection>
);

const StripLegend = ({ title, children, color, anchor }) => {
  const baseUrl = window.location.origin;

  return (
      <Wrapper borderColor={color}>
        <TextWrapper color={color}>
          <Title>{title}</Title>
          {children}
        </TextWrapper>
        <ActionsList>
          <ShareModal linkUrl={`${baseUrl}/viewfinder#${anchor}`} iconSize={20} shareIconFill={color}/>
        </ActionsList>
      </Wrapper>
  )
};

const Title = styled.div`
  margin-bottom: 10px;
`;

const ActionIcon = styled(Icon)`
  & + & {
    margin-left: 8px;
  }
`;

const ActionsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextWrapper = styled.div`
  white-space: nowrap;
  padding: 50px 0 40px;
  color: ${({ color }) => (color ? color : colors.warmGraphite)};
`;

// todo nir: original design had 16px line height, but 18px looks better (less compacted and more like the actual design psd)
const Wrapper = styled.div`
  line-height: 18px;
  letter-spacing: 0.02em;
  font-size: 14px;
  font-family: SlateProBold;
  border-color: ${({ borderColor }) =>
    borderColor ? borderColor : colors.warmGraphite};
  border-style: solid;
  border-top-width: 5px;
  border-bottom-width: 5px;
  padding-bottom: 15px;
`;

// nir: font SlateProItalic does not exist (only SlateProBold does)
const StyledYearLabel = styled.div`
  font-family: SlateProItalic;
`;

const StyledSection = styled.div`
  margin-bottom: 10px;
`;

export default StripLegend;
