import React from "react";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {useQueryFilter} from "hooks/filters/useQueryFilter";
import {fetchSuggestions} from "services/fetch-suggestions";
import AutoComplete from "components/shared/auto-complete/AutoComplete";
import Rule from "components/shared/Rule";
import {colors} from "config/StyleGuide";
import {
  PageBackground,
  PageContainer,
  PageContent,
  PageRow,
  PageRowWithPadding,
  PageSideBar,
  Row
} from "components/shared/Layouts";
import SatchelsHeader from "./SatchelsHeader";
import SatchelsList from "./SatchelsList";
import {useCollection} from "../../../hooks/useCollection";

const Satchels = ({ location }) => {
  useBreadCrumbs({ label: "Satchels", path: location.pathname });

  const { setSearchQuery, searchQueryString } = useQueryFilter();
  const { collection: satchels } = useCollection("satchels", searchQueryString);

  return (
    <PageBackground>
      <PageContainer>
        <SatchelsHeader />
        <PageRowWithPadding>
          <div className="col-8 offset-2">
            <Rule height={3} color={colors.warmGraphite} />
          </div>
        </PageRowWithPadding>
        <PageRowWithPadding>
          <div className="col-4 offset-4">
            <AutoComplete
              fetchSuggestions={fetchSuggestions("satchels/autocomplete")}
              labelKey="display_name"
              onSubmit={setSearchQuery}
              placeholder="Search Satchels"
            />
          </div>
        </PageRowWithPadding>
        <PageRowWithPadding>
          <SatchelsList satchels={satchels} />
        </PageRowWithPadding>
        <PageRow>
          <PageSideBar />
          <PageContent>
            <Row />
          </PageContent>
          <PageSideBar />
        </PageRow>
      </PageContainer>
    </PageBackground>
  );
};

export default Satchels;
