import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {UnstyledLink} from "../../shared/UnstyledLink";

const Region = ({ img, text, link }) => (
  <RegionLink className="col-3" img={img} text={text} to={link} />
);

const RegionLink = styled(UnstyledLink)`
  height: 450px;
  background: url(${({ img }) => img});
  color: ${colors.white};
  font-size: 42px;
  position: relative;
  font-family: SangBleuVersaillesBook, serif;
  filter: saturate(50%);
  transition: filter .2s ease;
  background-size: contain;
  background-repeat: no-repeat;
  
  &:before {
    font-family: SangBleuVersaillesRegular;
    color: ${colors.white};
    font-size: 40px;    
    line-height: 46px;
    position: absolute;
    bottom: 25px;
    left: 25px;
    content: '${({ text }) => text}';
    max-width: min-content;
  }

  &:hover {
    color: ${colors.white};
    cursor: pointer;
    filter: saturate(100%);
  }
`;

export default Region;
