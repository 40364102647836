import React from "react";

import {FormGroup, FormWrapper} from "../../shared/FormElements";
import {Col1, Col12, Col3, Row} from "../../shared/Grid";
import AuthPageTitle from "./shared/AuthPageTitle";
import AuthHeader from "./shared/AuthHeader";
import styled from "@emotion/styled";
import {Description} from "../../shared/style-guide/typography";
import loginVideo from "./shared/t3t_login_vid.mp4";
import {VideoBackground} from "./shared/VideoBackground";
import {PageContainer} from "../../shared/Layouts";

const ResetPasswordConfirmation = () => {
  return (
    <>
      <VideoBackground src={loginVideo} type="video/mp4" autoPlay muted loop />
      <PageContainer>
        <AuthHeader />
        <Row>
          <Col1 />
          <Col3>
            <AuthPageTitle />
            <FormWrapper>
              <Col12>
                <FormGroup>
                  <ResetInstructions>
                    Instruction to reset your password have been sent. <br />
                    Please check your inbox.
                  </ResetInstructions>
                </FormGroup>
              </Col12>
            </FormWrapper>
          </Col3>
        </Row>
      </PageContainer>
    </>
  );
};

// todo: move to auth/shared
const ResetInstructions = styled(Description)`
  color: white;
  padding: 5px 0 15px 0;
`;

export default ResetPasswordConfirmation;
