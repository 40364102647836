import {useState} from "react";
import {find} from "lodash";

// facet should always include an id and name fields
export function useFacetsFilters(resetGenreFiltersFn) {
  const [facetFilters, setFacetFilters] = useState([]);

  const isFiltersApplied = () => {
    return {
      status: facetFilters.length > 0,
      filterNames: facetFilters.map( filter => filter.name )
    }
  };

  const facetsQueryString = new URLSearchParams(
    // support custom values for a query via filter.value.
    facetFilters.map(filter => [
      filter.entity + "_ids[]",
      filter.value || filter.id
    ])
  ).toString();

  const setFilter = facet => {
    const isExist = find(facetFilters, filter => filter.name === facet.name);
    if (isExist) return;

    resetGenreFiltersFn();
    setFacetFilters([...facetFilters, facet]);
  };

  const clearFilter = facet => {
    const updatedFilters = facetFilters.filter(f => f.id !== facet.id);
    setFacetFilters(updatedFilters);
  };

  const resetFilters = () => {
    setFacetFilters([]);
  };

  const isActiveFilter = filter => {
    return !!find(facetFilters, { name: filter.name });
  };

  return {
    facetFilters,
    setFilter,
    clearFilter,
    isActiveFilter,
    resetFilters,
    isFiltersApplied,
    facetsQueryString
  };
}
