import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";

const Actions = ({ children }) => <ActionsWrapper>{children}</ActionsWrapper>;

const ActionsWrapper = styled.div`
  width: 36px;
  height: 74px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${colors.white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99999;
  padding: 17px 10px;
`;

export default Actions;
