import React, {useEffect, useLayoutEffect, useState} from 'react';
import {getPronoun, mediaRangeWithinPersonAgeRange} from "../../../../services/filters-utils";
import Menu from "../../../shared/Menu";
import {ENTITIES} from "../../../../config/constants";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";
import {useResource} from "../../../../hooks/useResource";
import {useStagesWithinRange} from "../../../../hooks/useStagesWitinRange";

const PersonStagesFilter = ({person, onAdd, onClear, isActive}) => {
  const [ageRange, isLoading] = useResource({path: `/people/life_during_range?person_id=${person.id}`});
  const stagesWithinRange = useStagesWithinRange(ageRange);

  if(isLoading || !stagesWithinRange.length) return null;

  const renderStages = (stage) => {
    const stageFilter = {
      ...stage,
      entity: ENTITIES.STAGES
    };

    return (
        <FilterMenuItemTree
            key={stageFilter.id}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={stageFilter}
            entity={ENTITIES.STAGES}
        />
    );
  };

  return (
      <Menu label={`${getPronoun(person.gender)} stages`} itemLength={4}>
        {stagesWithinRange.map(stage => renderStages(stage)
        )}
      </Menu>

  );
};

export default PersonStagesFilter;
