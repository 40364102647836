import React from 'react';
import styled from '@emotion/styled';
import Icon from "./Icon";
import {colors} from "../../config/StyleGuide";

// alert, confirm, error
const MessageAlert = ({ type='Confirm', message, closeable=false, onClose }) => {
  return (
      <Wrapper type={type} >
        <StyledIcon type={type}/><StyledSpan type={type}>{message}</StyledSpan>
        {closeable && <StyledCloseIcon size={10} type={"Close"} onClick={onClose}/>}

      </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  border: ${({type}) => {
  if(type === 'Error' || type === 'Alert') {
      return '1px solid red !important';
    }
  }};
 
  font-size: 14px;
  justify-content:  flex-start;    
  align-items: center;
  height: 54px;
  padding: 16px;
  background-color: rgba(255,255,255, 0.8);
`;

const StyledIcon = styled(Icon)`
  margin-right: 14px;
`;

const StyledCloseIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  cursor: pointer;           
`;



const StyledSpan = styled.span`
  font-family: SlateProRegular,sans-serif;
  color: ${ ({ type }) => (type === 'Error' || type === 'Alert' ) ? colors.errorRed : colors.warmGraphite }
 
`;

export default MessageAlert;
