import React, {useContext, useState} from "react";
import {AuthContext} from "components/context-providers/Auth";
import apiRequest from "services/api";
import {
  AccountCancelButton,
  AccountInput,
  AccountLabel,
  AccountSubmitButton,
  FormButtonsWrapper,
  SuccessLabel
} from "./shared/AccountFormElements";
import {ErrorMessage, FormWrapper} from "components/shared/FormElements";
import styled from "@emotion/styled";

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const AccountEmail = ({ user }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEmailChangeSuccess, setIsEmailChangeSuccess] = useState(false);
  const {currentUser } = useContext(AuthContext);
  const [error, setError] = useState(null);

  const [email, setEmail] = useState();
  const [confirmEmail, setConfirmEmail] = useState();

  const [currentEmail, setCurrentEmail] = useState(user.email);

  const onSubmitChangeEmail = async event => {
    event.preventDefault();

    if (!isExpanded) {
      return setIsExpanded(true);
    }

    if(!email || !confirmEmail) {
      return setError("Please fill both fields");
    }

    if(!validateEmail(email) || !validateEmail(confirmEmail)) {
      return setError("Please enter a valid email");
    }

    if (email !== confirmEmail) {
      return setError("Email do not match");
    }

    const response = await apiRequest({
      path: `/members/${currentUser.id}/email`,
      method: "POST",
      data: {
        email,
        confirm_success_url: `${process.env.REACT_APP_REDIRECT_URL_HOST}/login`,
        redirect_url: `${process.env.REACT_APP_REDIRECT_URL_HOST}/login` ,
      }
    });

    if (response.status && response.status !== 200) {
      return setError(response.data.errors[0]);
    }

    setIsEmailChangeSuccess(true);
    setCurrentEmail(email);
    setIsExpanded(false);
  };

  const onCancelClick = (e) => {
    e.preventDefault();

    setConfirmEmail("");
    setEmail("");
    setCurrentEmail("");
    setError(null)
  };

  return (
    <>
      <AccountLabel>{currentEmail}</AccountLabel>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {isEmailChangeSuccess && <StyledSuccess>A confirmation email has been send.</StyledSuccess>}
      <FormWrapper>
        {isExpanded && (
          <>
            <AccountInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="enter new email address"
              invalid={!!error}
            />
            <AccountInput
              placeholder="confirm your new email address"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              invalid={!!error}
            />
          </>
        )}

        <FormButtonsWrapper>
          <AccountSubmitButton onClick={onSubmitChangeEmail} type={"button"}>change email address</AccountSubmitButton>
          {isExpanded && (
            <AccountCancelButton onClick={onCancelClick}>
              cancel
            </AccountCancelButton>
          )}
        </FormButtonsWrapper>
      </FormWrapper>
    </>
  );
};

const StyledSuccess = styled(SuccessLabel)`
padding: 10px 0;
`;

export default AccountEmail;
