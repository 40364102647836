import React from 'react';
import Menu from "../../../shared/Menu";
import {ENTITIES, EPOCHS} from "../../../../config/constants";
import {useResource} from "../../../../hooks/useResource";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";

const ClanTopicsFilter = ({clan, onAdd, onClear, isActive}) => {
  const [topics, isLoading] = useResource({path: `clans/topics?clan_id=${clan.id}`});

  if(isLoading || !topics || !topics.length) return null;

  const renderTopic = (topic, idx) => {
    const filter = {
      name: topic.topic.display_name,
      id: topic.topic.id,
      entity: ENTITIES.TOPICS,
      children: topic.children.map(child => ({
        id: child.id,
        name: child.display_name,
        entity: ENTITIES.TOPICS,
      }))
    };

    return (
        <FilterMenuItemTree
            key={filter.id + idx}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={filter}
            entity={ENTITIES.TOPICS}
        />
    );
  };

  return (
      <Menu label={`their topics`} itemLength={EPOCHS.length}>
        {topics.map((topic, idx) => renderTopic(topic, idx))}
      </Menu>
  );
};

export default ClanTopicsFilter;
