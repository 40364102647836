import React from 'react';
import Menu from "../../../shared/Menu";
import {getPronoun} from "../../../../services/filters-utils";
import {ENTITIES} from "../../../../config/constants";
import FilterMenuItem from "../../../shared/FilterMenuItem";

const PersonEventFilter = ({person, onAdd, onClear, isActive}) => {

    const renderEvent = (event) => {
        const eventFilter = {...event, entity: ENTITIES.EVENT};
        return (
            <FilterMenuItem
                key={event.id}
                onAdd={onAdd}
                onClear={onClear}
                isActive={isActive}
                filter={eventFilter}
            />
        );
    };

    if(!person.events || !person.events.length) return null;

    return (
        <Menu
            label={`${getPronoun(person.gender)} Events`}
            itemsLength={person.events.length}
        >
            {person.events.map((event) =>
                renderEvent(event, onAdd, onClear, isActive, ENTITIES.EVENT)
            )}
        </Menu>

    );
};

export default PersonEventFilter;