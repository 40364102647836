import React from "react";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import {Row} from "components/shared/Layouts";
import {isEmpty} from "lodash/fp";
import NoResults from "components/shared/NoResults";
import LoadingMediaCards from "../LoadingMediaCards";
import styled from "@emotion/styled";
import DebugMediaFiles from "./DebugMediaFiles";

const DebugMediaFileGrid = ({
                         children,
                         mediaFiles,
                         hasMore,
                         loadMore,
                         loading,
                         isFiltersApplied,
                         reloalding,
                       }) => {

  const renderNoResults = () => {
    const appliedfilter = isFiltersApplied ? isFiltersApplied() : {status: false, filterNames: []};


    if(reloalding) return null;
    
    
    if(appliedfilter.status === false) {
      return (
          <NoResults>
            Sorry, there are no results for that query.<br />
            Please broaden your search terms and try again.
          </NoResults>
      );
    }

    if(appliedfilter.status === true && mediaFiles.length === 0) {
      return (
          <NoResults>
            Sorry, there are no results for those filters:<br />
            <strong>{appliedfilter.filterNames.join(', ')}</strong><br/>
            Please try another set of filters.
          </NoResults>
      )
    }
  };

  return (
      <div className="col-12  p-0">
        {mediaFiles.length > 0 && <Row>{children}</Row>}
        <Row>
          {loading ? (
              <Container>
                <LoadingMediaCards/>
              </Container>
          ) : !isEmpty(mediaFiles) ? (
              <ScrollableInfiniteScroll
                  hasMore={hasMore}
                  loadMore={loadMore}
                  useWindow={true}
              >
                <DebugMediaFiles
                    mediaFiles={mediaFiles}
                    hasMore={hasMore}
                    loadMore={loadMore}
                />
              </ScrollableInfiniteScroll>
          ) : renderNoResults()}
        </Row>
      </div>
  );
};

const Container = styled.div`
  padding-bottom: 100px;
`;

export default DebugMediaFileGrid;
