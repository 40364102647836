import React from 'react';
import FilterMenuItem from "../../../shared/FilterMenuItem";
import Menu from "../../../shared/Menu";
import {ENTITIES, EPOCHS} from "../../../../config/constants";
import {getEpochStartAndEndYears} from "../../../../services/filters-utils";
import {useResource} from "../../../../hooks/useResource";


const ClanLifeDuringFilter = ({clan, onAdd, onClear, isActive}) => {
  const [clanAgeRange, isLoading] = useResource({path:`/clans/clan_life_during_range?clan_id=${clan.id}`});

  // without the age range, this filter can't be calculated
  if(isLoading || !clanAgeRange || clanAgeRange.from_age == null) return null;

  // extract birth and death years
  const {birth_year, death_year} = clan.life_span;

  // filter the epochs constant to math the person life span
  const epochsWithinRange = EPOCHS.filter(epoch => {
    const {epochStart, epochEnd} = getEpochStartAndEndYears(epoch.value);

    return (epochEnd >= (birth_year + clanAgeRange.from_age) && !(death_year < epochStart))
  });

  const renderLifeDuring = (epoch, idx) => {
    const lifDuringFilter = {
      ...epoch,
      entity: ENTITIES.EPOCHS
    };

    return (
        <FilterMenuItem
            key={lifDuringFilter.id + idx}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={lifDuringFilter}
        />
    );
  };

  return (
      <Menu label={`their life during`} itemLength={EPOCHS.length}>
        {epochsWithinRange.map((epoch, idx) => renderLifeDuring(epoch, idx))}
      </Menu>
  );
};

export default ClanLifeDuringFilter;
