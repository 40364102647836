import React, {useState} from "react";
import ActionIcon from "./ActionIcon";
import {colors} from "../../../config/StyleGuide";

const ToggleMediaFullScreenAction = ({ media, onClick }) => {
  const [active, setActive] = useState(false);
  const fill = active ? colors.gray : colors.coolGray;

  return (
    <ActionIcon
      onMouseIn={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      size={16}
      type="FullScreen"
      onClick={onClick}
      fill={fill}
    />
  );
};
export default ToggleMediaFullScreenAction;
