import React from "react";
import styled from "@emotion/styled";
import {ExternalMenuItem, MenuItem, MenuWrapper} from "./shared/HeaderMenu";

const HeaderPopup = ({ onClose }) => {
  return (
    <HeaderMenuWrapper onClick={onClose} width={370} top={0} left={34}>
      <ul>
        <MenuItem to="/events">Events</MenuItem>
        <MenuItem to="/people">People</MenuItem>
        <MenuItem to="/places">Places</MenuItem>
        <MenuItem to="/clans">Clans</MenuItem>
        <MenuItem to="/milestones">Life Milestones</MenuItem>
        <MenuItem to="/satchels">Satchels</MenuItem>
        <MenuItem to="/pom">Pieces of Mind</MenuItem>
        <MenuItem to="/viewfinder">Viewfinder No. 07</MenuItem>

        <MenuItem to="/stories">Graphic Stories</MenuItem>
        <MenuItem to="/members">Member Profiles</MenuItem>

        <ExternalMenuItem href="http://159.203.117.68" target="_blank">
          New York Real Estate
        </ExternalMenuItem>
      </ul>
    </HeaderMenuWrapper>
  );
};

const HeaderMenuWrapper = styled(MenuWrapper)`
  top: 70px;
  left: 150px;
`;

export default HeaderPopup;
