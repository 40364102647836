import React, {useEffect, useRef} from "react";
import {chunk, flow, get, slice} from "lodash/fp";
import styled from "@emotion/styled";
import {H6} from "components/shared/style-guide/typography";
import {colors} from "config/StyleGuide";
import {UnstyledLink} from "../../shared/UnstyledLink";

const SatchelHoverContent = ({ satchel }) => {
  const counter = useRef(0);

  useEffect(() => {
    counter.current = 0;
  }, []);

  const columnChunks = flow(
    get("collections"),
    slice(0, 8),
    chunk(4)
  )(satchel);

  return (
    <Wrapper>
      <TitleWrapper>
        <H6>Contents</H6>
      </TitleWrapper>
      <ContentRow className="row">
        {columnChunks.map((columnArticles, columnIndex) => (
          <div className="col-6" key={columnIndex}>
            <ColumnListWrapper>
              <ColumnList>
                {columnArticles.map(collection => {
                  counter.current = counter.current + 1;
                  return (
                    <UnstyledLink to={`/collection/${collection.id}`}>
                      <Item key={collection.id}>
                        <ItemIndex>
                          {("0" + counter.current).slice(-2)}
                        </ItemIndex>
                        <Legend />
                        <ItemName>{collection.display_name}</ItemName>
                      </Item>
                    </UnstyledLink>
                  );
                })}
              </ColumnList>
            </ColumnListWrapper>
          </div>
        ))}
      </ContentRow>
      <BottomTitleWrapper>
        <H6>More +</H6>
      </BottomTitleWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ContentRow = styled.div`
  flex: 1;
`;

const ColumnListWrapper = styled.div`
  position: relative;
`;
const ColumnList = styled.ul`
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-family: SangBleuVersaillesRegular;
  letter-spacing: 0.02em;
  line-height: 25px;
  color: ${colors.warmGraphite};
  margin-bottom: 20px;
`;

const Legend = styled.legend`
  margin: 0 10px;
  border-top: 1px solid ${colors.warmGraphite};
  width: 65px;
`;
const ItemIndex = styled.div``;

const ItemName = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  color: ${colors.reed};
`;

const BottomTitleWrapper = styled(TitleWrapper)`
  align-self: flex-end;
`;

export default SatchelHoverContent;
