import React from "react";
import {H1} from "components/shared/style-guide/typography";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import event1Image1 from "./images/WW2-Europe.jpg";
import event1Image2 from "./images/WW2-USA.jpg";
import event2Image1 from "./images/PostWar-Europe.jpg";
import event2Image2 from "./images/PostWar-USA.jpg";
import event3Image from "./images/PostPostWar.jpg";
import ButtonWithArrow from "components/shared/ButtonWithArrow";
import {UnstyledLink} from "components/shared/UnstyledLink";

const GoToEventSatchel = ({ id }) => (
  <UnstyledLink to={`/events/satchel/${id}`}>
    <ButtonWithArrow label="Go to collection" />
  </UnstyledLink>
);

const EventsHeader = () => (
  <Container>
    <EventsBackgroundTextWrapper>
      <GridRowWrapper className="row p-0 no-gutters">
        <div className="col-12">
          <EventsBackgroundText>eve</EventsBackgroundText>
          <EventsBackgroundText>nts</EventsBackgroundText>
        </div>
      </GridRowWrapper>
    </EventsBackgroundTextWrapper>
    <GridRowWrapper className="row">
      <div className="col-10 offset-1 p-0">
        <Title>Events</Title>
      </div>
    </GridRowWrapper>
    <GridRowWrapper className="row">
      <div className="col-10 offset-1 p-0">
        <EventsTable>
          <tbody>
            <EventTableRowIdColumn>
              <EventIdTableColumn>01</EventIdTableColumn>
              <EventIdTableColumn>02</EventIdTableColumn>
              <EventIdTableColumn>03</EventIdTableColumn>
            </EventTableRowIdColumn>
            <EventWrapperRow>
              <EventsTd>
                <EventTable>
                  <tbody>
                    <EventTableFirstRow>
                      <EventTitleTh noPadding colSpan={2}>
                        World War II
                      </EventTitleTh>
                    </EventTableFirstRow>
                    <EventTableFirstRow>
                      <EventImageTd>
                        <EventImage src={event1Image1} />
                      </EventImageTd>
                      <EventImageTd>
                        <EventImage src={event1Image2} />
                      </EventImageTd>
                    </EventTableFirstRow>
                    <EventTableFirstRow>
                      <EventTd>
                        <CountryText>Europe</CountryText>
                      </EventTd>
                      <EventTd>
                        <CountryText>U.S.</CountryText>
                      </EventTd>
                    </EventTableFirstRow>
                    <EventTableFirstRow>
                      <EventTd>
                        <GoToEventSatchel id={48} />
                      </EventTd>
                      <EventTd>
                        <GoToEventSatchel id={17} />
                      </EventTd>
                    </EventTableFirstRow>
                  </tbody>
                </EventTable>
              </EventsTd>
              <EventsTd>
                <EventTable>
                  <tbody>
                    <EventTableTr>
                      <EventTitleTh colSpan={2}>
                        Post <br />
                        World War II
                      </EventTitleTh>
                    </EventTableTr>
                    <EventTableTr>
                      <EventImageTd>
                        <EventImage src={event2Image1} />
                      </EventImageTd>
                      <EventImageTd>
                        <EventImage src={event2Image2} />
                      </EventImageTd>
                    </EventTableTr>
                    <EventTableTr>
                      <EventTd>
                        <CountryText>Europe</CountryText>
                      </EventTd>
                      <EventTd>
                        <CountryText>U.S.</CountryText>
                      </EventTd>
                    </EventTableTr>
                    <EventTableTr>
                      <EventTd>
                        <GoToEventSatchel id={22} />
                      </EventTd>
                      <EventTd>
                        <GoToEventSatchel id={19} />
                      </EventTd>
                    </EventTableTr>
                  </tbody>
                </EventTable>
              </EventsTd>
              <EventsTd>
                <EventTable>
                  <tbody>
                    <EventTableTr>
                      <EventTitleTh colSpan={2}>
                        Post-Post <br />
                        World War II
                      </EventTitleTh>
                    </EventTableTr>
                    <EventTableTr>
                      <EventImageTd colSpan={2}>
                        <EventImage src={event3Image} />
                      </EventImageTd>
                    </EventTableTr>
                    <EventTableTr>
                      <EventTd colSpan={2} />
                    </EventTableTr>
                    <EventTableTr>
                      <EventTd colSpan={2}>
                        <GoToEventSatchel id={7} />
                      </EventTd>
                    </EventTableTr>
                  </tbody>
                </EventTable>
              </EventsTd>
            </EventWrapperRow>
          </tbody>
        </EventsTable>
      </div>
    </GridRowWrapper>
  </Container>
);

const cellPadding = "25px";
const cellBorder = `1px solid ${colors.warmGraphite}`;

const BaseTable = styled.table`
  width: 100%;
  table-layout: fixed;
`;
const EventTable = styled(BaseTable)``;

const GridRowWrapper = styled.div``;

const EventsTable = styled(BaseTable)`
  border-collapse: collapse;
  border-bottom: 4px solid ${colors.warmGraphite};
  border-top: 4px solid ${colors.warmGraphite};
`;

const EventTd = styled.td`
  &:nth-of-type(2) {
    padding-left: 0;
  }

  padding-left: ${cellPadding};
  vertical-align: middle;
`;

const EventImageTd = styled(EventTd)`
  padding: ${cellPadding};
`;

const EventTitleTh = styled.th`
  height: 117px;
  vertical-align: middle;
  empty-cells: show;
  white-space: nowrap;
  padding: ${({ noPadding }) => (noPadding ? 0 : `0 ${cellPadding}`)};
  font-family: SangBleuVersaillesRegular;
  font-size: 30px;
  letter-spacing: 0.02em;
`;

const EventsTd = styled.td`
  width: 33%;
  text-align: left;
  vertical-align: middle;
`;

const EventWrapperRow = styled.tr``;

const EventTableBaseRow = styled.tr`
  height: 60px;
`;

const EventTableTr = styled(EventTableBaseRow)`
  border: ${cellBorder};
  border-right: 0;
`;

const EventTableFirstRow = styled(EventTableTr)`
  border-left: 0;
`;

const EventTableRowIdColumn = styled(EventTableBaseRow)``;

const EventIdTableColumn = styled(EventsTd)`
  font-family: SangBleuVersailles;
  font-size: 24px;
  letter-spacing: 0.06em;
  padding-left: ${cellPadding};
  &:first-child {
    padding-left: 0;
  }
`;

const CountryText = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 20px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
`;

const EventImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  flex: 1;
  color: ${colors.warmGraphite};
  position: relative;
  min-height: 750px;
`;

const EventsBackgroundTextWrapper = styled.div`
  position: absolute;
  top: -84px;
  user-select: none;
  pointer-events: none;
`;

//todo eilam: add color to style guide
// todo eila: spec says font-size is 298px but it looks like it's 350px
const EventsBackgroundText = styled.div`
  font-size: 298px;
  font-family: SangBleuVersaillesRegular;
  color: ${colors.reed};
  opacity: 0.3;
  white-space: nowrap;
  line-height: 1.4;
  letter-spacing: 380px;
  text-transform: uppercase;
`;

//todo eilam: add font family SangBleuVersailles Medium
const Title = styled(H1)`
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 0.22em;
  font-family: SangBleuVersailles;
  margin: 60px 0 30px 0;
`;

export default EventsHeader;
