import styled from "@emotion/styled";
import React, {useEffect, useRef, useState} from "react";
import {ErrorMessage, Select, SelectWrapper} from "components/shared/FormElements";
import {colors} from "../../config/StyleGuide";

const getNonZeroNumbers = length => Array.from({length}, (v, k) => k + 1);

const getYears = (startYear) => {
  const currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1900;
  while ( startYear <= currentYear ) {
    years.push(startYear++);
  }
  return years;
};

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const BirthdayPicker = ({birthDate, onChange}) => {
  const [dateError, setDateError] = useState(null);
  const firstRenderRef = useRef(true);

  const [currentBirthDate, setCurrentBirthDate] = useState(() => {
    let date = new Date(birthDate);

    if(date.toString() === "Invalid Date") {
      date = null
    }

    return {
      day: date ? date.getDate() : null,
      month: date ? date.getMonth() : null,
      year: date ? date.getFullYear() : null
    }
  });

  const updateBirthDate = (partialDate) => {
    setCurrentBirthDate({...currentBirthDate, ...partialDate});
  };

  useEffect(() => {
    const {day, month, year} = currentBirthDate;
    if(day === null || month === null || year === null) {
      if(!firstRenderRef.current) {
        setDateError("Please fill all date fields");
        onChange(null);
      }
    } else {
      setDateError(null);
      onChange(new Date(year, month, day))
    }
    firstRenderRef.current = false;
  }, [currentBirthDate]);

  return (
      <>
        {dateError && <ErrorMessage>{dateError}</ErrorMessage>}
        <Wrapper>
          <StyledSelectWrapper>
            <SelectWrapper>
              <SelectMonth onChange={({target}) => updateBirthDate({month: target.value})}
                           value={currentBirthDate.month}>
                <option value="">Month</option>
                {MONTH_NAMES.map((monthName, index) => (
                    <option key={monthName} value={index}>
                      {monthName}
                    </option>
                ))}
              </SelectMonth>
            </SelectWrapper>
          </StyledSelectWrapper>
          <StyledSelectWrapper>
            <SelectWrapper>
              <SelectDay onChange={({target}) => updateBirthDate({day: target.value})} value={currentBirthDate.day}>
                <option value="">Day</option>
                {getNonZeroNumbers(31).map(day => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                ))}
              </SelectDay>
            </SelectWrapper>
          </StyledSelectWrapper>
          <StyledSelectWrapper>
            <SelectWrapper>
              <SelectYear onChange={({target}) => updateBirthDate({year: target.value})} value={currentBirthDate.year}>
                <option value="">Year</option>
                {getYears('1900')
                    .reverse()
                    .map(year => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                    ))}
              </SelectYear>
            </SelectWrapper>
          </StyledSelectWrapper>
        </Wrapper>
      </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSelectWrapper = styled.div`
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
`;

const SelectMonth = styled(Select)`
  width: 130px;
  border-color: ${colors.gray};  
`;

const SelectDay = styled(Select)`
  width: 60px;
  border-color: ${colors.gray};
`;

const SelectYear = styled(Select)`
  width: 80px;
  border-color: ${colors.gray};
`;
export default BirthdayPicker;
