import React from "react";
import {ReactComponent as LeftArrow} from "./icons/carousel-arrow-left-active.svg";
import {ReactComponent as RightArrow} from "./icons/carousel-arrow-right-active.svg";

const SliderArrow = ({ left, skipSlide }) => {
  return (
    <div onClick={skipSlide}>
      {left ? (
        <LeftArrow className="svg-logo" style={{ width: 34 }} />
      ) : (
        <RightArrow className="svg-logo" style={{ width: 34 }} />
      )}
    </div>
  );
};

export default SliderArrow;
