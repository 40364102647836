import styled from "@emotion/styled";
import {css} from "@emotion/core";
import {colors} from "../../../config/StyleGuide";
import {UnstyledLink} from "../UnstyledLink";

export const H1 = styled.div`
  font-family: SangBleuVersaillesRegular, serif;
  font-size: 40px;
  letter-spacing: 0.02em;
`;

export const H2 = styled.div`
  font-family: SlateProRegular, sans-serif;
  font-size: 20px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

export const H3 = styled.div`
  font-family: SlateProRegular, sans-serif;
  font-size: 15px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

export const H4 = styled.div`
  font-family: SlateProBold, sans-serif;
  font-size: 16px;
  line-height: 1.2;
`;

export const H5 = styled.div`
  font-family: SlateProMedium, sans-serif;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

export const H6 = styled.div`
  font-family: SlateProRegular, sans-serif;
  font-size: 10px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
`;

export const HeroTitle = styled.div`
  font-family: SangBleuVersaillesRegular, serif;
  font-size: 30px;
  letter-spacing: 0.02em;
`;

export const BodyCopy = styled.div`
  font-family: SangBleuVersaillesRegular, serif;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 26px;
`;

export const Description = styled.div`
  font-family: SlateProRegular, sans-serif;
  color: ${colors.warmGraphite};  
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 20px;
  & + & {
    margin-top: 25px;
  }
`;

export const Date = styled.div`
  font-family: SlateProBoldItalic, sans-serif;
  font-size: 14px;
  color: ${colors.warmGraphite};
  letter-spacing: 0.02em;
  line-height: 20px;
`;
export const InkyThumbnailTitle = styled.span`
  font-family: SangBleuVersaillesRegular, serif;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  display: inline-block;
`;

export const TextLink = styled(UnstyledLink)`
  font-family: SlateProRegular, sans-serif;
  color: ${colors.white};
  font-size: 12px;
  letter-spacing: 0.02em;

  display: block;
  text-align: center;

  padding-top: 13px;
  text-decoration: none;
`;

const BaseNavyLink = css`
  color: ${colors.navy};
`;
export const ExternalNavyLink = styled.a`
  ${BaseNavyLink};
`;

export const NavyLink = styled(UnstyledLink)`
  ${BaseNavyLink};
`;
