import React, {useState} from "react";
import {saveAs} from "file-saver";
import ActionIcon from "./ActionIcon";
import {colors} from "../../../config/StyleGuide";

const DownloadMediaAction = ({ media }) => {
  const mediaFileName = media.split("/").pop();
  const [active, setActive] = useState(false);
  const fill = active ? colors.gray : colors.coolGray;

  return (
    <ActionIcon
      onMouseIn={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      size={16}
      type="Download"
      fill={fill}
      onClick={() => saveAs(media, mediaFileName)}
    />
  );
};

export default DownloadMediaAction;
