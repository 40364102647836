import styled from "@emotion/styled";
import {useCollection} from "hooks/useCollection";
import MediaThumbnailMeta from "components/shared/MediaThumbnailMeta";
import Thumbnail from "components/shared/Thumbnail";
import ToggleButton from "components/shared/ToggleButton";
import {colors} from "config/StyleGuide";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context-providers/Auth";
import MediaViewer from "components/shared/media-viewer/MediaViewer";
import {getIsMyCollectionPublic, hideMyCollectionOnMyProfile, showMyCollectionOnMyProfile} from "services/api";

const MyProfileCollection = () => {
  const { currentUser } = useContext(AuthContext);
  const [isPublic, setIsPublic] = useState(currentUser.private_collection);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  useEffect(() => {
    const checkMyCollectionPublicStatus = async () => {
      const isInMyProfile = await getIsMyCollectionPublic();
      setIsPublic(isInMyProfile);
    };

    checkMyCollectionPublicStatus();
  }, []);

  const onToggle = async => {
    setIsPublic(currentValue => !currentValue);
    isPublic ? hideMyCollectionOnMyProfile() : showMyCollectionOnMyProfile();
  };

  const { collection: mediaFiles } = useCollection(
    "/members/media",
    `&member_id=${currentUser.id}`
  );

  return (
    <>
      {currentFileIndex !== null && (
        <MediaViewer
          onCloseModal={() => setCurrentFileIndex(null)}
          mediaFiles={mediaFiles}
          currentFileIndex={currentFileIndex}
          hasMore={false}
        />
      )}
      <Wrapper>
        <Header>
          <Title>My Collection</Title>
          <TogglePublicProfileWrapper>
            Show on public profile
            <ToggleButton value={isPublic} onToggle={onToggle} />
          </TogglePublicProfileWrapper>
        </Header>
        <MyMediaFilesPreview>
          {mediaFiles.map((mediaFile, mediaFileIndex) => (
            <ListItem
              key={mediaFile.id}
              onClick={() => setCurrentFileIndex(mediaFileIndex)}
            >
              <Thumbnail
                wide
                key={mediaFile}
                file={mediaFile}
                isInMyCollection={true}
              />
              <MediaThumbnailMeta file={mediaFile} />
            </ListItem>
          ))}
        </MyMediaFilesPreview>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 300px;
`;

const MyMediaFilesPreview = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 75px;
  padding: 0 40px;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 390px;
  margin-bottom: 80px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 3px solid ${colors.warmGraphite};
  color: ${colors.warmGraphite};
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: 36px;
`;

const TogglePublicProfileWrapper = styled.div`
  font-family: SlateProRegular;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default MyProfileCollection;
