import React from "react";
import styled from "@emotion/styled";
import Icon from "components/shared/Icon";

// todo nir: fix colors (use styleguide colors)

const BubbleLabel = ({ label, iconType }) => (
  <Wrapper>
    <Icon size={10} type={iconType} fill="#BA7E1F" />
    <Label>{label}</Label>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: #f2dfa1;
  border-radius: 16px;
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #f2dfa1;
    transform: translateY(100%);
  }
`;

// todo nir: add styles from styleguide
const Label = styled.div`
  margin-left: 4px;
  font-size: 10px;
  color: #ba7e1f;
  white-space: nowrap;
  font-family: SlateProMedium;
`;

export default BubbleLabel;
