import React from "react";
import SideMenu from "components/shared/SideMenu";
import RelatedPersonFilter from "./filters/RelatedPersonFilter";
import PersonLifeDuringFilter from "./filters/PersonLifeDuringFilter";
import PersonStagesFilter from "./filters/PersonStagesFilter";
import PersonEventFilter from "./filters/PersonEventFilter";
import PersonPlacesFilter from "./filters/PersonPlacesFilter";
import PersonSatchelLink from "./filters/PersonSatchelLink";
import PersonTopicsFilter from "./filters/PersonTopicsFilter";

const PersonFilters = (props) => {
  return (
      <SideMenu>
        <RelatedPersonFilter {...props}/>
        <PersonLifeDuringFilter {...props}/>
        <PersonStagesFilter {...props} />
        <PersonEventFilter {...props} />
        <PersonTopicsFilter {...props} />
        <PersonPlacesFilter {...props} />
        <PersonSatchelLink person={props.person}/>
      </SideMenu>
  )
};

export default PersonFilters;
