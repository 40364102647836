import React, {useContext} from "react";
import Icon from "components/shared/Icon";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import NameInitialsCircle from "./NameInitialsCircle";
import {getCurrentUserInitials} from "services/user-utils";
import {map} from "lodash/fp";
import ProfileFieldBox from "./shared/ProfileFieldBox";
import facebookIcon from "components/shared/icons/fb-icon.png";
import instagramIcon from "components/shared/icons/insta-icon.png";
import twitterIcon from "components/shared/icons/twitter-icon.png";
import {ProfileBox, ProfileBoxLeftSide, ProfileBoxRightSide, Title} from "./shared/ProfileBoxElements";
import {AuthContext} from "../../../context-providers/Auth";
import {FormLabel} from "../../../shared/FormElements";

// todo: move somewhere else
const WEBSITE_TYPE_TO_ICON = {
  website: "Website"
};

const WEBSITE_TYPE_TO_IMAGE = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  instagram: instagramIcon
};

const renderLinkIcon = type => (
  <IconWrapper>
    {type in WEBSITE_TYPE_TO_ICON ? (
      <Icon type={WEBSITE_TYPE_TO_ICON[type]} size={20} />
    ) : (
      <ImageIcon src={WEBSITE_TYPE_TO_IMAGE[type]} />
    )}
  </IconWrapper>
);

const ViewProfile = ({ currentUser, onEditProfileClick }) => {
  const {currentUser: loggedInUser} = useContext(AuthContext);
  const { first_name, last_name, middle_name } = currentUser;
  const fullName = `${first_name} ${middle_name || ""} ${last_name}`;
  const birthday = new Date(currentUser.birth_date).toLocaleDateString();

  const picturePath = loggedInUser.id === currentUser.id ? currentUser.profile_image_link : currentUser.picture;

  return (
    <Wrapper>
      <ProfileHeader>
        <Title>member profile</Title>
        {onEditProfileClick && (
          <EditProfileButton onClick={onEditProfileClick}>
            edit profile
            <EditProfileIcon
              stroke={colors.white}
              fill={colors.white}
              type="Website"
              size={24}
            />
          </EditProfileButton>
        )}
      </ProfileHeader>
      <ViewProfileBox>
        <ProfileBoxLeftSide>
          <NameInitialsCircle
            radius={250}
            fontSize={130}
            initials={getCurrentUserInitials(currentUser)}
            imageSource={picturePath}
          />
        </ProfileBoxLeftSide>
        <ProfileBoxRightSide>
          <ProfileFieldBox label="name">
            <Name>{fullName}</Name>
          </ProfileFieldBox>
          <ProfileFieldBox label="birthday">
            <FormLabel>
              <IconWrapper>
                <Icon type="BirthdayCake" size={20} stroke={colors.warmGraphite}/>
              </IconWrapper>
              <BirthdayWrapper>{birthday}</BirthdayWrapper>
            </FormLabel>
          </ProfileFieldBox>
          <ProfileFieldBox label="social media / personal websites">
            {map(
              ({ url, type }) => (
                <SocialMediaLink key={url} href={`http://${url}`} target={"_blank"}>
                  {renderLinkIcon(type)}
                  {url}
                </SocialMediaLink>
              ),
              currentUser.social_links
            )}
          </ProfileFieldBox>
        </ProfileBoxRightSide>
      </ViewProfileBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px;
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 15px;
  align-items: center;
`;

const EditProfileButton = styled.div`
  font-family: SlateProMedium;
  font-size: 12px;
  letter-spacing: 0.15em;
  color: ${colors.navy};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`;

const EditProfileIcon = styled(Icon)`
  background-color: #191085;
  border-radius: 50%;
  padding: 8px;
  margin-left: 15px;
  cursor: inherit;
`;

const Name = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 28px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  text-transform: capitalize;
`;

const ViewProfileBox = styled(ProfileBox)`
  min-height: 340px;
`;

// todo nir: fix color
const SocialMediaLink = styled.a`
  font-family: "SlateProRegular";
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #191085;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 15px;
`;

const ImageIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const BirthdayWrapper = styled.div`
  text-transform: none;
`;

export default ViewProfile;
