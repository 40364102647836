import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import React from "react";

const PageBorder = ({ children }) => (
  <div>
    <div className="row no-gutters">
      <TopBorderColumn className="col-2 offset-5" />
    </div>
    {children}
    <div className="row no-gutters">
      <BottomBorderColumn className="col-2 offset-5" />
    </div>
  </div>
);

const StyledBorderColumn = styled.div`
  border-color: ${colors.warmGraphite};
  border-style: solid;
`;
const TopBorderColumn = styled(StyledBorderColumn)`
  border-top-width: 4px;
`;
const BottomBorderColumn = styled(StyledBorderColumn)`
  border-bottom-width: 4px;
`;

export default PageBorder;
