import React, {useContext, useMemo} from "react";
import {useCollection} from "hooks/useCollection";
import {PageContainer, Row} from "components/shared/Layouts";
import AutoComplete from "components/shared/auto-complete/AutoComplete";
import {fetchSuggestions} from "services/fetch-suggestions";
import styled from "@emotion/styled";
import {useQueryFilter} from "hooks/filters/useQueryFilter";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import MemberInkyGrid from "./MemberInkyGrid";
import membersHeaderImage from "./members_header.png";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {AuthContext} from "../../../context-providers/Auth";

const Members = ({ location }) => {
  useBreadCrumbs({ label: "Members", path: location.pathname });
  const {currentUser} = useContext(AuthContext);
  const { setSearchQuery, searchQueryString } = useQueryFilter();


  const { collection: members, loadMore, hasMore } = useCollection(
    "/members",
    searchQueryString
  );

  const membersWithoutCurrentMember = useMemo(() => {
    return members.filter( member => member.id !== currentUser.id );
  }, [members]);

  return (
    <PageContainer>
      <Row>
        <div className="col-10 offset-1">
          <HeaderImage src={membersHeaderImage} />
        </div>
      </Row>
      <Row>
        <div className="col-4 offset-4">
          <SearchWrapper>
            <AutoComplete
              fetchSuggestions={fetchSuggestions("members/autocomplete")}
              labelKey="display_name"
              onSubmit={setSearchQuery}
              placeholder="Search all members"
            />
          </SearchWrapper>
        </div>
      </Row>

      <Row>
        {members && !!members.length && (
          <ScrollableInfiniteScroll
            key={searchQueryString}
            hasMore={hasMore}
            loadMore={loadMore}
            useWindow={true}
          >
            <div className="col-8 offset-2">
              <MemberInkyGrid members={membersWithoutCurrentMember} />
            </div>
          </ScrollableInfiniteScroll>
        )}
      </Row>
    </PageContainer>
  );
};

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
`;

const SearchWrapper = styled.div`
  margin: 40px 0 70px;
  position: relative;
`;

export default Members;
