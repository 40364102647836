import styled from "@emotion/styled";
import React, {useContext, useState} from "react";
import Slider from "react-slick";
import SliderArrow from "../../shared/SliderArrow";
import {SubmitButton} from "../../shared/FormElements";
import {SectionTitle} from "../../shared/Layouts";
import {Link} from "react-router-dom";
import {Description} from "../../shared/style-guide/typography";
import Icon from "../../shared/Icon";
import Thumbnail from "../../shared/Thumbnail";
import MediaThumbnailMeta from "../../shared/MediaThumbnailMeta";
import {AuthContext} from "../../context-providers/Auth";
import {isEmpty, size} from "lodash/fp";
import {useCollection} from "../../../hooks/useCollection";
import MediaViewer from "../../shared/media-viewer/MediaViewer";

const CollectionCarousel = () => {
  const [sliderRef, setSliderRef] = useState({});
  const {currentUser} = useContext(AuthContext);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  const {collection} = useCollection(
      "/members/media",
      `&member_id=${currentUser.id}`
  );

  const minSlides = Math.min(2, size(collection));

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: minSlides,
    slidesToScroll: minSlides,
    draggable: false,
    arrows: false
  };

  if(!isEmpty(collection)) {
    return (
        <>
          {currentFileIndex !== null && (
              <MediaViewer
                  onCloseModal={() => setCurrentFileIndex(null)}
                  mediaFiles={collection}
                  currentFileIndex={currentFileIndex}
                  hasMore={false}
                  loadMore={false}
              />
          )}

          <Wrapper>
            <SectionTitle>from my collection</SectionTitle>
            <LeftArrow>
              <SliderArrow left={true} skipSlide={sliderRef.slickPrev}/>
            </LeftArrow>
            <SliderWrapper>
              <Slider {...settings} ref={ref => setSliderRef(ref)}>
                {collection.map((item, fileIndex) => (
                    <div key={item.id}>
                      <div onClick={() => setCurrentFileIndex(fileIndex)}>
                        <Thumbnail file={item} wide isInMyCollection={true}/>
                        <MediaThumbnailMeta file={item}/>
                      </div>
                    </div>
                ))}
              </Slider>
            </SliderWrapper>
            <RightArrow>
              <SliderArrow skipSlide={sliderRef.slickNext}/>
            </RightArrow>
            <div className="col-4 offset-4">
              <Link to={"/profile"}>
                <MyCollectionButton>Go to my collection</MyCollectionButton>
              </Link>
            </div>
          </Wrapper>
        </>
    );
  }

  return (
      <>
        <SectionTitle>From my collection</SectionTitle>
        <Description>
          You have not yet added any items to Your Collection.
          <br/>
          <br/>
          To add to Your Collection, click the heart icon
          <Icon type={"HeartLine"} width={20} style={{margin: "0 7px"}}/>
          found on items and pages.
          {/*<InLineIcon type={"HeartLine"} width={20} /> found on items and pages.*/}
        </Description>
      </>
  );
};

const MyCollectionButton = styled(SubmitButton)`
  margin: 25px 0;
`;

const ArrowPosition = styled.div`
  position: absolute;
  cursor: pointer;
  top: 30%;
  transform: translateX(-100%);
`;

const Wrapper = styled.div`
  position: relative;
`;

const LeftArrow = styled(ArrowPosition)`
  left: -15px;
`;
const RightArrow = styled(ArrowPosition)`
  right: -15px;
`;

const SliderWrapper = styled.div`
  margin: 0 40px;
`;

export default CollectionCarousel;
