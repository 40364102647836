import styled from "@emotion/styled";
import Icon from "../Icon";

const ActionIcon = styled(Icon)`
  cursor: pointer;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default ActionIcon;
