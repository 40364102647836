import React from "react";
import Rule from "components/shared/Rule";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {PageBackground, PageContainer, PageContent, PageRow, PageSideBar, Row} from "components/shared/Layouts";
import AboutInky from "./AboutInky";
import {Description, H1} from "components/shared/style-guide/typography";
import StaticPageHeader from "components/shared/StaticPageHeader";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";

// todo nir: get real data
const tempMockPeopleList = [
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" },
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" },
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" },
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" },
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" },
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" },
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" },
  { name: "Benjamin Goldhagen", jobTitle: "Owner and Chief Custodian" }
];

const About = ({ location }) => {
  useBreadCrumbs({ label: "About", path: location.pathname });

  return (
    <PageBackground>
      <PageContainer>
        <div className="row pb-5">
          <ContentColumn className="col-6 offset-3">
            <StaticPageHeader>
              <H1>About Tap Three Times </H1>
            </StaticPageHeader>
            <Rule height={3} color={colors.warmGraphite} />
            <Subtitle>
              Tap Three Times is a project initiated soon after a mass re Was
              discovered in a closet.
            </Subtitle>
            <Description>
              When somebody died, his or her pictures and letters, films and
              recordings were dropped into the drawers, shelves and piles of a
              heap. Sometimes one person’s collection included remnants of
              someone elses’s heap. An ever ite growing jumble. We discovered
              that as years passed, the thoughts and memories of the living also
              found their way in. Accidental time capsule. Ages of material
              continue to be deposited like silt. When others heard of the
              project, they added bags and satchels.
            </Description>
            <Description>
              I wanted to share what I was unearthing, but unable with the
              software available, so I assembled a team for the task. Tap Three
              Times came to fruition by the hands of a wonderful group of people
              working in concert. Included are graphic artists, genealogists,
              programmers, photographers, information architects and, of course,
              the contributions of historians, some of whom are directly related
              to the project and the peaple themselves who are depicted.
            </Description>
            <Description>
              A touchstone for us was a sign we taped to the wall early in the
              project: “A first-person adventure with a real family to find
              yourself”.
            </Description>
            <Rule height={1} color={colors.warmGraphite} />
            <Subtitle>
              Three years later from the time the first items were pulled out,
              the team is being presented here, together for the first time:
            </Subtitle>
            <PageRow>
              {tempMockPeopleList.map(person => (
                <div className="col-4">
                  <AboutInky person={person} />
                </div>
              ))}
            </PageRow>
          </ContentColumn>
        </div>
        <PageRow>
          <PageSideBar />
          <PageContent>
            <Row />
          </PageContent>
          <PageSideBar />
        </PageRow>
      </PageContainer>
    </PageBackground>
  );
};

const ContentColumn = styled.div`
  color: ${colors.warmGraphite};
`;

const Subtitle = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 24px;
  line-height: 40px;
  padding-top: 30px;
  padding: 30px 0 15px;
`;

export default About;
