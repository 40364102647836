import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import Icon from "components/shared/Icon";

const LaunchPdfButton = ({ onClick, label }) => {
  return (
    <Wrapper onClick={onClick}>
      {label}
      <StyledIcon
        size={16}
        type="CollectionArrow"
        fill={colors.vermillion}
        stroke={colors.vermillion}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 120px;
  border: 1px solid ${colors.vermillion};
  color: ${colors.vermillion};
  font-family: SlateProRegular;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  padding: 4px 8px;
`;

const StyledIcon = styled(Icon)`
  transform: rotate(-45deg);
`;

export default LaunchPdfButton;
