import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import React, {useRef} from "react";
import {Option, Select, SelectWrapper} from "components/shared/FormElements";
import {EditProfileInput} from "components/pages/members/account/shared/AccountFormElements";
import Icon from "./Icon";


const SOCIAL_MEDIA_TYPES = ["facebook", "twitter", "website", "snapchat", "instagram"];

const SocialMediaInput = ({type, url, onChange, onRemove}) => {
  const inputRef = useRef();
  const onChangeType = e => onChange({url, type: e.target.value});
  const onChangeUrl = e => onChange({type, url: e.target.value});

  const handelEnter = (evt) => {
    if(evt.key === 'Enter') {

      evt.preventDefault();
      evt.stopPropagation();

      inputRef.current.blur();
    }
  };

  return (
      <Wrapper>
        <SelectWrapper>
          <SocialMediaSelect value={type} onChange={onChangeType}>
            {SOCIAL_MEDIA_TYPES.map(type => (
                <Option key={type} value={type}>
                  {type}
                </Option>
            ))}
          </SocialMediaSelect>
        </SelectWrapper>
        <InputWrapper>
          <SocialMediaEditProfileInput
              ref={inputRef}
              type="text"
              placeholder="paste URL here"
              value={url}
              onChange={onChangeUrl}
              onKeyDown={handelEnter}
          />
        </InputWrapper>
        <Remove onClick={() => onRemove(type, url)}>
          <Icon type="RemoveSocialLink" size={14}/>
        </Remove>
      </Wrapper>
  );
};

const Remove = styled.div`
  cursor: pointer !important;
`;

const InputWrapper = styled.div`
  margin-left: 10px;
  flex: 1;
`;

const SocialMediaEditProfileInput = styled(EditProfileInput)`
  color: ${colors.navy};
  width: 98%;
`;

const SocialMediaSelect = styled(Select)`
  width: 130px;
`;

SocialMediaInput.defaultProps = {
  type: SOCIAL_MEDIA_TYPES[2],
  url: ""
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default SocialMediaInput;
