import React from 'react';
import Menu from "../../../shared/Menu";
import {convertEpochStringIntoRange, range, withinRange} from "../../../../services/filters-utils";
import {ENTITIES, EPOCHS} from "../../../../config/constants";
import FilterMenuItem from "../../../shared/FilterMenuItem";

const PlacesDuringEpochFilter = ({place, onAdd, onClear, isActive}) => {
  const locationLifeRange = range(place.life_span[0], place.life_span[1]);

  const epochsWithinRange = EPOCHS.filter(epoch => {
    const epochRange = convertEpochStringIntoRange(epoch.value);
    return withinRange(locationLifeRange, epochRange);
  });

  const renderLifeDuring = (epoch) => {
    const lifDuringFilter = {
      ...epoch,
      entity: ENTITIES.EPOCHS
    };

    return (
        <FilterMenuItem
            key={lifDuringFilter.id}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={lifDuringFilter}
        />
    );
  };

  return (
      <Menu label={`life in ${place.display_name}`} itemLength={EPOCHS.length}>
        {epochsWithinRange.map(epoch =>
            renderLifeDuring(epoch, onAdd, onClear, isActive, ENTITIES.EPOCHS)
        )}
      </Menu>
  )
};

export default PlacesDuringEpochFilter;
