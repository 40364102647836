import React, {useContext, useReducer} from "react";
import styled from "@emotion/styled";
import Rule from "../Rule";
import {PageContainer} from "components/shared/Layouts";
import {AuthContext} from "../../context-providers/Auth";
import {colors} from "../../../config/StyleGuide";
import Breadcrumbs from "components/shared/Breadcrumbs";
import Logo from "components/shared/Logo";
import {Link} from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import HeaderPopup from "./HeaderPopup";
import ProfilePopup from "./ProfilePopup";
import OutsideClickHandler from "react-outside-click-handler";
import NameInitialsCircle from "components/pages/members/profile/NameInitialsCircle";
import {getCurrentUserInitials, getProfilePicture} from "services/user-utils";
import BubbleLabel from "components/shared/BubbleLabel";
import {useMyCollectionStatus} from "../../../hooks/useMyCollectionStatus";

//todo: add conditional rendering depending on currentUser
const Header = () => {
  const { currentUser } = useContext(AuthContext);

  const { isInMyCollection } = useMyCollectionStatus();

  const toggleMenuReducer = (state, action) => {
    switch (action.type) {
      case "toggle":
        return { visible: !state.visible };
      case "hide":
        return { visible: false };
      default:
        return state;
    }
  };
  
  const [browseMenu, dispatchBrowseMenu] = useReducer(toggleMenuReducer, {
    visible: false
  });
  const [profileMenu, dispatchProfileMenu] = useReducer(toggleMenuReducer, {
    visible: false
  });

  return (
    <HeaderContainer>
      <PageContainer>
        <div className="container-fluid pl-0 pr-0">
          <div className="row" style={{paddingBottom: "16px"}}>
            <LeftSide className="col-3">
              <Logo />
              <OutsideClickHandler
                onOutsideClick={() => dispatchBrowseMenu({ type: "hide" })}
              >
                {currentUser && (
                  <BrowseButton
                    onClick={() => dispatchBrowseMenu({ type: "toggle" })}
                  >
                    Explore
                  </BrowseButton>
                )}
                {browseMenu.visible && (
                  <HeaderPopup
                    onClose={() => dispatchBrowseMenu({ type: "hide" })}
                  />
                )}
              </OutsideClickHandler>
            </LeftSide>

            <div className="col-3 offset-6">
              <CurrentUser>
                {currentUser && (
                  <>
                    <MyCollectionLinkWrapper>
                      <ItemAddedBubbleWrapper visible={isInMyCollection}>
                        <BubbleLabel iconType="HeartFill" label="ITEM ADDED!" />
                      </ItemAddedBubbleWrapper>
                      <BoldLink to={"/profile"}>my collection</BoldLink>
                    </MyCollectionLinkWrapper>
                    <OutsideClickHandler
                      onOutsideClick={() =>
                        dispatchProfileMenu({ type: "hide" })
                      }
                    >
                      <InitialsCircleWrapper
                        onClick={() => dispatchProfileMenu({ type: "toggle" })}
                      >
                        <NameInitialsCircle
                          radius={32}
                          fontSize={14}
                          initials={getCurrentUserInitials(currentUser)}
                          imageSource={getProfilePicture(currentUser)}
                        />
                      </InitialsCircleWrapper>
                      {profileMenu.visible && (
                        <ProfilePopup
                          onClose={() => dispatchProfileMenu({ type: "hide" })}
                        />
                      )}
                    </OutsideClickHandler>
                  </>
                )}
              </CurrentUser>
            </div>
          </div>

          {currentUser && (
            <>
              <div className="row">
                <div className="col-12">
                  <Rule height={1} color={colors.coolGray} />
                </div>
              </div>
              <div className="row pt-3 align-items-center">
                <div className="col-10">
                  <Breadcrumbs />
                </div>

                <div className="col-2">
                  <HeaderSearch />
                </div>
              </div>
            </>
          )}
        </div>
      </PageContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  padding: 15px 0;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999999999;
`;

const CurrentUser = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BrowseButton = styled.div`
  cursor: pointer;
  font-family: SlateProMedium, sans-serif;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${colors.warmGraphite};
  user-select: none;
`;

const BoldLink = styled(Link)`
  font-family: SlateProMedium, sans-serif;
  font-size: 12px;
  color: ${colors.warmGraphite};
  cursor: pointer;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  
`;

const InitialsCircleWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`;

const MyCollectionLinkWrapper = styled.div`
  position: relative;
`;

const ItemAddedBubbleWrapper = styled.div`
  position: absolute;
  left: 0;
  top: -4px;
  transform: translateY(-100%);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.5s;
`;

export default Header;
