import React from "react";
import loader from "./images/loader.png";
import styled from "@emotion/styled";

const Loading = ({ size = 235 }) => {
  return (
    <SpinnerContainer>
      <Spinner src={loader} size={size} />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  width: 100%;
  display: contents;
`;

const Spinner = styled.img`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  animation: spin 4s linear infinite;
  opacity: 0.5;
  
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
