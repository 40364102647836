import React from "react";
import {colors} from "config/StyleGuide";
import {BodyCopy} from "components/shared/style-guide/typography";
import styled from "@emotion/styled";
import {PageRowWithPadding} from "components/shared/Layouts";
// todo: use real satchels image
import satchelsHeaderImage from "./satchels.png";

const SatchesHeader = () => (
  <PageRowWithPadding>
    <div className="col-6 offset-3">
      {/* todo: use satchels image  */}
      <ImageWrapper>
        <img alt="" src={satchelsHeaderImage} />
      </ImageWrapper>
    </div>

    <div className="col-4 offset-4">
      <BodyCopyWrapper>
        <BodyCopy>
          Satchel. Holder of stuff. Maybe a day’s worth, some things
          ever-present,
        </BodyCopy>
        <BodyCopy>
          possibly useful, precious perhaps, close to the heart, maybe.
        </BodyCopy>
        <BodyCopy>
          Valise of a refugee, briefcase of a businessman, a woman’s clutch,
        </BodyCopy>
        <BodyCopy>
          pockets inside an adolescent’s backpack, psychological baggage.
        </BodyCopy>
        <BodyCopy>A satchel is personal.</BodyCopy>
      </BodyCopyWrapper>
    </div>
  </PageRowWithPadding>
);

const ImageWrapper = styled.div`
  margin-bottom: -250px;
`;

const BodyCopyWrapper = styled.div`
  color: ${colors.warmGraphite};
  margin-left: -40px;
  margin-right: -40px;
  text-align: center;
`;

export default SatchesHeader;
