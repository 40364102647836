import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import React from "react";
import {H2} from "./style-guide/typography";
import {SubmitButton} from "./FormElements";

export const PageContainer = styled.div`
  width: 1400px;
  margin: 0 auto;
  position: relative;
`;

export const PageBackground = styled.div`
  background-color: ${colors.parchment};
`;

export const PageHero = ({ children }) => (
  <PageHeroContainer>{children}</PageHeroContainer>
);

export const PageHeroTitle = styled.h1`
  font-size: 38px;
  padding: 25px;
`;

export const PageHeroDescription = styled.p`
  padding: 25px;
`;

export const PageHeroContainer = styled.div`
  display: flex;
  height: 400px;
  justify-content: space-between;
  background-color: ${colors.lightGray};
  font-family: SangBleuVersaillesBook, sans-serif;
`;

export const Row = ({ children }) => (
  <div className="row no-gutters">{children}</div>
);

export const RowOverlay = ({ children, pointerEvents }) => (
  <StyledRowOverlay className="row no-gutters" pointerEvents={pointerEvents}>{children}</StyledRowOverlay>
);

const StyledRowOverlay = styled.div`
  pointer-events:  ${ ({pointerEvents}) => pointerEvents ? pointerEvents : 'all'  };
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  right: 0;
`;

export const PageRow = ({ children }) => (
  <div className="row pt-5 no-gutters">{children}</div>
);

export const PageRowWithVerticalPadding = ({ children }) => (
  <div className="row pt-5 pb-5 no-gutters">{children}</div>
);

export const PageRowWithPadding = ({ children }) => (
  <div className="row p-5 no-gutters">{children}</div>
);

export const PageSideBar = ({ children }) => (
  <div className="col-2">{children}</div>
);

export const PageContent = ({ children }) => (
  <div className="col-7 offset-1 pr-0">{children}</div>
);

// todo nir: why is PageContent taking *7* columns instead of *8* ?
export const PageContent8Columns = ({ children }) => (
  <div className="col-8">{children}</div>
);

export const SectionTitle = styled(H2)`
  color: ${colors.warmGraphite};
  margin-bottom: 42px;
`;

export const RandomizeButton = styled(SubmitButton)`
  margin-bottom: 15px;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    min-height: 1000px;
    padding-top: 50px;
`;
