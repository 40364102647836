import React from "react";
import {Col3, Container, Row} from "components/shared/Grid";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import logo from './logo-horizontal-white.png';


const AuthHeader = () => (
  <Container>
    <Header>
      <Col3>
        <img src={logo} alt=""/>
      </Col3>
    </Header>
  </Container>
);

const Header = styled(Row)`
  height: 127px;
  box-sizing: border-box;
  padding: 35px 0;
  margin-bottom: 25px;
  color: ${colors.ink};
`;

export default AuthHeader;
