import styled from "@emotion/styled";

import React from "react";
import {colors} from "../../../../config/StyleGuide";

const WelcomeMessage = () => {
  return (
    <>
      <Title>
        Where the past is <TitleItalic>ever-present</TitleItalic>.
      </Title>
    </>
  );
};

const Title = styled.div`
  font-family: "SangBleuVersaillesRegular", serif;
  color: ${colors.white};
  letter-spacing: 0.02em;
  font-size: 20px;
  margin-top: 8px;
`;

const TitleItalic = styled(Title)`
  font-family: "SangBleuVersaillesRegularItalic", serif;
  display: inline-block;
`;

export default WelcomeMessage;
