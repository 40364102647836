import React from "react";
import {PageContainer} from "components/shared/Layouts";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import EventsHeader from "components/pages/events/EventsHeader";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import Filters from "../../shared/filters/Filters";
import EventFilters from "./EventFilters";
import {useResource} from "../../../hooks/useResource";
import {useCollection} from "../../../hooks/useCollection";
import MediaFilesGrid from "components/shared/MediaFiles/MediaFilesGrid";
import {useGenreFilters} from "../../../hooks/filters/useGenreFilters";
import {Row} from "../../shared/Layouts";
import {useDynamicGenres} from "../../../hooks/filters/useDynamicGenres";
import LoadingMenu from "../../shared/LoadingMenu";
import LoadingMediaCards from "../../shared/LoadingMediaCards";

const Events = ({ location }) => {
  useBreadCrumbs({ label: "Events", path: location.pathname });
  const [topics, topicsLoading] = useResource({ path: "events/topics" });

  const {
    GenreFilters,
    genresQueryString,
    updateAvailableGenres,
    resetGenreFilters
  } = useGenreFilters({
    path: "/events/genres"
  });

  const {
    facetFilters,
    clearFilter,
    resetFilters,
    setFilter,
    isActiveFilter,
    facetsQueryString
  } = useFacetsFilters(resetGenreFilters);

  const buildQuery = (...queries) => queries.join("&");

  const query = buildQuery(facetsQueryString, genresQueryString);

  const {
    loadMore,
    hasMore,
    collection: mediaFiles,
    initialLoading,
    genres,
    loading
  } = useCollection("/events/media", query);

  useDynamicGenres(
    facetFilters,
    genres,
    updateAvailableGenres,
    resetGenreFilters
  );

  // todo: might need to be reusable for other pages
  // reset the genre filters when the facet filters are cleared
  const onResetFilters = (...args) => {
    resetGenreFilters();
    resetFilters(...args);
  };

  const setFilterAndScroll = filter => {
    setFilter(filter);
    window.scrollTo({
      top: 700,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <PageContainer>
      <Row>
        <EventsHeader />
      </Row>

      <Row>
        <div className="col-2 ml-3 pr-0 pb-5">
          <Filters
            values={facetFilters}
            onClear={clearFilter}
            onReset={onResetFilters}
          />

          {topicsLoading && <LoadingMenu />}

          {topics && (
            <EventFilters
              topics={topics}
              onAdd={setFilterAndScroll}
              onClear={clearFilter}
              isActive={isActiveFilter}
              isLoading={topicsLoading}
            />
          )}
        </div>

        {initialLoading ? (
          <LoadingMediaCards />
        ) : (
          <MediaFilesGrid
            mediaFiles={mediaFiles}
            loadMore={loadMore}
            hasMore={hasMore}
            loading={loading}
          >
            <GenreFilters />
          </MediaFilesGrid>
        )}
      </Row>
    </PageContainer>
  );
};

export default Events;
