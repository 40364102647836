import React from "react";
import {PageContainer} from "components/shared/Layouts";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {useResource} from "hooks/useResource";
import styled from "@emotion/styled";
import Filters from "components/shared/filters/Filters";
import PersonInky from "./shared/PersonInky";
import {ENTITIES} from "../../../config/constants";
import {LoaderContainer, RandomizeButton} from "../../shared/Layouts";

import {useGenreFilters} from "../../../hooks/filters/useGenreFilters";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import MediaFilesGrid from "components/shared/MediaFiles/MediaFilesGrid";
import {useCollection} from "hooks/useCollection";
import {useDynamicGenres} from "../../../hooks/filters/useDynamicGenres";
import {withScroll} from "../../../services/utils";
import PersonFilters from "./PersonFilters";
import LoadingMediaCards from "../../shared/LoadingMediaCards";
import Loading from "../../shared/Loading";

const Person = ({match, location}) => {
  const personId = match.params.id;
  const [person] = useResource({path: `/people/${personId}`});

  useBreadCrumbs({label: person, path: location.pathname});

  const {
    GenreFilters,
    genresQueryString,
    updateAvailableGenres,
    resetGenreFilters
  } = useGenreFilters({
    path: `/people/genres/?person_id=${personId}`
  });

  const {
    facetFilters,
    clearFilter,
    resetFilters,
    setFilter,
    isActiveFilter,
    facetsQueryString,
    isFiltersApplied
  } = useFacetsFilters(resetGenreFilters);

  const buildQuery = (...queries) => queries.join("&");

  const query = buildQuery(
      genresQueryString,
      facetsQueryString,
      `${ENTITIES.PERSON}_id=${personId}`
  );

  const {
    shuffle,
    collection: mediaFiles,
    hasMore,
    loadMore,
    genres,
    loading,
    initialLoading,
  } = useCollection("/people/media", query);


  useDynamicGenres(
      facetFilters,
      genres,
      updateAvailableGenres
  );

  if(!person) return (
      <LoaderContainer>
        <Loading/>
      </LoaderContainer>
  );

  return (
        <PageContainer>
          <div className="row">
            <div className="col-4 offset-4">
              <HeaderContainer>
                <PersonInky person={person}/>
              </HeaderContainer>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-2">
              <RandomizeButton onClick={shuffle}>Randomize</RandomizeButton>

              <Filters
                  values={facetFilters}
                  onClear={clearFilter}
                  onReset={resetFilters}
              />

              <PersonFilters
                  person={person}
                  onAdd={withScroll(setFilter, 350)}
                  onClear={clearFilter}
                  isActive={isActiveFilter}
              />
            </div>

            {initialLoading ? <LoadingMediaCards/> : (
                <MediaFilesGrid
                    mediaFiles={mediaFiles}
                    hasMore={hasMore}
                    loadMore={loadMore}
                    loading={loading}
                    reloalding={loading}
                    isFiltersApplied={isFiltersApplied}>
                  <GenreFilters/>

                </MediaFilesGrid>
            )}

          </div>
        </PageContainer>
  );
};

const HeaderContainer = styled.div`
  padding: 25px 0;
`;

export default Person;
