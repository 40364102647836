import React, {useEffect, useState} from "react";
import NewSlider from "components/shared/NewSlider/NewSlider";
import ReactPlayer from "react-player";
import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";
import Icon from "../Icon";
import {size} from "lodash/fp";
import MediaActions from "../media-actions/MediaActions";
import DownloadMediaAction from "../media-actions/DownloadMediaAction";
import ToggleMediaFullScreenAction from "../media-actions/ToggleMediaFullScreenAction";
import {getFileType, getMediaFileUrl, getPreviewUrl, getThumbnailUrl} from "services/media-file.utils";
import LaunchPdfButton from "../LaunchPdfButton";
import ThumbnailImage from "components/shared/ThumbnailImage";

const NAV_ICON_SIZE = 20;

const VideoPreview = ({url, isVisible}) => {
  return isVisible && (
      <StyledVideoPreview>
        <ReactPlayer url={url} controls/>
      </StyledVideoPreview>
  );
};

const getFilePreview = (item, isVisible) => {
  const fileType = getFileType(item);
  const filePreviewUrl = getPreviewUrl(item);
  const fileThumbnailUrl = getThumbnailUrl(item);
  const mediaFileUrl = getMediaFileUrl(item);

  switch (fileType) {
    case 4:
      return (
          <PdfPeviewWrapper>
          <VideoPreview
              key={item.id}
              url={mediaFileUrl}
              isVisible={isVisible}
          />
            <div style={{ height: 30 }}/>
            <LaunchPdfButton
                label={"launch video"}
                onClick={() => window.open(mediaFileUrl)}
            />
          </PdfPeviewWrapper>
      );
    case 5:
      return (
          <PdfPeviewWrapper key={item.id}>
            <PdfImagePreview url={fileThumbnailUrl}/>
            <PdfButtonWrapper>
              <LaunchPdfButton
                  label={"launch pdf"}
                  onClick={() => window.open(mediaFileUrl)}
              />
            </PdfButtonWrapper>
          </PdfPeviewWrapper>
      );

    case 6:
      return (
          <PdfPeviewWrapper key={item.id}>
            <PdfImagePreview url={filePreviewUrl}/>
            <PdfButtonWrapper>
              <LaunchPdfButton
                  label={"launch map"}
                  onClick={() => window.open(mediaFileUrl)}
              />
            </PdfButtonWrapper>
          </PdfPeviewWrapper>
      );

    case 2:
    default:
      return <ImagePreview key={item.id} url={filePreviewUrl}/>;
  }
};

const MediaViewerPreviewPane = ({
                                  isMetadataVisible,
                                  mediaFiles,
                                  currentFileIndex,
                                  onSelectMediaFile,
                                  hasMore,
                                  loadMore
                                }) => {
  const [currentIndex, setIndex] = useState(currentFileIndex);

  const onChange = newIndex => {
    setIndex(newIndex);
  };

  const nextSlide = () => {
    setIndex(index => Math.min(index + 1, size(mediaFiles) - 1));
  };

  const previousSlide = () => {
    setIndex(index => Math.max(0, index - 1));
  };

  const onKeyUp = ({key}) => {
    if(key === "ArrowRight") {
      return nextSlide();
    }

    if(key === "ArrowLeft") {
      return previousSlide();
    }
  };

  const keyExtractor = ({item}) => item.id;

  const renderPreviewItem = ({item: mediaFile, index: mediaFileIndex}) =>
      getFilePreview(mediaFile, currentIndex === mediaFileIndex);

  const renderThumbnailItem = ({item, index}) => (
      <ThumbnailContainer>
        <ThumbnailImage
            isActive={currentIndex === index}
            onClick={() => setIndex(index)}
            src={getThumbnailUrl(item)}
            alt={item.title}
        />
      </ThumbnailContainer>
  );

  useEffect(() => {
    window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  }, [currentIndex]);

  useEffect(() => {
    onSelectMediaFile(currentIndex);
  }, [currentIndex]);

  const currentMediaFile = mediaFiles[currentIndex];
  const currentFileMediaUrl = getMediaFileUrl(currentMediaFile);
  const notVideo = getFileType(currentMediaFile) !== 4;

  const renderDownloadAction = () => {
    return currentFileMediaUrl ? (
        <DownloadMediaAction media={currentFileMediaUrl}/>
    ) : null;
  };

  return (
      <PreviewPane isMetadataVisible={isMetadataVisible} isVideo={!notVideo} >
        <MediaFilePane>
          {notVideo && (
              <MediaActions>
                <ToggleMediaFullScreenAction
                    onClick={() => window.open(currentFileMediaUrl, "_blank")}
                />
                {renderDownloadAction()}
              </MediaActions>
          )}
          <div className="pt-5">
            <NewSlider
                onChange={onChange}
                loadMore={loadMore}
                hasMore={hasMore}
                slidesToShow={1}
                slideIndex={currentIndex}
                keyExtractor={keyExtractor}
                data={mediaFiles || []}
                renderItem={renderPreviewItem}
            />
          </div>

          <div className="pt-5">{}</div>
        </MediaFilePane>

        <ThumbnailsPane>
          <PrevNavIcon
              type="PrevActive"
              size={NAV_ICON_SIZE}
              onClick={previousSlide}
              storke={colors.warmGraphite}
              hoverStroke={colors.warmGraphite}
          />
          <NewSlider
              onChange={onChange}
              loadMore={loadMore}
              hasMore={hasMore}
              slidesToShow={7}
              numSlidesOutsideWindow={3}
              slideIndex={currentIndex}
              centerMode={true}
              numSlidesPrefetch={3}
              data={mediaFiles || []}
              keyExtractor={keyExtractor}
              renderItem={renderThumbnailItem}
          />
          <NextNavIcon
              type="NextActive"
              size={NAV_ICON_SIZE}
              onClick={nextSlide}
              storke={colors.warmGraphite}
              hoverStroke={colors.warmGraphite}
          />
        </ThumbnailsPane>
      </PreviewPane>
  );
};

const MediaFilePane = styled.div`
  background-color: ${colors.mediaViewerBackground};
  flex: 1;
`;

const METADATA_WIDTH = 340;

const PreviewPane = styled.div`
  position: relative;
  width: calc(
    100% - 50px - ${props => (props.isMetadataVisible ? METADATA_WIDTH : 0)}px
  );
  min-width: 780px;
  padding-top: 15px;
  background-color: ${colors.mediaViewerBackground};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  ${ ({isVideo}) => isVideo && "align-items: center; z-index: -1;"}
`;

const ThumbnailsPane = styled.div`
  height: 115px;
  background-color: ${colors.stone};
  width: 100%;
  padding: 0 50px;
  position: relative;
`;

const Preview = styled.div`
  height: 425px;
  width: 100%;
`;

const ImagePreview = styled(Preview)`
  background-color: ${colors.mediaViewerBackground};
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
`;

const PdfImagePreview = styled(ImagePreview)`
  width: 100%;
`;

const PdfButtonWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

const PdfPeviewWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


const StyledVideoPreview = styled(Preview)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ThumbnailContainer = styled.div`
  height: 100%;
  padding: 10px;
  background: ${colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NavIcon = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 999;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PrevNavIcon = styled(NavIcon)`
  left: 0;
`;

const NextNavIcon = styled(NavIcon)`
  right: 0;
`;

export default MediaViewerPreviewPane;
