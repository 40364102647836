import styled from "@emotion/styled";
import React from "react";
import {UnstyledLink} from "../../../shared/UnstyledLink";
import ClanInky from "./ClanInky";

const ClansInkyGrid = ({ clans, col = 6 }) => {
  return (
    <InkyContainer>
      {(clans || []).map(clan => (
        <div className={`col-${col}`} key={clan.id}>
          <UnstyledLink to={`/clans/${clan.id}`}>
            <ClanInky clan={clan} />
          </UnstyledLink>
        </div>
      ))}
    </InkyContainer>
  );
};

const InkyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export default ClansInkyGrid;
