import React from 'react';
import Menu from "../../../shared/Menu";
import {ENTITIES} from "../../../../config/constants";
import FilterMenuItem from "../../../shared/FilterMenuItem";
import {sortPeopleByLastName} from "../../../../services/filters-utils";
import {uniqBy} from 'lodash';


const MediaFilesRelatedPeopleFilter = ({ mediaFiles = [], onAdd, onClear, isActive }) => {

  if(!mediaFiles.length) return null;

  const flatPeople = mediaFiles.map( mediaFile => mediaFile.people ).flat();
  const uniquePeople = uniqBy(flatPeople, person => person.id);
  const people = sortPeopleByLastName(uniquePeople);

  const renderPerson = (person) => {
    const personFilter = {
      ...person,
      name: `${person.first_name} ${person.last_name}`,
      entity: ENTITIES.PERSON
    };
    return (
        <FilterMenuItem
            key={person.id}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={personFilter}
        />
    )
  };

  return (
      <Menu
          label={`Related People`}
          itemsLength={people.length}>
        {people.map(relatedPerson => renderPerson(relatedPerson))}
      </Menu>
  );
};

export default MediaFilesRelatedPeopleFilter;
