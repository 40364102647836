import React from "react";
import styled from "@emotion/styled";
import {H6} from "components/shared/style-guide/typography";
import {colors} from "config/StyleGuide";

const FieldSeparator = ({ label, color = colors.white, padding = 15 }) => (
  <SeparatorContainer padding={padding}>
    <Label color={color}>{label}</Label>
    <Line color={color} />
  </SeparatorContainer>
);

const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${({ padding }) => padding || 15}px 0;
`;

const Label = styled(H6)`
  display: inline-block;
  padding-right: 10px;
  color: ${({ color }) => color || "#f1f2f2"};
`;

const Line = styled.div`
  display: inline-block;
  flex-grow: 1;
  height: 1px;
  background-color: ${({ color }) => color || "white"};
  padding-left: 5px;
  box-sizing: border-box;
`;

export default FieldSeparator;
