import {useEffect, useRef, useState} from "react";
import {isEmpty, size} from "lodash/fp";
import apiRequest from "../services/api";

export function useCollection(path, query) {
  const [collection, setCollection] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [genres, setGenres] = useState([]);
  const [reLoading, setLoading] = useState(true);
  const initialLoading = useRef(true);

  const incrementPageNumber = () => setPageNumber(pageNumber => pageNumber + 1);
  const resetPageNumber = () => setPageNumber(1);
  const resetCollection = () => setCollection([]);

  const nextPage = async () => {
    setHasMore(false); // https://github.com/CassetteRocks/react-infinite-scroller/issues/143
    incrementPageNumber();
  };

  const shuffle = () => {
    resetCollection();
    resetPageNumber();

    fetchData(true);
  };

  const fetchApiData = async path => {
    setLoading(true);

    if (initialLoading.current) {
      setLoading(true);
    }

    const apiResponse = await apiRequest({ path });
    initialLoading.current = false;


    const { total_count, items, genres } = apiResponse.data;

    // autocomplete can return a result which count under 18
    if (total_count < 18) {
      setCollection(state => {
        return state.concat(items)
      });

    } else if (size(collection) < total_count && !isEmpty(items)) {
      setCollection(state => {
        return state.concat(items)
      });
      setHasMore(true);
    }
    setGenres(genres);
    setLoading(false);
  };

  const fetchData = (withShffle = false) => {
    let baseQury = `?page=${pageNumber}&${query}`;
    let shuffleQuery = `&shuffle=${Date.now()}`;

    const fullQuery = withShffle ? baseQury + shuffleQuery : baseQury;
    return fetchApiData(`/${path}${fullQuery}`);
  };

  useEffect(() => {
    resetCollection();
    resetPageNumber();
  }, [query, path]);

  useEffect(() => {
    fetchData(false);
  }, [pageNumber, query, path]);

  return {
    collection,
    loadMore: nextPage,
    hasMore,
    shuffle,
    initialLoading: initialLoading.current,
    genres,
    loading: reLoading,
  };
}
