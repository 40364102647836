import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Rule = styled.div`
  width: ${({ width = "100%" }) => width};
  height: ${({ height }) => `${height}px`};
  background-color: ${({ color }) => color};
  margin: 0;
`;

Rule.propTypes = {
  height: PropTypes.number,
  margin: PropTypes.number
};

Rule.defaultProps = {
  height: 1,
  margin: 0
};

export default Rule;
