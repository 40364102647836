import React from "react";
import {Link} from "react-router-dom";
import {PageRow, PageSideBar, Row} from "../../shared/Layouts";
import SearchResultsCategorySelection from "./shared/SearchResultsCategorySelection";
import SideMenu from "components/shared/SideMenu";
import {useSearch} from "hooks/useSearch";
import {map} from "lodash/fp";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {useCollection} from "hooks/useCollection";
import StaticMap from "../../shared/StaticMap";
import {getZoomLevel} from "../../../services/map-utils";

const PlacesSearchResults = ({ location, resultByTypeCount }) => {
  const { searchQuery, longQuery } = useSearch(location.search);

  const query = `query=${longQuery || searchQuery}`;

  const { collection: places } = useCollection(`locations/search`, query);

  if (!places) return null;

  const placesWithZoom = places.map( place => ({
    ...place,
    zoom: getZoomLevel(place)
  }));

  return (
    <>
      <SearchResultsCategorySelection category={"places"} query={searchQuery} resultByTypeCount={resultByTypeCount}/>

      <PageRow>
        <PageSideBar>
          <SideMenu />
        </PageSideBar>
        <div className="col-8  pr-0">
          <Row>
            {map(
              place => (
                <PlaceWrapper
                  to={`/places/${place.id}`}
                  className="col-3 offset-1"
                  key={place.id}
                >
                  <MapWrapper>
                    <StaticMap lat={place.coordinates[0]} lon={place.coordinates[1]} zoom={place.zoom} height={210} width={233} />
                  </MapWrapper>
                  <Title>{place.display_name || "No Title"}</Title>
                  <ShortDescription>
                    {place.short_description || "No Description"}
                  </ShortDescription>
                </PlaceWrapper>
              ),
                placesWithZoom
            )}
          </Row>
        </div>
      </PageRow>
    </>
  );
};

const PlaceWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 100px;
  text-align: center;
  text-decoration: none;
`;
const MapWrapper = styled.div`
  width: 100%;
  height: 210px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 22px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  font-family: SangBleuVersaillesBook;
  margin-bottom: 15px;
`;

const Region = styled.div`
  font-family: SlateProMedium;
  font-size: 10px;
  letter-spacing: 0.15em;
  color: ${colors.gray};
  margin-bottom: 15px;
`;

const ShortDescription = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 11px;
  letter-spacing: 0.02em;
  line-height: 18px;
  color: ${colors.warmGraphite};
`;

export default PlacesSearchResults;
