import React, {useState} from "react";
import {css} from "@emotion/core";
import styled from "@emotion/styled";
import noImageUrl from "components/shared/images/no-image.png";

const ThumbnailImage = ({ isActive, onClick, src, alt }) => {
  const [isError, setIsError] = useState(false);

  const onError = () => setIsError(true);

  return (
    <Wrapper onClick={onClick}>
      <StyledThumbnailImage
        src={isError ? noImageUrl : src}
        isActive={isActive}
        url={src}
        onError={onError}
        alt={alt}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledThumbnailImage = styled.img`
  /* https://stackoverflow.com/questions/10134935/weird-css3-transition-flickering */
  -webkit-backface-visibility: hidden;
  width: auto;
  max-width: 100%;
  height: 60px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  ${({ isActive }) =>
    isActive &&
    css`
      transform: translate3d(0, -10px, 0);
      box-shadow: 0 2px 7px 1px rgba(20, 20, 20, 0.5);
    `}
  &:hover {
    opacity: 1;
  }
`;

export default ThumbnailImage;
