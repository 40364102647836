import FilterMenuItem from "../../../shared/FilterMenuItem";
import React, {useMemo} from "react";
import Menu from "../../../shared/Menu";
import {ENTITIES} from "../../../../config/constants";
import {getPronoun} from "../../../../services/filters-utils";

const RELATIONSHIPS_MAP = {
  "Grandmother": 1,
  "Grandfather": 2,
  "Mother": 3,
  "Stepmother": 4,
  "Father": 5,
  "Stepfather": 6,
  "Husband": 7,
  "Wife": 8,
  "Daughter": 9,
  "Stepdaughter": 10,
  "Son": 11,
  "Stepson": 12,
  "Sister": 13,
  "Brother": 14,
  "Granddaughter": 15,
  "Grandson": 16,
  "Aunt": 17,
  "Uncle": 18,
  "Niece": 19,
  "Nephew": 20,
  "Cousin": 21,
  "Colleague": 22,
  "Friend": 23,
  "Employee": 24,
  "Employer": 25,
};

const RelatedPersonFilter = ({person, onAdd, onClear, isActive}) => {

   if(!person.relationships || !person.relationships.length) return null;

  // sort person relationship
  const person_relationships = useMemo( () => {
      return person.relationships.map(relationship => {
          return {...relationship, order: RELATIONSHIPS_MAP[relationship.type]}
      }).sort((a, b) => a.order - b.order);
  }, [person]);

  const renderRelatedPerson = (relatedPerson) => {
    const personFilter = {
      ...relatedPerson,
      entity: ENTITIES.PERSON
    };

    return (
        <FilterMenuItem
            key={relatedPerson.id}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={personFilter}
            subValue={personFilter.type}
        />
    )
  };

  return (
      <Menu
          label={`${getPronoun(person.gender)} People`}
          itemsLength={person.relationships.length}>
        {person_relationships.map(relatedPerson => renderRelatedPerson(relatedPerson))}
      </Menu>
  );
};

export default RelatedPersonFilter;