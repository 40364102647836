import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;
const DEFAULT_HEADERS = {
  "content-type": "application/json"
};

const LOCAL_STORAGE_KEY = "T3T:tokens";

axios.interceptors.request.use(
  config => {
    const { accessToken, clientToken, userToken } =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};

    Object.assign(config.headers.common, {
      "token-type": "Bearer",
      "access-token": accessToken,
      client: clientToken,
      uid: userToken
    });

    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => {
    const accessToken = response.headers["access-token"];
    const clientToken = response.headers["client"];
    const userToken = response.headers["uid"];

    if (accessToken) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ accessToken, clientToken, userToken })
      );
    }

    return response;
  },
  error => Promise.reject(error.response)
);

const apiRequest = async ({ path, method = "GET", data, headers = {} }) => {
  try {
    const response = await axios({
      url: API_URL + path,
      method,
      data: data || {},
      headers: Object.assign({}, DEFAULT_HEADERS, headers)
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const passwordResetApiRequest = async ({ path, method = "GET", data, headers = {} }) => {
  const instance = axios.create();

  try {
    const response = await instance({
      url: API_URL + path,
      method,
      data: data || {},
      headers: Object.assign({}, DEFAULT_HEADERS, headers)
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const addToCollection = async (userId, fileId) => {
  const response = await apiRequest({
    path: `/members/${userId}/add_file?file_id=${fileId}`,
    method: "POST"
  });
  return response;
};

export const removeFromCollection = async (userId, fileId) => {
  await apiRequest({
    path: `/members/${userId}/remove_file?file_id=${fileId}`,
    method: "POST"
  });
};

export const showMyCollectionOnMyProfile = async userId => {
  await apiRequest({
    path: `/members/${userId}/set_private_collection`,
    method: "POST",
    data: { status: false }
  });
};

export const hideMyCollectionOnMyProfile = async userId => {
  await apiRequest({
    path: `/members/${userId}/set_private_collection`,
    method: "POST",
    data: { status: true }
  });
};

export const getIsMyCollectionPublic = async userId => {
  // todo nir: implement
  return new Promise(resolve =>
    setTimeout(() => resolve(Math.random() > 0.5), 1000)
  );
};

export const externalApiRequest = async ({ path, method = "GET", data, headers = {} }) => {
  try {
    const response = await axios({
      url: path,
      method,
      data: data || {},
      headers
    });

    return response;
  } catch (error) {
    return error;
  }
};

export default apiRequest;
