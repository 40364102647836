import React from "react";
import styled from "@emotion/styled";
import {PageContainer, PageRow, PageSideBar} from "components/shared/Layouts";
import SearchResultsCategorySelection from "components/pages/search-results/shared/SearchResultsCategorySelection";
import {useSearch} from "hooks/useSearch";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import {isEmpty, map} from "lodash/fp";
import {UnstyledLink} from "components/shared/UnstyledLink";
import CollectionSearchResultsListItem from "./CollectionSearchResultsListItem";
import noImage from "components/shared/images/no-image.png";
import {useCollection} from "../../../../hooks/useCollection";
import SideMenu from "../../../shared/SideMenu";

const CollectionSearchResults = ({ location, resultByTypeCount }) => {
  const { searchQuery } = useSearch(location.search);

  const query = `query=${searchQuery}`;

  const { collection: collections, loadMore, hasMore } = useCollection(
    `/collections/search`,
    query
  );


  return (
    <PageContainer>
      <SearchResultsCategorySelection
        category={"collections"}
        query={searchQuery}
        resultByTypeCount={resultByTypeCount}
      />

      <PageRow>
        <PageSideBar>
          <SideMenu />
        </PageSideBar>

        <div className="col-9 offset-1">
          {!isEmpty(collections) && (
            <ScrollableInfiniteScroll
              key={query}
              hasMore={hasMore}
              loadMore={loadMore}
              useWindow={true}
            >
              <CardsWrapper>
                {map(
                  item => (
                    <Card className="card" key={item.id}>
                      <UnstyledLink to={`/collection/${item.id}`}>
                        <CollectionSearchResultsListItem
                          title={item.name}
                          description={item.description}
                          startYear={item.date_range[0]}
                          endYear={item.date_range[1]}
                          img={item.thumbnail || noImage}
                        />
                      </UnstyledLink>
                    </Card>
                  ),
                  collections
                )}
              </CardsWrapper>
            </ScrollableInfiniteScroll>
          )}
        </div>
      </PageRow>
    </PageContainer>
  );
};

const CardsWrapper = styled.div`
  column-count: 3;
`;

const Card = styled.div`
  background-color: transparent;
  border: none;
  width: 260px;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  display: inline-block;
`;

export default CollectionSearchResults;
