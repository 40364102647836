import React, {useContext, useEffect, useState} from "react";
import {BaseButton, ErrorMessage, FormWrapper, SemiTransparentInput, Space} from "../../shared/FormElements";
import {AuthContext} from "../../context-providers/Auth";
import {withRouter} from "react-router-dom";
import apiRequest from "services/api";
import AuthHeader from "./shared/AuthHeader";
import FieldSeparator from "./shared/FieldSeparator";
import {PageContainer, Row} from "../../shared/Layouts";
import WelcomeMessage from "./shared/styled";
import {TextLink} from "../../shared/style-guide/typography";
import loginVideo from "./shared/t3t_login_vid.mp4";
import {VideoBackground} from "./shared/VideoBackground";
import MessageAlert from "../../shared/MessageAlert";

const Login = ({history}) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);
  const [accountDeleted, setAccountDeleted] = useState(false);

  const {setCurrentUser} = useContext(AuthContext);

  useEffect(() => {
    const isDeleted = localStorage.getItem('T3T_ACCOUNT_DELETED');
    if(isDeleted !== null && isDeleted === 'TRUE') {
      setAccountDeleted(true);
    }
  }, []);

  const removeDeletedAccount = () => {
    localStorage.removeItem('T3T_ACCOUNT_DELETED');
    setAccountDeleted(false);
  };

  const signIn = async event => {
    event.preventDefault();

    if(!email || !password) {
      return setError("Invalid login credentials. Please try again.");
    }

    const response = await apiRequest({
      path: "/auth/sign_in",
      method: "POST",
      data: {email, password}
    });

    if(response.status && response.status !== 200) {
      return setError(response.data.errors[0]);
    }

    return setCurrentUser(response.data.data);
  };

  return (
      <>
        <VideoBackground src={loginVideo} type="video/mp4" autoPlay muted loop/>
        <PageContainer>
          <AuthHeader/>
          <div>
            <Row>
              <div className="col-3">
                {accountDeleted && (
                    <MessageAlert closeable={true} onClose={removeDeletedAccount}
                                  message={"You have successfully deleted your account."}/>
                )}
                <WelcomeMessage/>

                <Space value="20px"/>

                <FormWrapper noValidate onSubmit={signIn}>
                  <Row>
                    <div className="col-12">
                      <FieldSeparator label="Login"/>

                      <Space value="10px"/>

                      <SemiTransparentInput
                          placeholder="Email Address"
                          onChange={({target}) => setEmail(target.value)}
                          invalid={!!error}
                      />
                    </div>
                  </Row>

                  <Space value="18px"/>

                  <Row>
                    <div className="col-12">
                      <SemiTransparentInput
                          type="password"
                          placeholder="Password"
                          onChange={({target}) => setPassword(target.value)}
                          invalid={!!error}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </div>
                  </Row>

                  <Space value="18px"/>

                  <Row>
                    <div className="col-6">
                      <BaseButton type="submit">LOGIN</BaseButton>
                    </div>

                    <div className="col-6">
                      <TextLink to="/password">Forgot Your Password?</TextLink>
                    </div>
                  </Row>

                  <Space value="10px"/>

                  <Row>
                    <div className="col-12">
                      <FieldSeparator label="New to t3t?"/>

                      <Space value="10px"/>

                      <BaseButton onClick={() => history.push("/register")}>
                        REQUEST ACCESS
                      </BaseButton>
                    </div>
                  </Row>
                </FormWrapper>
                <br/>
              </div>
            </Row>
          </div>
        </PageContainer>
      </>
  );
};


export default withRouter(Login);
