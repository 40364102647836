import React, {useContext, useState} from "react";
import {addToCollection, removeFromCollection} from "../services/api";
import {get} from "lodash/fp";
import {AuthContext} from "../components/context-providers/Auth";

export const MyCollectionStatusContext = React.createContext();

export const MyCollectionStatusProvider = ({children}) => {
  const [isInMyCollection, setIsInMyCollection] = useState(false);

  return (
      <MyCollectionStatusContext.Provider
          value={{isInMyCollection, setIsInMyCollection}}
      >
        {children}
      </MyCollectionStatusContext.Provider>
  );
};

export const useMyCollectionStatus = () => {
  const {isInMyCollection, setIsInMyCollection} = useContext(
      MyCollectionStatusContext
  );

  const addToMyCollection = () => {
    setIsInMyCollection(true);
    setTimeout(() => setIsInMyCollection(false), 2000);
  };

  const removeFromMyCollection = () => setIsInMyCollection(false);

  return {isInMyCollection, addToMyCollection, removeFromMyCollection};
};

export function useMyCollection(file, isInMyCollection) {
  const [isInCollection, setIsInCollection] = useState(isInMyCollection);
  const {addToMyCollection} = useMyCollectionStatus();
  const {currentUser} = useContext(AuthContext);

  const addOrRemoveFromMyCollection = async event => {
    event.preventDefault();
    event.stopPropagation();

    setIsInCollection(!isInCollection);

    if(isInCollection) {
      removeFromCollection(currentUser.id, file.id);
    } else {
      const success =
          get(
              "data.status.success",
              await addToCollection(currentUser.id, file.id)
          ) === true;

      if(success) {
        addToMyCollection();
      }
    }
  };

  return {isInCollection, addOrRemoveFromMyCollection}
}
