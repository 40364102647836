import React from "react";
import Loading from "./Loading";
import styled from "@emotion/styled";


const LoadingMediaCards = () => {

  return (
      <Container>
        <Loading size={75}/>
      </Container>
  )
};

const Container = styled.div`
  display: inline-block;
  position: fixed;
  top: 50%;
  z-index: 999;
  right: calc(50% - 27px);
`;

export default LoadingMediaCards;
