import React from "react";
import SearchResultsHeader from "components/pages/search-results/shared/SearchResultsHeader";
import {Redirect, Route, Switch} from "react-router-dom";
import MediaFilesSearchResults from "components/pages/search-results/MediaFilesSearchResults";
import PeopleSearchResults from "components/pages/search-results/PeopleSearchResults";
import PlacesSearchResults from "components/pages/search-results/PlacesSearchResults";
import ClanSearchResults from "components/pages/search-results/ClanSearchResults";
import CollectionSearchResults from "components/pages/search-results/CollectionSearchResults/CollectionSearchResults";
import {PageContainer} from "components/shared/Layouts";
import {useSearch} from "../../../../hooks/useSearch";


const SearchResultsRouter = ({ location }) => {
  const {resultCount, searchQuery, resultByTypeCount} = useSearch(location.search);

  return (
    <>
      <PageContainer>
        <SearchResultsHeader resultCount={resultCount} searchQuery={searchQuery}/>
        <Switch>
          <Route
            exact
            path={"/search/media"}
            render={(props) => <MediaFilesSearchResults {...props} resultByTypeCount={resultByTypeCount} />}
          />
          <Route
            exact
            path={"/search/people"}
            render={(props) => <PeopleSearchResults {...props} resultByTypeCount={resultByTypeCount} />}
          />
          <Route
            exact
            path={"/search/places"}
            render={(props) => <PlacesSearchResults {...props} resultByTypeCount={resultByTypeCount} />}
          />

          <Route
              exact
              path={"/search/clans"}
              render={(props) => <ClanSearchResults {...props} resultByTypeCount={resultByTypeCount} />}
              />

          <Route
            exact
            path={`/search/collection`}
            render={(props) => <CollectionSearchResults {...props} resultByTypeCount={resultByTypeCount} />}
          />
          <Redirect from="*" to="/" />
        </Switch>
      </PageContainer>
    </>
  );
};

export default SearchResultsRouter;
