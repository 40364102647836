import React from "react";
import {useCollection} from "hooks/useCollection";
import MediaFiles from "components/shared/MediaFiles/MediaFiles";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import NoResults from "../../shared/NoResults";
import {PageRow, PageSideBar, Row} from "../../shared/Layouts";
import SearchResultsCategorySelection from "./shared/SearchResultsCategorySelection";
import {useSearch} from "hooks/useSearch";
import {isEmpty} from "lodash/fp";
import MediaFilesSearchResultsFilters from "./MediaFilesSearchResultsFilters";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import {noop, withScroll} from "../../../services/utils";
import Filters from "../../shared/filters/Filters";

const MediaFilesSearchResults = ({ location, resultByTypeCount }) => {
  const { longQuery, searchQuery } = useSearch(location.search);

  const buildQuery = (...queries) => queries.join("&");

  const {
    facetFilters,
    clearFilter,
    resetFilters,
    setFilter,
    isActiveFilter,
    facetsQueryString,
    isFiltersApplied
  } = useFacetsFilters(noop);

  const query = buildQuery(
      facetsQueryString,
      `query=${longQuery ? longQuery : searchQuery }`
  );

  const { loadMore, hasMore, collection: mediaFiles } = useCollection(
    "/media_files/search",
    query
  );


  return (
    <>
      <SearchResultsCategorySelection category={"media"} query={searchQuery} resultByTypeCount={resultByTypeCount} />

      <PageRow>
        <PageSideBar>
          <Filters
            values={facetFilters}
            onClear={clearFilter}
            onReset={resetFilters} />

          <MediaFilesSearchResultsFilters mediaFiles={mediaFiles}
                                          query={query}
                                          onAdd={withScroll(setFilter, 350)}
                                          onClear={clearFilter}
                                          isActive={isActiveFilter}
          />
        </PageSideBar>

        <div className="col-8 offset-1 pr-0">
          <Row>
            {!isEmpty(mediaFiles) ? (
              <ScrollableInfiniteScroll
                style={{ width: "100%" }}
                key={searchQuery}
                hasMore={hasMore}
                loadMore={loadMore}
              >
                <MediaFiles
                  mediaFiles={mediaFiles}
                  hasMore={hasMore}
                  loadMore={loadMore}
                />
              </ScrollableInfiniteScroll>
            ) : (
              <NoResults />
            )}
          </Row>
        </div>
      </PageRow>
    </>
  );
};

export default MediaFilesSearchResults;
