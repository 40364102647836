import apiRequest from "../services/api";
import {useEffect, useState} from "react";
import history from "config/history";

export function useSearch(locationSearch, newTab = false) {
  const params = new URLSearchParams(locationSearch);
  const query = params.get("query");

  const [resultByTypeCount, setResultByTypeCount] = useState({});
  const [resultCount, setResultCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(query);
  const [longQuery, setLongQuery] = useState(query);

  const countSearchResults = async () => {
    const response = await apiRequest({path: `/search?query=${query}`});

    setSearchQuery(query);
    setLongQuery(response.data.query);
    setResultCount(response.data.results.length);
    setResultByTypeCount(countByType(response.data.results))
  };

  const countByType = (arr) => {
    const countResults = {};

    arr.forEach(item => {

      if(!countResults[item.searchable_type]) {
        countResults[item.searchable_type] = []
      }

      countResults[item.searchable_type].push(item)

    });

    Object.keys(countResults).forEach(key => {
      countResults[key] = countResults[key].length
    });

    return countResults;
  };

  const submitSearch = (value, searchType = "media") => {
    newTab
        ? window.open(
        window.location.origin + `/search/${searchType}?query=${value}`
        )
        : history.push(`/search/${searchType}?query=${value}`);
  };

  useEffect(() => {
    countSearchResults();
  }, [query]);

  return {resultByTypeCount, resultCount, searchQuery, longQuery, submitSearch};
}
