import React, {useMemo} from "react";
import {colors} from "../../../config/StyleGuide";
import Tags from "../Tags";
import RelatedPeopleTags from "../RelatedPeopleTags";
import styled from "@emotion/styled";
import Icon from "../Icon";
import {useSearch} from "hooks/useSearch";
import ButtonWithArrow from "../ButtonWithArrow";
import {UnstyledLink} from "../UnstyledLink";
import ShareModal from "components/shared/ShareModal";
import {getFileShareUrl} from "services/media-file.utils";
import {useMyCollection} from "../../../hooks/useMyCollectionStatus";

const MediaViewerDetailsPane = ({ selectedMediaFile }) => {
  const { submitSearch } = useSearch(null, true);
  const {isInCollection, addOrRemoveFromMyCollection} = useMyCollection(selectedMediaFile, selectedMediaFile.favorite);

  const keywords = (selectedMediaFile.keywords[0] || "")
    .split(";")
    .map(keyword => {
      return {
        label: keyword,
        value: keyword
      };
    });

  const onPersonSelect = personId =>
    window.open(window.location.origin + `/people/${personId}`);

  const validateKeywords = useMemo(() => {
    return keywords.filter(keyword => keyword.value.length > 0);
  }, [selectedMediaFile]);

  return (
    <MetadataWrapper>
      <Actions>
        <IconWrapper>
          <ShareModal
            linkUrl={getFileShareUrl(selectedMediaFile)}
            shareIconFill={colors.warmGraphite}
            iconSize={18}
          />
        </IconWrapper>
        <IconWrapper>
          <AddToMyCollectionIcon type={isInCollection ? "HeartFill" : "HeartLine"}
                                 fill={colors.black}
                                 onClick={addOrRemoveFromMyCollection}
                                 size={18} />
        </IconWrapper>
      </Actions>

      <Label>
        {selectedMediaFile.genre || "No Genre"}{" "}
        {selectedMediaFile.display_date &&
          `| ${selectedMediaFile.display_date} `}
      </Label>

      <Title>{selectedMediaFile.title || "No Title"}</Title>

      <Caption>{selectedMediaFile.caption || "No Caption"}</Caption>

      <ShortDescription>
        {selectedMediaFile.short_description || "No Short description"}
      </ShortDescription>

      <ReadMoreLink to={`/media/${selectedMediaFile.id}`}>
        <ButtonWithArrow label="Read More" />
      </ReadMoreLink>

      {validateKeywords.length > 0 && (
        <Tags label={"Keywords"} tags={keywords} onTagSelect={submitSearch} />
      )}

      <RelatedPeopleTags
        label={"People"}
        people={selectedMediaFile.people}
        onTagSelect={onPersonSelect}
      />
    </MetadataWrapper>
  );
};

const ReadMoreLink = styled(UnstyledLink)`
  display: inline-block;
  margin-bottom: 8px;
`;

const MetadataWrapper = styled.div`
  height: 100%;
  padding: 40px 50px 10px 0;
  align-items: start;
  background-color: ${colors.white};
  overflow-x: hidden;
  color: ${colors.lightGray};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const MetadataContent = styled.p`
  margin-bottom: 20px;
`;

const Actions = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  margin-right: 4px;
`;

const Label = styled.div`
  font-family: SlateProRegular, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.reed};
  font-weight: 100;
  margin: 12px 0 8px 0;
  letter-spacing: 0.12em;
`;

const Title = styled(MetadataContent)`
  font-family: SangBleuVersaillesRegular;
  font-size: 26px;
  line-height: 34px;
  word-break: break-word;
  color: ${colors.warmGraphite};
  margin-bottom: 16px;
`;

const Caption = styled(MetadataContent)`
  font-family: SangBleuVersaillesRegularItalic;
  font-size: 14px;
  line-height: 23px;
  color: ${colors.warmGraphite};
  letter-spacing: 0.03em;
  margin-bottom: 16px;
`;

const ShortDescription = styled(MetadataContent)`
  font-family: SangBleuVersaillesRegular;
  color: ${colors.warmGraphite};
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
`;

const AddToMyCollectionIcon = styled(Icon)`
  cursor: pointer;
`;

export default MediaViewerDetailsPane;
