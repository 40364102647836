import React from 'react';
import FilterMenuItem from "../../../shared/FilterMenuItem";
import {getBirthAndDeathYears, getEpochStartAndEndYears, getPronoun} from "../../../../services/filters-utils";
import Menu from "../../../shared/Menu";
import {ENTITIES, EPOCHS} from "../../../../config/constants";
import {useResource} from "../../../../hooks/useResource";

const PersonLifeDuringFilter = ({person, onAdd, onClear, isActive}) => {
  const [ageRange, isLoading] = useResource({path:`/people/life_during_range?person_id=${person.id}`});

  // without the age range, this filter can't be calculated
  if(isLoading || !ageRange || ageRange.from_age == null ) return null;

  // get person birth and death
  const {birthYear, deathYear} = getBirthAndDeathYears(person);

  // filter the epochs constant to math the person life span
  const epochsWithinRange = EPOCHS.filter(epoch => {
    const {epochStart, epochEnd} = getEpochStartAndEndYears(epoch.value);

    // the birth year + the minimum age of the image found on his archive
    return (epochEnd >= (birthYear + ageRange.from_age)) && !(deathYear < epochStart);
  });

  const renderLifeDuring = (epoch) => {
    const lifDuringFilter = {
      ...epoch,
      entity: ENTITIES.EPOCHS
    };

    return (
        <FilterMenuItem
            key={lifDuringFilter.id}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={lifDuringFilter}
        />
    );
  };

  return (
      <Menu label={`${getPronoun(person.gender)} life during`} itemLength={EPOCHS.length}>
        {epochsWithinRange.map(epoch => renderLifeDuring(epoch))}
      </Menu>
  );
};

export default PersonLifeDuringFilter;
