/* eslint-disable no-unused-vars */
import React, {useState} from "react";

import {
  BaseButton,
  ErrorMessage,
  FormActionsContainer,
  FormGroup,
  FormWrapper,
  SemiTransparentInput,
  Space
} from "../../shared/FormElements";
import {Col12, Col4, Col8} from "../../shared/Grid";
import AuthHeader from "./shared/AuthHeader";
import styled from "@emotion/styled";
import FieldSeparator from "./shared/FieldSeparator";
import {withRouter} from "react-router-dom";
import {PageContainer, Row} from "components/shared/Layouts";
import WelcomeMessage from "./shared/styled";
import {Description, TextLink} from "../../shared/style-guide/typography";
import {colors} from "../../../config/StyleGuide";
import apiRequest from "../../../services/api";
import loginVideo from "./shared/t3t_login_vid.mp4";
import {VideoBackground} from "./shared/VideoBackground";

const NewPasswordForm = ({ history, match }) => {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [error, setError] = useState(null);

  const recoverPassword = async () => {

    if (!password || !confirmPassword) {
      setError("Please fill both fields.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Password do not match.");
      return;
    }

    const params =new URLSearchParams(window.location.search);

    const response = await apiRequest({
      path:"/auth/password",
      method:"PUT",
      data: { password, password_confirmation:confirmPassword },
      headers: {
        'access-token': params.get('access-token'),
        'client': params.get('client'),
        'client_id': params.get('client_id'),
        'token': params.get('token')
      }
    });

    // in case of error
    if (response.status !== 200) {
      return setError(response.response.data.error[0]);
    }

    history.push("/login");
  };

  return (
    <>
      <VideoBackground src={loginVideo} type="video/mp4" autoPlay muted loop />
      <PageContainer>
        <AuthHeader />
        <div>
          <Row>
            <div className="col-3">
              <WelcomeMessage />
              <Space value="40px" />
              <FormWrapper>
                <Col12>
                  <FormGroup>
                    <FieldSeparator label="Password reset" />
                    <ResetInstructions>
                      Please choose new password
                    </ResetInstructions>
                    <SemiTransparentInput
                      placeholder="New password"
                      type="password"
                      onChange={({ target }) => setPassword(target.value)}
                      invalid={error}
                    />
                  </FormGroup>

                  <FormGroup>
                    <ResetInstructions>
                      Confirm your new password
                    </ResetInstructions>
                    <SemiTransparentInput
                      placeholder="Confirm your password"
                      type="password"
                      onChange={({ target }) => setConfirmPassword(target.value)}
                      invalid={error}
                    />
                  </FormGroup>
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Col12>
                <Col12>
                  <FormGroup>
                    <FormActionsContainer>
                      <Col8>
                        <BaseButton type={"button"} onClick={recoverPassword}>
                          SUBMIT
                        </BaseButton>
                      </Col8>
                      <CancelCol />
                    </FormActionsContainer>
                    <TextLink to="/login">Cancel</TextLink>
                  </FormGroup>
                </Col12>
              </FormWrapper>
            </div>
          </Row>
        </div>
      </PageContainer>
    </>
  );
};

const ResetInstructions = styled(Description)`
  color: ${colors.white};
  padding: 5px 0 15px 0;
`;

const CancelCol = styled(Col4)`
  padding-top: 10px;
`;

export default withRouter(NewPasswordForm);
