import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";

const ViewfinderHeader = () => (
  <Wrapper>
    <TextWrapper>
      <TopText>Viewfinder</TopText>
      <BottomText>
        No.<NumberText>07</NumberText>
      </BottomText>
    </TextWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  border-color: ${colors.warmGraphite};
  border-style: solid;
  border-left-width: 3px;
  border-right-width: 3px;
  color: ${colors.warmGraphite};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  font-family: SangBleuVersaillesRegular;
`;

const TopText = styled(Text)`
  font-size: 84px;
`;

const BottomText = styled(Text)`
  font-size: 79px;
  align-self: flex-end;
`;

const NumberText = styled(Text)`
  font-size: 90px;
  display: inline;
  margin-right: -120px;
`;

export default ViewfinderHeader;
