import React from "react";
import Rule from "components/shared/Rule";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {PageRowWithVerticalPadding} from "components/shared/Layouts";
import {css} from "@emotion/core";
import {BodyCopy, H3} from "components/shared/style-guide/typography";

const CollectionHeader = ({
  collection,
  isFullWidth,
  backgroundColor,
  textColor
}) => {
  return (
    <Wrapper backgroundColor={backgroundColor} textColor={textColor}>
      {isFullWidth && <FullBackground />}
      <PageRowWithVerticalPadding>
        <div className="col-3">
          <TopSpace />
          <TitleSpaceWrapper>
            <RuleWrapper>
              <Rule height={1} color={colors.white} />
            </RuleWrapper>
          </TitleSpaceWrapper>
        </div>

        <div className="col-8">
          <TopSpace>
            <H3>collection</H3>
          </TopSpace>
          <TitleSpaceWrapper>
            <CollectionTitle>{collection.display_name}</CollectionTitle>
          </TitleSpaceWrapper>
          <CollectionDescription>
            <BodyCopy>{collection.short_description}</BodyCopy>
          </CollectionDescription>
        </div>
      </PageRowWithVerticalPadding>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: ${({ textColor }) => textColor};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background: url("${backgroundImage}") no-repeat center center;
      background-size: cover;
}
    `};
  position: relative;
  color: ${colors.white};
  height: 400px;
`;

const TopSpace = styled.div`
  height: 50px;
`;

const TitleSpaceWrapper = styled.div`
  height: 40px;
  margin-bottom: 30px;
`;

const RuleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 50px;
  height: 100%;
`;

const CollectionTitle = styled.div`
  font-size: 40px;
  letter-spacing: 0.02em;
  font-family: SangBleuVersaillesRegular;
`;

const CollectionDescription = styled.div`
  font-size: 14px;
  width: 60%;
  column-gap: 40px;
  line-height: 1.8;
`;

const FullBackground = styled.div`
  background-color: inherit;
  left: -1000px;
  right: -1000px;
  top: 0;
  height: 100%;
  position: absolute;
`;

export default CollectionHeader;
