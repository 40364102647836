import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {PageBackground, PageContainer, PageSideBar, Row} from "components/shared/Layouts";
import {Space} from "components/shared/FormElements";
import Rule from "components/shared/Rule";
import {useBreadCrumbs} from "../../../hooks/useBreadCrumbs";
import PhotoPersonYear from "./PhotoPersonYear";
import FathersDay from "./FathersDay";

import doc1 from "./images/doc_01.png";
import doc2 from "./images/doc_02.png";
import doc3 from "./images/doc_03.png";
import doc4 from "./images/doc_04.png";
import doc5 from "./images/doc_05.png";
import person1 from "./images/Inky_LeonLowenstein.png";
import person2 from "./images/Inky_DudleyBachrach.png";
import person3 from "./images/Inky_YettiGoldhagen.png";
import person4 from "./images/Inky_EleanorGold.png";
import person5 from "./images/Inky_NormaGoldhagen.png";
import BookAndYear from "./BookAndYear";
import book1 from "./images/fd_doc_01.png";
import book2 from "./images/fd_doc_02.png";
import book3 from "./images/fd_doc_03.png";
import headerLogo from "./images/pom_headline.png";

const RuleRow = ({ children }) => (
  <div className="row pt-5">
    <div className="col-10 offset-1">{children}</div>
  </div>
);

const PiecesOfMind = ({ location }) => {
  useBreadCrumbs({ label: "Pieces of mind", path: location.pathname });

  return (
    <PageBackground>
      <PageContainer>
        <div className="row py-5">
          <div className="col-9 offset-2">
            <HeaderImage src={headerLogo} />
          </div>
        </div>
        <RuleRow>
          <Rule height={3} color={colors.warmGraphite} />
        </RuleRow>
        <PrefaceRow className="row no-gutters">
          <LeftSide className="col-4 offset-1">
            <LeftSideContent>
              Collected here are pieces of what has been on people’s minds. They
              were gifted to others to accept, reject, or nod in understanding.
              The creators of the following fragments hoped that these bits of
              emotional and intellectual DNA would be useful to someone in the
              future.
            </LeftSideContent>
          </LeftSide>
          <RightSide className="col-6">
            <p>
              Every person is the architect of his or her own life. Surrounding
              circumstances, war, famine, divorce can affect one’s structure.
              Love, a summer breeze by the ocean can be balm. Much is determined
              by the physical DNA that courses through years of bloodlines.
              There are experiential lessons, emotional and intellectual DNA,
              that forebearers discover and sometimes pass on through oral
              histories or traits.
            </p>
            <p style={{ marginTop: "20px" }}>
              This section highlights what someone at a juncture in his or her
              life hoped that a future somebody might take to heart.
            </p>
          </RightSide>
        </PrefaceRow>
        <RuleRow>
          <Rule height={1} color={colors.warmGraphite} />
        </RuleRow>
        <Space value="90px" />
        <Row>
          <StyledItemColumn className="col-2 offset-2">
            <PhotoPersonYear
              imageId={2838}
              width={280}
              mainPhoto={doc1}
              personPhoto={person1}
              year="1918"
              alignPerson="right"
              hoverTitle="leon lowenstein"
              hoverTextLines={[
                "I'm Going to War.",
                "Take Care of the Business and Dad"
              ]}
            />
          </StyledItemColumn>
          <StyledItemColumn className="col-2 offset-3">
            <PhotoPersonYear
              imageId={2836}
              width={280}
              mainPhoto={doc2}
              personPhoto={person2}
              year="1921"
              alignPerson="left"
              hoverTitle="dudley bachrach"
              hoverTextLines={['"To Achieve Contentment."']}
            />
          </StyledItemColumn>
        </Row>
        <Row>
          <StyledItemColumn className="col-2 offset-2">
            <PhotoPersonYear
              imageId={2084}
              width={370}
              mainPhoto={doc3}
              personPhoto={person3}
              year="193X"
              alignPerson="right"
              hoverTitle="jonah, his wife and child"
              hoverTextLines={["All Three Killed.", "No Words."]}
            />
          </StyledItemColumn>
          <StyledItemColumn className="col-2 offset-3">
            <PhotoPersonYear
              imageId={2837}
              width={320}
              mainPhoto={doc4}
              personPhoto={person4}
              year="19XX"
              alignPerson="left"
              hoverTitle="eleanor gold"
              hoverTextLines={['"Life is a Series of Decisions."']}
            />
          </StyledItemColumn>
        </Row>
        <Row>
          <StyledItemColumn className="col-2 offset-2">
            <PhotoPersonYear
              imageId={2835}
              width={280}
              mainPhoto={doc5}
              personPhoto={person5}
              year="1985"
              alignPerson="left"
              hoverTitle="norma goldhagen"
              hoverTextLines={["Mother to Flock:", "Please Understand..."]}
            />
          </StyledItemColumn>
          <FathersDayColumn className="col-3 offset-2">
            <FathersDay />
          </FathersDayColumn>
        </Row>
        <Space value="50px" />
        <Row>
          <StyledBookColumn className="col-2 offset-6">
            <BookAndYear
              imageId={2842}
              width={170}
              year="2015"
              bookPhoto={book1}
              alignText="right"
              hoverTextLines={["Discover:", "", "The Self", "&", "Others"]}
            />
          </StyledBookColumn>
          <StyledBookColumn className="col-2">
            <BookAndYear
              imageId={2841}
              width={150}
              year="2016"
              bookPhoto={book2}
              alignText="left"
              hoverTextLines={[
                "Study Negotiation.",
                "Engineer Your Agreements."
              ]}
            />
          </StyledBookColumn>
        </Row>
        <Row>
          <StyledBookColumn className="col-2 offset-6">
            <BookAndYear
              imageId={2865}
              width={160}
              year="2017"
              bookPhoto={book3}
              alignText="right"
              hoverTextLines={[
                '"It takes a team of good people to get to your destination in life"',
                " - General McCraven"
              ]}
            />
          </StyledBookColumn>
          <StyledBookColumn className="col-2">
            <BookAndYear
              imageId={2841}
              width={150}
              year="2018"
              bookPhoto={book2}
              alignText="left"
            />
          </StyledBookColumn>
        </Row>
        <Row>
          <StyledBookColumn className="col-2 offset-6">
            <BookAndYear
              width={160}
              year="2019"
              bookPhoto={book3}
              alignText="right"
            />
            <CustomRule height={1} width={"175%"} color={colors.warmGraphite} />
          </StyledBookColumn>
        </Row>

        <PageSideBar />
      </PageContainer>
    </PageBackground>
  );
};

const CustomRule = styled(Rule)`
  margin: 75px 0;
`;

const LeftSide = styled.div`
  font-size: 18px;
  line-height: 36px;
`;

const RightSide = styled.div`
  line-height: 26px;
  font-size: 16px;
  margin-top: 5px;
`;

const LeftSideContent = styled.div`
  margin-right: 20px;
`;

const PrefaceRow = styled.div`
  font-family: SangBleuVersaillesRegular;
  color: ${colors.warmGraphite};
  padding: 45px 0;
`;

const StyledItemColumn = styled.div`
  &:nth-child(2) {
    margin-top: 150px;
    margin-bottom: 100px;
  }
`;

const FathersDayColumn = styled.div`
  &:nth-child(2) {
    margin-top: 250px;
  }
`;
const StyledBookColumn = styled.div`
  &:nth-child(2) {
    margin-left: 50px;
    margin-top: 150px;
    margin-bottom: 30px;
  }
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
`;

export default PiecesOfMind;
