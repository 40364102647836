import React from "react";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import backgroundImage from "../images/bg-text.png";

const FILES_IDS = [2937, 2936];

// todo nir: replace background color with background image
const BoysStrip = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1] = Files;

  return (
    <StripContainer backgroundColor="#171D3B">
      <BackgroundImageWrapper />
      <MediaViewer />
      <RowOverlay>
        <div className="col-1 offset-10" style={{ marginTop: "70px" }} >
          <StripLegend title="Boys" color={colors.white} anchor="boys">
            <Section>
              <div>left:</div>
              <div>Danny Goldhagen</div>
              <YearLabel>1962</YearLabel>
            </Section>
            <Section>
              <div>right:</div>
              <div>Dudley Bachrach</div>
              <YearLabel>1897</YearLabel>
            </Section>
          </StripLegend>
        <span id={"boys"}/>
        </div>
      </RowOverlay>
      <RowOverlay pointerEvents={"none"}>
        <div className="col-6 offset-3" style={{ marginTop: "200px" }}>
          <ImagesWrapper>
            <div
              style={{ marginRight: "70px", width: "280px", height: "420px" }}
            >
              <File0 width="auto" height="100%" />
            </div>
            <div style={{ width: "300px", height: "420px" }}>
              <File1 width="auto" height="100%" />
            </div>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const BackgroundImageWrapper = styled.div`
  background: url(${backgroundImage}) no-repeat center bottom;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default BoysStrip;
