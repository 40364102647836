import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import PropTypes from "prop-types";

const NewSlider = props => {
  const {
    data,
    renderItem,
    keyExtractor,
    slideIndex,
    slidesToShow,
    animationDuration,
    centerMode,
    onChange,
    hasMore,
    loadMore,
    numSlidesPrefetch, //todo: find a better name
    numSlidesOutsideWindow
  } = props;

  const numSlides = data.length;
  const lastSlideIndex = numSlides - 1;
  const trackTranslateXIncrements = (100 / slidesToShow) * -1;
  const numPlaceholdersLeftForCenterMode = centerMode
    ? Math.floor(slidesToShow / 2)
    : 0;

  const [slideTranslateX, setSlideTranslateX] = useState(0);
  const previousSlideIndex = useRef(slideIndex);
  const currentSlideIndex = Math.max(0, Math.min(slideIndex, lastSlideIndex));

  const initialTrackTranslateX =
    Math.abs(numPlaceholdersLeftForCenterMode * trackTranslateXIncrements) +
    currentSlideIndex * trackTranslateXIncrements;

  const [trackTranslateX, setTrackTranslateX] = useState(
    initialTrackTranslateX
  );
  const moveDelta = Math.abs(currentSlideIndex - previousSlideIndex.current);

  const renderEmptyPlaceholders = numPlacesHolders =>
    Array(numPlacesHolders)
      .fill(0)
      .map(
        (_, placeHolderIndex) => (
          <Slide
            key={`placeholder-${placeHolderIndex}`}
            slidesToShow={slidesToShow}
            translateX={slideTranslateX}
          />
        ),
        numPlacesHolders
      );

  const getFirstSlideIndexToRender = () =>
    Math.max(
      0,
      centerMode
        ? currentSlideIndex -
            numPlaceholdersLeftForCenterMode -
            numSlidesOutsideWindow
        : currentSlideIndex - numSlidesOutsideWindow
    );

  const getLastSlideIndexToRender = () =>
    Math.min(
      lastSlideIndex + 1,
      centerMode
        ? currentSlideIndex +
            numSlidesOutsideWindow +
            numPlaceholdersLeftForCenterMode +
            1
        : currentSlideIndex + slidesToShow + numSlidesOutsideWindow + 1
    );

  const [firstIndex, setFirstIndex] = useState(getFirstSlideIndexToRender());
  const [lastIndex, setLastIndex] = useState(getLastSlideIndexToRender());

  const shouldLoadMore = () =>
    currentSlideIndex + (slidesToShow - 1) + numSlidesPrefetch >=
      lastSlideIndex && hasMore;

  useEffect(() => {
    const didMove = previousSlideIndex.current !== currentSlideIndex;
    if (!didMove) {
      return;
    }

    const isMovingRight = currentSlideIndex > previousSlideIndex.current;
    previousSlideIndex.current = currentSlideIndex;

    const trackTranslateXAddition = isMovingRight
      ? trackTranslateXIncrements * moveDelta
      : -trackTranslateXIncrements * moveDelta;
    setTrackTranslateX(current => current + trackTranslateXAddition);

    setTimeout(() => {
      setFirstIndex(getFirstSlideIndexToRender());
      setLastIndex(getLastSlideIndexToRender());
    }, animationDuration);

    if (shouldLoadMore()) {
      loadMore();
    }

    onChange(currentSlideIndex);
  }, [slideIndex]);

  useEffect(() => {
    setSlideTranslateX(firstIndex * 100);
  }, [firstIndex]);

  // todo: memoize rendering
  return (
    <Slider>
      <SliderTrack
        translateX={trackTranslateX}
        centerMode={centerMode}
        animationDuration={animationDuration}
      >
        {data.map((currentSlideData, currentSlideIndex) => {
          if (
            currentSlideIndex < firstIndex ||
            currentSlideIndex >= lastIndex
          ) {
            //todo: make sure this is ok, render-tree wise
            return null;
          }
          const itemData = { item: currentSlideData, index: currentSlideIndex };
          return (
            <Slide
              key={keyExtractor(itemData)}
              slidesToShow={slidesToShow}
              translateX={slideTranslateX}
            >
              {renderItem(itemData)}
            </Slide>
          );
        })}
        {slidesToShow > numSlides &&
          renderEmptyPlaceholders(slidesToShow - numSlides)}
      </SliderTrack>
    </Slider>
  );
};

const noop = () => {};

const Slider = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
`;

const SliderTrack = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  transition: ${({ animationDuration }) =>
    `transform ${animationDuration}ms ease`};
  transform: translate3d(${({ translateX }) => `${translateX}%`}, 0, 0);
`;

const Slide = styled.div`
  ${({ slidesToShow }) => css`
    flex: 1 0 ${100 / slidesToShow}%;
    max-width: ${100 / slidesToShow}%;
  `};
  height: 100%;
  width: 100%;
  transform: translate3d(${({ translateX }) => `${translateX}%`}, 0, 0);
`;

NewSlider.defaultProps = {
  slideIndex: 0,
  slidesToShow: 1,
  animationDuration: 500,
  centerMode: false,
  onChange: noop,
  loadMore: noop,
  hasMore: false,
  numSlidesPrefetch: 0,
  numSlidesOutsideWindow: 1
};

NewSlider.propTypes = {
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  keyExtractor: PropTypes.func.isRequired,
  slideIndex: PropTypes.number,
  slidesToShow: PropTypes.number,
  animationDuration: PropTypes.number,
  centerMode: PropTypes.bool,
  onChange: PropTypes.func,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  numSlidesPrefetch: PropTypes.number,
  numSlidesOutsideWindow: PropTypes.number
};

export default NewSlider;
