import styled from "@emotion/styled";

/**
 * Shared properties between all buttons
 */
const Button = styled.button`
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

export default Button;
