import React, {useContext, useState} from "react";
import apiRequest from "services/api";
import styled from "@emotion/styled";
import {AccountLabel, AccountSubmitButton} from "./shared/AccountFormElements";
import Modal from "react-modal";
import {ConfirmAccountDeletion, DeactivateAccountDialog, DeleteAccountDialog} from "./AccountDialogs";
import ModalOverlayStyles from "../../../shared/ModalOverlayStyles";
import {withRouter} from 'react-router-dom';
import {AuthContext} from "../../../context-providers/Auth";

const modalStyle = {
  content: {
    width: "455px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: ModalOverlayStyles
};

const AccountOperations = ({history}) => {
  const {currentUser} = useContext(AuthContext);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState(
      false
  );
  const [isDeleteAccountModalVisible, setIsDeletAccountModalVisible] = useState(
      false
  );

  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(
      false
  );



  const deactivateAccount = async () => {
    await apiRequest({
      path: `/members/${currentUser.id}/deactivate`,
      method: "POST"
    });

    window.location.reload();
  };

  const confirmAccountDeletion = () => {
    setIsDeleteConfirmationModalVisible(true);
  };

  const deleteAccount = async () => {
    await apiRequest({
      path: "/auth",
      method: "DELETE"
    });

    localStorage.setItem('T3T_ACCOUNT_DELETED', 'TRUE');
    window.location.reload();
  };

  const displayDeleteAccountModal = () => {
    setIsDeletAccountModalVisible(true);
  };

  const onCloseModal = () => {
    setIsDeactivateModalVisible(false);
    setIsDeletAccountModalVisible(false);
  };
  return (
      <Wrapper>
        <div>
          <StyledAccountLabel>
            Temporarily hide profile and collection.
          </StyledAccountLabel>
          <AccountSubmitButton onClick={() => setIsDeactivateModalVisible(true)}>
            deactivate account
          </AccountSubmitButton>
          <Modal
              isOpen={isDeactivateModalVisible}
              onRequestClose={onCloseModal}
              style={modalStyle}
          >
            <DeactivateAccountDialog
                onConfirm={() => {
                  setIsDeactivateModalVisible(false);
                  deactivateAccount();
                }}
                onCancel={() => setIsDeactivateModalVisible(false)}
            />
          </Modal>
        </div>
        <div>
          <StyledAccountLabel>
            Delete your account and account data.
          </StyledAccountLabel>
          <AccountSubmitButton onClick={displayDeleteAccountModal}>
            delete account
          </AccountSubmitButton>
          <Modal
              isOpen={isDeleteAccountModalVisible}
              onRequestClose={onCloseModal}
              style={modalStyle}
          >
            <DeleteAccountDialog
                onConfirm={() => {
                  setIsDeletAccountModalVisible(false);
                  confirmAccountDeletion();
                }}
                onCancel={() => {
                  setIsDeletAccountModalVisible(false);
                }}
            />
          </Modal>
          <Modal
              isOpen={isDeleteConfirmationModalVisible}
              onRequestClose={onCloseModal}
              style={modalStyle}
          >
            <ConfirmAccountDeletion onCancel={() => setIsDeleteConfirmationModalVisible(false)} onConfirm={ () =>
            {
              setIsDeleteConfirmationModalVisible(false)
              deleteAccount();
            }
            } />
          </Modal>
        </div>
      </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const StyledAccountLabel = styled(AccountLabel)`
  margin-bottom: 20px;
`;

export default withRouter(AccountOperations);
