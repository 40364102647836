import React from "react";
import styled from "@emotion/styled";
import FieldSeparator from "../../pages/auth/shared/FieldSeparator";
import {colors} from "../../../config/StyleGuide";
import Icon from "components/shared/Icon";

const AppliedFilters = ({ filters, onClose }) => {
  return (
    <FilterContainer>
      <FieldSeparator padding={16} label="Applied filters" color={colors.warmGraphite} />
      {filters.map(filter => (
        <FilterBox key={filter.id}>
          <Label>{filter.name}</Label>
          <CloseIconContainer onClick={() => onClose(filter)}>
            <CloseIcon type="AppliedFilterClose" size={8} />
          </CloseIconContainer>
        </FilterBox>
      ))}
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  margin-bottom: 16px;
`;

const CloseIconContainer = styled.span`
  display: inline-block;
`;

const FilterBox = styled.div`
  font-family: SlateProRegular;
  display: inline-block;  
  justify-content: center;
  box-sizing: border-box;
  align-content: center;
  min-height: 24px;
  padding: 5px 10px;
  color: ${colors.gray};
  font-size: 12px;
  letter-spacing: 0.02em;
  border-radius: 5px;
  margin: 0 5px 6px 0;
  background-color: ${colors.white} ;
`;

const Label = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  display: inline-block;
  margin-top: -2px;  
`;

export default AppliedFilters;
