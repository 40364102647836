import React from 'react';
import Menu from "../../../shared/Menu";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";
import {useResource} from "../../../../hooks/useResource";
import {getPronoun} from "../../../../services/filters-utils";
import {ENTITIES} from "../../../../config/constants";


const PersonTopicsFilter = ({person, onAdd, onClear, isActive}) => {
    const [topics, isLoading] = useResource({ path: `people/topics?person_id=${person.id}` });

    if( isLoading || !topics || !topics.length ) return null;

    const renderTopic = (topic) => {
        const filter = {
            name: topic.topic.display_name,
            id: topic.topic.id,
            entity: ENTITIES.TOPICS,
            children: topic.children.map(child => ({
                id: child.id,
                name: child.display_name,
                entity: ENTITIES.TOPICS,
            }))
        };

        return (
            <FilterMenuItemTree
                key={filter.id}
                onAdd={onAdd}
                onClear={onClear}
                isActive={isActive}
                filter={filter}
                entity={ENTITIES.TOPICS}
            />
        );
    };


    return (
        <Menu label={`${getPronoun(person.gender)} topics`} itemLength={topics.length}>
            {topics.map(topic =>
                renderTopic(topic, onAdd, onClear, isActive, "topics")
            )}
        </Menu>
    );
};

export default PersonTopicsFilter;
