import React from 'react';
import {useResource} from "../../../../hooks/useResource";
import Menu from "../../../shared/Menu";
import {ENTITIES, EPOCHS} from "../../../../config/constants";
import FilterMenuItem from "../../../shared/FilterMenuItem";
import {convertEpochStringIntoRange, formatValidDates, withinRange} from "../../../../services/filters-utils";

const GenreLifeDuringFilter = ({genreId, onAdd, onClear, isActive, firstLoad}) => {
  const [dates] = useResource({path: `/genres/${genreId}/dates`});

  if(!dates) return null;

  const epochsWithinRange = EPOCHS.filter(epoch => {
    const epochRange  = convertEpochStringIntoRange(epoch.value);
    const formattedDates = dates.map( stringDate => parseInt(stringDate) );

    return withinRange(formattedDates, epochRange);
  });

  const renderWithingEpoch = (epoch) => {
    const epochFilter = {
      ...epoch,
      name: epoch.name,
      entity: ENTITIES.EPOCHS
    };

    return (
        <FilterMenuItem key={epoch.id}
                        onAdd={onAdd}
                        onClear={onClear}
                        isActive={isActive}
                        filter={epochFilter}/>
    )
  };

  return (
      <Menu toggled={!firstLoad} label={'During'}>
        {epochsWithinRange.map(epoch => renderWithingEpoch(epoch))}
      </Menu>
  );
};

export default React.memo(GenreLifeDuringFilter);
