import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import person5 from "./images/Inky_BenjaminGoldhagen.png";

const FathersDay = () => {
  return (
    <Wrapper>
      <Image src={person5} />
      <Name>benjamin goldhagen</Name>
      <Title>Father's Day & the Kids Have to Listen</Title>
      <Description>
        Thomas and Elizabeth give Dad the same present every year. They sit near
        to him and listen to what he brings to the table.
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  color: ${colors.warmGraphite};
`;

const Name = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.2em;
  font-family: SlateProRegular;
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 3px solid ${colors.warmGraphite};
`;

const Title = styled.div`
  font-size: 28px;
  line-height: 36px;
  font-family: SangBleuVersaillesRegular;
  margin-bottom: 20px;
  max-width: 230px;
`;

const Description = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 16px;
  line-height: 28px;
`;
const Image = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;

export default FathersDay;
