import React, {useContext} from "react";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import AccountSection from "./AccountSection";
import AccountEmail from "./AccountEmail";
import AccountPassword from "./AccountPassword";
import AccountOperations from "./AccountOperations";
import {AuthContext} from "components/context-providers/Auth";
import AccountMenu from "./shared/AccountMenu";

import {PageContainer, PageContent8Columns, PageRowWithPadding, PageSideBar} from "components/shared/Layouts";

const Account = ({ location }) => {
  useBreadCrumbs({ label: "Account", path: location.pathname });

  const { currentUser } = useContext(AuthContext);

  return (
    <PageContainer>
      <PageRowWithPadding>
        <PageSideBar>
          <AccountMenu selectedItemName="account" />
        </PageSideBar>
        <PageContent8Columns>
          <ContentWrapper>
            <Header>My Account</Header>
            <AccountSection label="email address">
              <AccountEmail user={currentUser} />
            </AccountSection>
            <AccountSection label="password">
              <AccountPassword />
            </AccountSection>
            <AccountSection label="account changes">
              <AccountOperations />
            </AccountSection>
          </ContentWrapper>
        </PageContent8Columns>
      </PageRowWithPadding>
    </PageContainer>
  );
};

const Header = styled.h1`
  font-size: 32px;
  font-family: SangBleuVersaillesBook, sans-serif;
  margin-bottom: 30px;
`;

const ContentWrapper = styled.div`
  background-color: ${colors.white};
  height: 100%;
  padding: 45px 35px;
`;
export default Account;
