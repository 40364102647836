import React from "react";
import SideMenu from "../../shared/SideMenu";
import ClanLifeDuringFilter from "./filters/ClanLifeDuringFilter";
import ClanStagesFilter from "./filters/ClanStagesFilter";
import ClanEventsFilter from "./filters/ClanEventsFilter";
import ClanTopicsFilter from "./filters/ClanTopicsFilter";
import ClanPlacesFilter from "./filters/ClanPlacesFilter";

const ClanFilters = (props) => {
  return (
      <SideMenu>
        <ClanLifeDuringFilter {...props}/>
        <ClanStagesFilter {...props} />
        <ClanEventsFilter {...props} />
        <ClanTopicsFilter {...props} />
        <ClanPlacesFilter {...props} />
      </SideMenu>
  )
};

export default ClanFilters;
