import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";

import {AccountCancelButton, AccountModalCancelButton, AccountModalSubmitButton} from "./shared/AccountFormElements";

const Title = ({text}) => <StyledTitle>{text}</StyledTitle>;

const ConfirmamblePopup = ({
                             onCancel,
                             onConfirm,
                             confirmText,
                             cancelText = "Cancel",
                             children,
                             isSubmitDefaultButton
                           }) => {
  const CancelButton = isSubmitDefaultButton
      ? AccountModalCancelButton
      : AccountModalSubmitButton;

  const SubmitButton = isSubmitDefaultButton
      ? AccountModalSubmitButton
      : AccountModalCancelButton;

  return (
      <PopupWrapper>
        {children}
        <ButtonsWrapper>
          <CancelButton onClick={onCancel}>{cancelText}</CancelButton>
          <SubmitButton onClick={onConfirm}>{confirmText}</SubmitButton>
        </ButtonsWrapper>
      </PopupWrapper>
  );
};

ConfirmamblePopup.defaultProps = {
  confirmText: "continue",
  isSubmitDefaultButton: true
};

export const ConfirmAccountDeletion = ({onCancel, onConfirm}) => {
  return (
      <ConfirmamblePopup
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmText="yes. please delete my account"
          cancelText="Nevermind"
          isSubmitDefaultButton={false}
      >
        <Title text={'This is your last chance.'}/>
        <Subtitle>Are you sure you want to permanently delete your account? </Subtitle>
      </ConfirmamblePopup>)
};

export const DeleteAccountDialog = ({onCancel, onConfirm}) => {
  return (
      <ConfirmamblePopup
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmText="I understand. continue"
          cancelText="Nevermind"
          isSubmitDefaultButton={false}
      >
        <Title text={'Are you sure?'}/>
        <Subtitle>Deleting your account means:</Subtitle>
        <List>
          <ListItem>
            Permanently deleting your profile information and saved collection
            items.
          </ListItem>
          <ListItem>Your data wil not be recoverable.</ListItem>
        </List>
      </ConfirmamblePopup>
  );
};

export const DeactivateAccountDialog = ({onCancel, onConfirm}) => {
  return (
      <ConfirmamblePopup onCancel={onCancel} onConfirm={onConfirm}>
        <Title/>
        <Subtitle>Decativating your account means:</Subtitle>
        <List>
          <ListItem>No one can see your profile or your Collection.</ListItem>
          <ListItem>
            Both your profile information and your Collection will be saved.
          </ListItem>
          <ListItem>You can reactivate at any time.</ListItem>
        </List>
      </ConfirmamblePopup>
  );
};

const StyledTitle = styled.div`
  font-family: SangBleuVersaillesRegular;
  font-size: 24px;
  margin-bottom: 20px;
  color: ${colors.warmGraphite};
  letter-spacing: 0.02em;
`;

const Subtitle = styled.div`
  font-family: SlateProBold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.warmGraphite};
  margin-bottom: 40px;
`;

const List = styled.ol`
  margin: 0 0 40px 18px; 
       
`;

const ListItem = styled.li`
  font-family: SangBleuVersaillesRegular,sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.warmGraphite};
`;

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: flex-start;
  color: ${colors.warmGraphite};  
`;

const ButtonsWrapper = styled.div`
  display: flex;  
  flex-direction: column;
  align-items: center;  
  width: 100%;  
`;
