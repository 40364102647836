import React from "react";
import {Link} from "react-router-dom";
import styled from "@emotion/styled";
import {chunk} from "lodash";
import Icon from "components/shared/Icon";
import {SectionTitle} from "components/shared/Layouts";
import {colors} from "config/StyleGuide";
import {useResource} from "../../../hooks/useResource";

const Explore = () => {
  const getColumn = idx => ["col-3 offset-2", "col-3", "col-2"][idx];
  const [genres] = useResource({path: "/genres"});

  if(!genres) return null;

  return (
      <ListContainer>
        <div className="row pb-4">
          <div className="col-8 offset-2 mt-5">
            <SectionTitle>explore</SectionTitle>
          </div>
        </div>
        {chunk(genres, 3).map((col, i) => (
            <div className="row" key={i}>
              {col.map((genre, colIdx) => {
                return (
                    <HoverContainer className={getColumn(colIdx)} key={genre}>
                      <ItemLink to={`genre/${genre.id}`}>
                        <Icon type={genre.display_name} fill={colors.warmGraphite}/>
                        <Label>{genre.display_name}</Label>
                      </ItemLink>
                    </HoverContainer>
                );
              })}
            </div>
        ))}
      </ListContainer>
  );
};

const HoverContainer = styled.div`
  &:hover * {
    color: ${colors.gray};
    fill: ${colors.gray} !important;
  }
`;

const ListContainer = styled.div`
  padding-bottom: 50px;
`;

const ItemLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 0;
  text-decoration: none;
`;

const Label = styled.span`
  font-family: SlateProMedium, sans-serif;
  font-size: 15px;
  letter-spacing: 0.2em;
  display: inline-block;
  text-transform: uppercase;
  padding-left: 20px;
  color: ${colors.warmGraphite};
`;

export default Explore;
