import React from "react";
import SideMenu from "../../shared/SideMenu";
import GenreRelatedPeopleFilter from "./filters/GenreRelatedPeopleFilter";
import GenreLifeDuringFilter from "./filters/GenreLifeDuringFilter";

const GenreCollectionFilters = (props) => (
    <SideMenu>
      <GenreRelatedPeopleFilter {...props} />
      <GenreLifeDuringFilter {...props} />
    </SideMenu>
);

export default GenreCollectionFilters;
