import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import Loading from "./Loading";
import SideMenu from "./SideMenu";

const LoadingMenu = () => {
  return (
    <SideMenu>
      <MenuHeader>
        <MenuLabel>Loading...</MenuLabel>
        <SpinnerContainer>
          <Loading size={15} />
        </SpinnerContainer>
      </MenuHeader>
    </SideMenu>
  );
};

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-family: SlateProBold, sans-serif;
  font-size: 12px;
  color: #454545;
  letter-spacing: 0.2em;
  cursor: pointer;
  user-select: none;
`;

const MenuLabel = styled.span`
  font-family: SlateProRegular, sans-serif;
  font-size: 14px;
  color: ${colors.warmGraphite};
  text-transform: uppercase;
  letter-spacing: 0.15em;
  display: inline-block;
  cursor: pointer;
`;

const SpinnerContainer = styled.span`
  display: inline-block;
`;

export default LoadingMenu;
