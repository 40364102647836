import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";

const ResetLink = ({ onReset }) => (
  <ResetContainer>
    <ResetFilters onClick={onReset}>
      Reset All Filters
      <ResetFiltersLine />
    </ResetFilters>
  </ResetContainer>
);
const ResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ResetFilters = styled.div`
  font-family: SlateProMedium;
  font-size: 14px;
  color: ${colors.gray};
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
`;
const ResetFiltersLine = styled.div`
  height: 1px;
  background-color: ${colors.gray};
  margin: 6px 0;
`;

export default ResetLink;
