import React, {useEffect, useRef} from 'react';
import {useCollection} from "../../../hooks/useCollection";
import {PageBackground, PageContainer, PageRowWithVerticalPadding} from "../../shared/Layouts";
import MediaFilesGrid from "../../shared/MediaFiles/MediaFilesGrid";
import GenreHeader from "./GenreHeader";
import background from "../auth/shared/auth-bacground.png";
import {colors} from "../../../config/StyleGuide";
import {useResource} from "../../../hooks/useResource";
import GenreCollectionFilters from "./GenreCollectionFilters";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import {noop, withScroll} from "../../../services/utils";
import Filters from "../../shared/filters/Filters";

const GenreCollection = ({match, location}) => {
  const genreId = match.params.id;
  const [genre] = useResource({path: `genres/${genreId}`});
  const firstLoad = useRef(true);

  useEffect(() => {
    firstLoad.current = false;
  }, []);

  const {
    facetFilters,
    clearFilter,
    resetFilters,
    setFilter,
    isActiveFilter,
    facetsQueryString
  } = useFacetsFilters(noop);

  const buildQuery = (...queries) => queries.join("&");

  const query = buildQuery(
      facetsQueryString,
      `id=${genreId}`
  );

  const {collection: mediaFiles, loadMore, hasMore, loading} = useCollection(
      `/media_files/genre`,
      query
  );

  if(!genre) return null;

  return (
      <PageBackground>
        <PageContainer>
          <GenreHeader genre={genre}
                       backgroundImage={background}
                       backgroundColor={colors.ink}
                       textColor={colors.white}/>

          <PageRowWithVerticalPadding>

            <div className="col-2">

              <Filters
                  values={facetFilters}
                  onClear={clearFilter}
                  onReset={resetFilters}
              />

              <GenreCollectionFilters genreId={genre.id}
                                      media={mediaFiles}
                                      onAdd={withScroll(setFilter, 350)}
                                      onClear={clearFilter}
                                      firstLoad={firstLoad}
                                      isActive={isActiveFilter}/>
            </div>

            <MediaFilesGrid
                mediaFiles={mediaFiles}
                hasMore={hasMore}
                loadMore={loadMore}
                loading={loading}
            >

            </MediaFilesGrid>
          </PageRowWithVerticalPadding>

        </PageContainer>
      </PageBackground>
  );
};

export default GenreCollection;
