import {LoaderContainer, PageBackground, PageContainer, PageRowWithPadding} from "components/shared/Layouts";
import React from "react";

import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {useResource} from "../../../hooks/useResource";
import EventSatchelHeader from "./EventSatchelHeader";
import EventSatchelCollections from "./EventSatchelCollections";
import Loading from "../../shared/Loading";

const EventSatchel = ({ match, location }) => {
  const eventId = match.params.id;
  const [event] = useResource({ path: `/events/${eventId}/satchel` });

  useBreadCrumbs({
    label: event && `The Event Collection of ${event.name}`,
    path: location.pathname
  });

  if (!event) return (
      <LoaderContainer>
        <Loading/>
      </LoaderContainer>
  );

  return (
    <PageBackground>
      <PageContainer>
        <PageRowWithPadding>
          <EventSatchelHeader satchelOwner={event.name} />
        </PageRowWithPadding>
        <EventSatchelCollections collections={event.collections} />
      </PageContainer>
    </PageBackground>
  );
};

export default EventSatchel;
