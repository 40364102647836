import React from "react";
import SideMenu from "../../shared/SideMenu";
import Menu from "../../shared/Menu";
import {ENTITIES, EPOCHS} from "../../../config/constants";
import FilterMenuItem from "components/shared/FilterMenuItem";
import {useResource} from "../../../hooks/useResource";
import FilterMenuItemTree from "../../shared/FilterMenuItemTree";

const PeopleFilters = ({onAdd, onClear, isActive}) => {
    const [places] = useResource({path: 'people/people_birth_places'});

    const renderEpoch = (epoch, idx) => {
        const epochFilter = {...epoch, entity: ENTITIES.EPOCHS, value: epoch.name};

        return (
            <FilterMenuItem
                key={idx + epoch.id}
                onAdd={onAdd}
                onClear={onClear}
                isActive={isActive}
                filter={epochFilter}
            />
        );
    };

    const renderPlaces = (place, idx) => {
        const placeFilter = {...place, entity: ENTITIES.LOCATION};
        return (
            <FilterMenuItemTree
                key={idx + place.id}
                onAdd={onAdd}
                onClear={onClear}
                isActive={isActive}
                filter={placeFilter}
                entity={ENTITIES.LOCATION}
            />
        );

    };

    return (
        <SideMenu>
            {places && (
            <Menu label={"born in"} itemLength={places.length}>
                {(places || []).map(renderPlaces)}
            </Menu>
            )}

            <Menu label={"lived during"} itemLength={EPOCHS.length}>
                {EPOCHS.map(renderEpoch)}
            </Menu>

        </SideMenu>
    );
};

export default PeopleFilters;
