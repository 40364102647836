import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";

export const openInNewWindow = imageId => {
  window.open(`${window.location.origin}/media/${imageId}`, "_blank");
};

export const Wrapper = styled.div`
  width: ${({ width }) => `${width}px` || "100%"};
  display: flex;
  flex-direction: ${({ alignText }) =>
    alignText === "right" ? "row" : "row-reverse"};
  align-items: flex-start;
  position: relative;
  color: ${colors.warmGraphite};
  cursor: pointer;
`;
