import React from "react";
import Rule from "components/shared/Rule";
import styled from "@emotion/styled";
import Menu from "components/shared/Menu";
import {colors} from "config/StyleGuide";
import {PageBackground, PageContainer} from "components/shared/Layouts";
import {ExternalNavyLink, H1, NavyLink} from "components/shared/style-guide/typography";
import StaticPageHeader from "components/shared/StaticPageHeader";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";

const MenuWrapper = ({ label, children }) => (
  <StyledMenuWrapper>
    <Menu label={label}>{children}</Menu>
  </StyledMenuWrapper>
);

const FAQ = ({ location }) => {
  useBreadCrumbs({ label: "FAQ", path: location.pathname });

  return (
    <PageBackground>
      <PageContainer>
        <div className="row pb-5">
          <ContentColumn className="col-6 offset-3">
            <StaticPageHeader>
              <H1>Frequently Asked Questions</H1>
            </StaticPageHeader>
            <Rule height={3} color={colors.warmGraphite} />
            <QuestionsList>
              <MenuWrapper
                label={<Question>Why is it called Tap Three Times?</Question>}
              >
                <Answer>
                  <ExternalNavyLink
                    href="https://www.youtube.com/watch?v=Byoyjo2zDc94"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=Byoyjo2zDc94
                  </ExternalNavyLink>
                </Answer>
              </MenuWrapper>
              <MenuWrapper
                label={<Question>Is it open to the public?</Question>}
              >
                <Answer>At present, no</Answer>
              </MenuWrapper>
              <MenuWrapper
                label={<Question>Can | add to Pieces of Mind?</Question>}
              >
                <Answer>
                  If you have something you want to tell other people that fits
                  within its purpose, then{" "}
                  <NavyLink to="/contact">contact us.</NavyLink>
                </Answer>
              </MenuWrapper>
              <MenuWrapper
                label={
                  <Question>
                    I have some photos, writings, videos, I’d like to add to the
                    T3T archive
                  </Question>
                }
              >
                <Answer>
                  Please <NavyLink to="/contact">share them with us</NavyLink>.
                  We have culled hundreds of thousands of photos to deliver this
                  set. We'd love to see what you have. Especially important are
                  things of interest to you or other people: life experiences,
                  life milestones, celebrations, the sublime, the mundane, the
                  ridiculous, the timeline of life.
                </Answer>
              </MenuWrapper>
              <MenuWrapper
                label={
                  <Question>I have found some errors in the site.</Question>
                }
              >
                <Answer>
                  We have found plenty of mistakes ourselves and are fixing, as
                  much as possible. Please{" "}
                  <NavyLink to="/contact">contact us.</NavyLink>. It’s best if
                  you include a screen shot of the error and if you can annotate
                  the screen shot, even better. If you can’t, just describe it
                </Answer>
              </MenuWrapper>
              <MenuWrapper
                label={
                  <Question>
                    What are the little hearts for on the site?
                  </Question>
                }
              >
                <Answer>To create and add to a collection of your own.</Answer>
              </MenuWrapper>
              <MenuWrapper
                label={<Question>Will the site be updated?</Question>}
              >
                <Answer>Yes. Life goes on.</Answer>
              </MenuWrapper>
            </QuestionsList>
          </ContentColumn>
        </div>
      </PageContainer>
    </PageBackground>
  );
};

const ContentColumn = styled.div`
  color: ${colors.warmGraphite};
`;

const QuestionsList = styled.div``;

const Question = styled.div`
  font-family: SangBleuVersaillesBook,sans-serif;
  font-size: 18px;
  color: ${colors.warmGraphite};
  text-transform: none;
  letter-spacing: normal;
  line-height: 28px;
`;

const Answer = styled.div`
  margin-bottom: 30px;
  font-family: SlateProBook;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 30px;
`;

const StyledMenuWrapper = styled.div`
  margin-top: 20px;
`;

export default FAQ;
