import React, {useState} from "react";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import Icon from "components/shared/Icon";

const Menu = ({ label, children, toggled = true }) => {
  const [visible, setVisible] = useState(toggled);

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <MenuWrapper>
      <MenuHeader onClick={toggle}>
        <MenuLabel>{label}</MenuLabel>
        <MenuToggle isOpen={visible}>
          <Icon onClick={toggle} type="FilterToggle" width={18} height={18} />
        </MenuToggle>
      </MenuHeader>
      {visible && <MenuList>{children}</MenuList>}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  border-bottom: 1px solid ${colors.black};
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-family: SlateProBold, sans-serif;
  font-size: 12px;
  color: #454545;
  letter-spacing: 0.2em;
  cursor: pointer;
  user-select: none;
`;

const MenuLabel = styled.span`
  font-family: SlateProRegular, sans-serif;
  font-size: 16px;
  color: ${colors.warmGraphite};
  text-transform: uppercase;
  letter-spacing: 0.15em;
  display: inline-block;
  cursor: pointer;
`;

const MenuToggle = styled.span`
  display: inline-block;
  transition: transform 0.2s ease-in-out;
  transform: rotate(${props => (props.isOpen ? 0 : -90)}deg);
  cursor: pointer;
`;

const MenuList = styled.ul`
  font-family: SlateProRegular, sans-serif;
  color: ${colors.warmGraphite};
`;

export default Menu;
