import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";

const AuthPageTitle = () => (
  <TitleContainer>
    <Subtitle>Welcome to</Subtitle>
    <Title>
      Tap<Italic>Three</Italic>Times
    </Title>
  </TitleContainer>
);

const TitleContainer = styled.div`
  font-family: "SangBleuVersaillesBook", sans-serif;
  font-style: normal;
  color: ${colors.white};
`;

const Subtitle = styled.div`
  font-size: 24px;
`;

const Title = styled.div`
  padding: 10px 0;
  font-size: 38px;
`;

const Italic = styled.span`
  font-family: "SangBleuVersaillesBookItalic", sans-serif;
`;

export default AuthPageTitle;
