import {LoaderContainer, PageBackground, PageContainer, PageRowWithPadding} from "components/shared/Layouts";
import React from "react";
import SatchelHeader from "./SatchelHeader";
import SatchelCollections from "./SatchelCollections";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {useResource} from "../../../hooks/useResource";
import Loading from "../../shared/Loading";

const Satchel = ({ match, location }) => {
  const satchelId = match.params.id;
  const [satchel] = useResource({ path: `/satchels/${satchelId}` });

  useBreadCrumbs({
    label: satchel && `The Satchel of ${satchel.person_name}`,
    path: location.pathname
  });

  if (!satchel) return (
      <LoaderContainer>
        <Loading/>
      </LoaderContainer>
  );

  return (
    <PageBackground>
      <PageContainer>
        <PageRowWithPadding>
          <SatchelHeader satchelOwner={satchel.person_name} />
        </PageRowWithPadding>
        <SatchelCollections collections={satchel.collections} />
      </PageContainer>
    </PageBackground>
  );
};

export default Satchel;
