export const colors = {
  parchment: "#f4f3ed",
  white: "#ffffff",
  vermillion: "#db6453",
  warmGraphite: "#3f3f3d",
  ink: "#1e284c",
  navy: "#2829b2",
  sapphire: "#282c81",
  cornFlower: "#b6bad4",
  coolGray: "#b8c1c4",
  gray: "#9ea7a0",
  stone: "#e3e6e7",
  disabledStone: "#f1f2f3",
  pink: "#dcc6b2",
  confirmationGreen: "#007f3d",
  errorRed: "#ed1c24",
  reed: "#caae99",
  placeholderGray: "#bac2c5",
  thumbnailBackground: "#f2f2f2",
  mapBackground: "#cacaca",
  mediaViewerBackground: "#f1f3f4"
};
