import React, {useMemo} from 'react';
import {useResource} from "../../../../hooks/useResource";
import Menu from "../../../shared/Menu";
import {ENTITIES} from "../../../../config/constants";
import FilterMenuItem from "../../../shared/FilterMenuItem";
import {sortPeopleByLastName} from "../../../../services/filters-utils";

const GenreRelatedPeopleFilter = ({genreId, onAdd, onClear, isActive, firstLoad}) => {
  const [relatedPeople] = useResource({path: `/media_files/people?id=${genreId}`});

  const sortedByLastName = useMemo(() => {
    if(!relatedPeople) return [];

    return sortPeopleByLastName(relatedPeople);
  }, [relatedPeople]);

  const renderRelatedPerson = (person) => {
    const personFilter = {
      ...person,
      name: `${person.first_name} ${person.last_name}`,
      entity: ENTITIES.PERSON
    };

    return (
        <FilterMenuItem key={person.id}
                        onAdd={onAdd}
                        onClear={onClear}
                        isActive={isActive}
                        filter={personFilter}/>
    )
  };

  if(!relatedPeople) return null;


  return (
      <Menu toggled={!firstLoad} label={'Related People'}>
        {sortedByLastName.map(relatedPerson => renderRelatedPerson(relatedPerson))}
      </Menu>
  );
};

export default React.memo(GenreRelatedPeopleFilter);
