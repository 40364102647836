import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {PageContainer, RandomizeButton} from "components/shared/Layouts";
import Icon from "components/shared/Icon";
import {useCollection} from "hooks/useCollection";
import {useResource} from "hooks/useResource";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import {ENTITIES} from "../../../config/constants";
import {useGenreFilters} from "../../../hooks/filters/useGenreFilters";
import Filters from "../../shared/filters/Filters";
import PlacesFilters from "./PlacesFilters";
import {RowOverlay} from "../../shared/Layouts";
import MediaFilesGrid from "components/shared/MediaFiles/MediaFilesGrid";
import {useDynamicGenres} from "../../../hooks/filters/useDynamicGenres";
import LoadingMediaCards from "../../shared/LoadingMediaCards";
import {getZoomLevel} from "../../../services/map-utils";
import InteractiveMap from "../../shared/InteractiveMap";

const Place = ({match, location}) => {
    const placeId = match.params.id;
    const [place] = useResource({path: `/locations/${placeId}`});

    useBreadCrumbs({label: place, path: location.pathname});

    const {
        GenreFilters,
        genresQueryString,
        updateAvailableGenres,
        resetGenreFilters
    } = useGenreFilters({
        path: `/locations/genres/?location_id=${placeId}`
    });

    const {
        clearFilter,
        setFilter,
        isActiveFilter,
        resetFilters,
        facetFilters,
        facetsQueryString,
        isFiltersApplied
    } = useFacetsFilters(resetGenreFilters);

    const buildQuery = (...queries) => queries.join("&");

    const query = buildQuery(
        genresQueryString,
        facetsQueryString,
        `${ENTITIES.LOCATION}_id=${placeId}`
    );

    const {
        shuffle,
        hasMore,
        loadMore,
        genres,
        collection: mediaFiles,
        isLoading,
        initialLoading
    } = useCollection("/locations/media", query);

    useDynamicGenres(
        facetFilters,
        genres,
        updateAvailableGenres,
        resetGenreFilters
    );

    const setFilterAndScroll = filter => {
        setFilter(filter);
        window.scrollTo({
            top: 500,
            left: 0,
            behavior: "smooth"
        })
    };


    if (!place) return null;

    return (
        <PageContainer>
            <Wrapper>
                <Background/>
                <RowOverlay>
                    <ColumnBorder className="col-1 offset-3"/>
                </RowOverlay>

                <HeaderRow className="row no-gutters">
                    <HeaderLeftSide className="col-5 offset-3">
                        <Title>{place.display_name}</Title>
                        <Description>
                            {place.short_description || "No Description"}
                        </Description>

                        <ActionsWrapper>
                            <ActionIcon
                                type="HeartLine"
                                size={24}
                                fill={colors.warmGraphite}
                            />
                            <ActionIcon type="Share" size={24} fill={colors.warmGraphite}/>
                        </ActionsWrapper>
                    </HeaderLeftSide>
                    <div className="col-4">
                        <InteractiveMap width={500}
                                        height={502}
                                        lat={place.coordinates[0]}
                                        lon={place.coordinates[1]}
                                        zoom={getZoomLevel(place)} />
                    </div>
                </HeaderRow>
            </Wrapper>

            <div className="row no-gutters py-5">
                <div className="col-2">
                    <RandomizeButton onClick={shuffle}>Randomize</RandomizeButton>

                    <Filters
                        values={facetFilters}
                        onClear={clearFilter}
                        onReset={resetFilters}
                    />

                    <PlacesFilters
                        place={place}
                        onAdd={setFilterAndScroll}
                        onClear={clearFilter}
                        isActive={isActiveFilter}
                    />
                </div>

                {initialLoading ? (
                    <LoadingMediaCards/>
                ) : (
                    <MediaFilesGrid
                        mediaFiles={mediaFiles}
                        hasMore={hasMore}
                        loadMore={loadMore}
                        loading={isLoading}
                        isFiltersApplied={isFiltersApplied}
                    >
                        <GenreFilters/>
                    </MediaFilesGrid>
                )}
            </div>
        </PageContainer>
    );
};

const HeaderRow = styled.div`
  position: relative;
  height: 100%;
`;

const Background = styled.div`
  background-color: #eeefef;
  left: -1000px;
  right: -1000px;
  top: 0;
  height: 100%;
  position: absolute;
`;

// todo nir: add styles (font size/color/family)
const Title = styled.div`
  font-size: 38px;
  margin-top: 100px;
`;

// todo nir: add styles (font size/color/family)
const Description = styled.div`
  font-size: 25px;
  margin-top: 50px;
  color: ${colors.warmGraphite};
`;

const ColumnBorder = styled.div`
  height: 500px;
  border-top: 10px solid ${colors.warmGraphite};
  border-bottom: 10px solid ${colors.warmGraphite};
`;

const HeaderLeftSide = styled.div`
  position: relative;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 99999;
`;

const ActionIcon = styled(Icon)`
  margin-right: 4px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  height: 500px;
  position: relative;
`;

export default Place;
