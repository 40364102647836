import React, {Component} from "react";
import apiRequest from "../../services/api";

export const AuthContext = React.createContext();

class Auth extends Component {
  state = {
    initialLoading: true,
    currentUser: null
  };

  async componentDidMount() {
    try {
      const response = await this.authenticate();

      if ("success" in response && response.success) {
        this.setCurrentUser(response.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ initialLoading: false });
    }
  }

  authenticate = async () => {
    const response = await apiRequest({ path: "/auth/validate_token" });
    return response.data;
  };

  setCurrentUser = currentUser => {
    this.setState({ currentUser });
  };

  render() {
    if (this.state.initialLoading) {
      return null;
    }

    const context = {
      currentUser: this.state.currentUser,
      setCurrentUser: this.setCurrentUser
    };

    return (
      <AuthContext.Provider value={context}>
        {this.props.children(context.currentUser)}
      </AuthContext.Provider>
    );
  }
}

export default Auth;
