import React from 'react';
import Menu from "../../../shared/Menu";
import {getPronoun, sortByName} from "../../../../services/filters-utils";
import {ENTITIES} from "../../../../config/constants";
import {useResource} from "../../../../hooks/useResource";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";

const PersonPlacesFilter = ({person, onAdd, onClear, isActive}) => {
  const [places, isLoading] = useResource({path: `people/places?person_id=${person.id}`});

  if(isLoading) return null;

  const renderRelatedPlace = (relatedPlace) => {
    const placeFilter = {
      ...relatedPlace,
      entity: ENTITIES.LOCATION
    };

    return (
        <FilterMenuItemTree
            key={relatedPlace.id}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={placeFilter}
            entity={ENTITIES.LOCATION}
        />
    );
  };

  // if the server returned an empty array
  if(!places || !places.length) return null;

  return (
      <Menu
          label={`${getPronoun(person.gender)} Places`}
          itemsLength={person.countries.length}
      >

        {places.sort(sortByName).map(place =>
            renderRelatedPlace(
                place,
                onAdd,
                onClear,
                isActive,
                ENTITIES.LOCATION
            )
        )}
      </Menu>
  );
};

export default PersonPlacesFilter;
