import React from "react";

import SideMenu from "../../shared/SideMenu";
import Menu from "../../shared/Menu";
import FilterMenuItemTree from "../../shared/FilterMenuItemTree";
import {ENTITIES} from "../../../config/constants";

const EventFilters = ({ topics, onAdd, onClear, isActive }) => {
  const renderTopic = (topic, onAdd, onClear, isActive) => {
    const filter = {
      name: topic.topic.display_name,
      id: topic.topic.id,
      children: topic.children.map(child => ({
        id: child.id,
        name: child.display_name,
      }))
    };

    return (
      <FilterMenuItemTree
        key={filter.id}
        onAdd={onAdd}
        onClear={onClear}
        isActive={isActive}
        filter={filter}
        entity={ENTITIES.TOPICS}
      />
    );
  };

  return (
    <SideMenu>
      <Menu label={`topics`} itemLength={topics.length}>
        {topics.map(topic =>
          renderTopic(topic, onAdd, onClear, isActive)
        )}
      </Menu>
    </SideMenu>
  );
};

export default EventFilters;
