import React, {Component} from "react";
import Modal from "react-modal";
import {colors} from "config/StyleGuide";
import Icon from "components/shared/Icon";
import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import apiRequest from "../../../services/api";
import MediaViewerDetailsPane from "./MediaViewerDetailsPane";
import MediaViewerPreviewPane from "./MediaViewerPreviewPane";
import ModalOverlayStyles from "../ModalOverlayStyles";
import CloseModalButton from "../CloseModalButton";

const modalStyle = {
  content: {
    display: "flex",
    width: "1200px",
    maxWidth: "1200px",
    height: "720px",
    position: "relative",
    margin: "0",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: ModalOverlayStyles
};

class MediaViewer extends Component {
  state = {
    currentFileIndex: this.props.currentFileIndex,
    mainMedia: null,
    thumbnails: null,
    isMetadataVisible: true,
    selectedMediaFile: null
  };

  loadSelectedFileData = async fileIndex => {
    const response = await apiRequest({
      path: `/media_files/${this.props.mediaFiles[fileIndex].id}`
    });

    this.setState({ selectedMediaFile: response.data });
  };

  toggleMetadata = () =>
    this.setState({ isMetadataVisible: !this.state.isMetadataVisible });

  render() {
    const { onCloseModal, mediaFiles, hasMore, loadMore } = this.props;

    const {
      currentFileIndex,
      isMetadataVisible,
      selectedMediaFile
    } = this.state;

    if (!Number.isInteger(currentFileIndex)) {
      return null;
    }

    return (
      <Modal isOpen={true} onRequestClose={onCloseModal} style={modalStyle}>
        <CloseWrapper>
          <CloseModalButton onClose={onCloseModal} />
        </CloseWrapper>

        <MediaViewerPreviewPane
          isMetadataVisible={isMetadataVisible}
          mediaFiles={mediaFiles}
          currentFileIndex={currentFileIndex}
          onSelectMediaFile={this.loadSelectedFileData}
          hasMore={hasMore}
          loadMore={loadMore}
        />

        <ToggleMetadataDivider onClick={this.toggleMetadata}>
          <Icon
            type={
              isMetadataVisible ? "CloseViewerDetails" : "OpenViewerDetails"
            }
            fill={colors.coolGray}
            size={18}
          />
        </ToggleMetadataDivider>

        {isMetadataVisible && selectedMediaFile && (
          <MediaViewerDetailsPane selectedMediaFile={selectedMediaFile} />
        )}
      </Modal>
    );
  }
}

MediaViewer.propTypes = {
  isOpen: PropTypes.any,
  onCloseModal: PropTypes.any,
  mediaFiles: PropTypes.any,
  currentFileIndex: PropTypes.any
};

const CloseWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 200;
`;

const ToggleMetadataDivider = styled.div`
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50px;
  flex-shrink: 0;
  cursor: pointer;
`;

export default MediaViewer;
