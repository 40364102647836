import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {H5} from "../../../../shared/style-guide/typography";

const ProfileDetailsBox = ({ label, children }) => (
  <Wrapper>
    <Label>{label}</Label>
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 10px 20px 25px 30px;
  color: ${colors.tempGrayColor};
  border-bottom: 2px solid ${colors.stone};
  &:last-child {
    border-bottom: 0;
  }
`;

const Label = styled(H5)`
  color: ${colors.coolGray};
  margin-bottom: 15px;
`;
export default ProfileDetailsBox;
