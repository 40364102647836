import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {isEmpty, isInteger} from "lodash/fp";
import MediaViewerComponent from "components/shared/media-viewer/MediaViewer";
import apiRequest from "services/api";

export const useStaticMediaFiles = filesIds => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  const getAllFiles = () => {
    return Promise.all(
      filesIds.map(fileId => apiRequest({ path: `/media_files/${fileId}` }))
    ).then(resources => resources.map(resource => resource.data));
  };

  useEffect(() => {
    getAllFiles().then(setMediaFiles);
  }, [filesIds]);

  const MediaViewer = () => (
    <>
      {isInteger(currentFileIndex) && (
        <MediaViewerComponent
          key={isInteger(currentFileIndex) ? currentFileIndex : "noIndexKey"}
          mediaFiles={mediaFiles}
          currentFileIndex={currentFileIndex}
          onCloseModal={() => setCurrentFileIndex(null)}
        />
      )}
    </>
  );

  if (isEmpty(mediaFiles)) {
    const emptyFiles = filesIds.map(_ => () => <span />);
    return [MediaViewer, emptyFiles];
  }

  const Files = mediaFiles.map((file, fileIndex) => {
    return ({ width, height }) => (
      <MediaFileImage
        width={width || "100%"}
        height={height || "auto"}
        onClick={() => setCurrentFileIndex(fileIndex)}
        alt={file.title || file.caption || file.short_description}
        src={file.url}
      />
    );
  });

  return [MediaViewer, Files];
};

const MediaFileImage = styled.img`
  cursor: pointer;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
