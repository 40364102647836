import React, {useEffect, useState} from "react";
import {xor} from "lodash";
import {GENRES} from "../../config/constants";
import Icon from "../../components/shared/Icon";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import apiRequest from "../../services/api";

export function useGenreFilters({ path }) {
  const [genreFilters, setGenreFilters] = useState([]);
  const [availableGenres, setAvailableGenres] = useState([]);

  const genresQueryString = new URLSearchParams(
    genreFilters.map(genre => ["genres[]", genre])
  ).toString();

  const applyFilter = genre => {
    if (genre === "all") {
      setGenreFilters([]);
      return;
    }

    setGenreFilters(xor(genreFilters, [genre]));
  };

  const isEmpty = genreFilters.length === 0;

  const GenreFilters = () => {
    const renderGenreFilter = genre => {


      if (!genre) {
        return "got undefined genre";
      }

      if (!GENRES[genre]) {
        return "Missing genre: " + genre;
      }

      const selected = genreFilters.includes(genre);

      return (
        <MediaTypeFilter
          key={genre}
          onClick={() => applyFilter(genre)}
          selected={genreFilters.includes(genre)}
        >
          <Icon
            type={GENRES[genre].icon}
            size={12}
            fill={selected ? colors.vermillion : colors.gray}
            style={{ marginRight: "5px", userSelect: "none" }}
          />

          <FilterLabel selected={selected}>{genre}</FilterLabel>
        </MediaTypeFilter>
      );
    };

    return (
      <FilterContainer>
        <FixedMediaTypeFilter
          onClick={() => applyFilter("all")}
          selected={isEmpty}
        >
          <FixedFilterLabel selected={isEmpty}>all types</FixedFilterLabel>
        </FixedMediaTypeFilter>
        {availableGenres.map(renderGenreFilter)}
      </FilterContainer>
    );
  };

  useEffect(() => {
    const apiResponse = apiRequest({ path });
    apiResponse.then(response => {
      setAvailableGenres(response.data);
    });
  }, []);

  return {
    genresQueryString,
    GenreFilters,
    updateAvailableGenres: setAvailableGenres,
    resetGenreFilters: () => setGenreFilters([])
  };
}

const FilterContainer = styled.div`
  margin-bottom: 40px;
`;

const MediaTypeFilter = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 115px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? "#db6453" : "#9ea7a0")};
  color: ${({ selected }) => (selected ? "#db6453" : "#9ea7a0")};
  margin: 0 5px 10px 0;
  padding: 0 10px;
  float: left;
  cursor: pointer;
`;

const FixedMediaTypeFilter = styled(MediaTypeFilter)`
  float: none;
  display: flex;
  justify-content: center;
`;

const FilterLabel = styled.span`
  display: inline-block;
  font-family: SlateProBook;
  letter-spacing: 0.25em;
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? colors.vermillion : colors.gray)};
  margin-left: 5px;
  user-select: none;
`;

const FixedFilterLabel = styled(FilterLabel)`
  text-align: center;
`;
