import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";

// todo nir: fix background color
const FILES_IDS = [2850, 2853];

const BoatStrip = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1] = Files;
  return (

    <StripContainer backgroundColor="#171D3B" id={"boatContainer"}>
      <MediaViewer />
      <RowOverlay>
        <div className="col-1 offset-10" style={{ marginTop: "500px" }} >
        <span id={"boat"}/>
          <StripLegend title="On A Boat" color={colors.white} anchor={"boat"}>
            <Section>
              <div>left:</div>
              <div>Atlantic Ocean</div>
              <YearLabel>1956</YearLabel>
            </Section>
            <Section>
              <div>right:</div>
              <div>Hudson River, USA</div>
              <YearLabel>192x</YearLabel>
            </Section>
          </StripLegend>
        </div>
      </RowOverlay>
      <RowOverlay>
        <div className="col-8 offset-2" style={{ marginTop: "150px" }}>
          <ImagesWrapper>
            <div style={{ marginRight: "70px", width: "360px" }}>
              <File0 />
            </div>
            <div style={{ width: "360px" }}>
              <File1 />
            </div>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default BoatStrip;
