import React from "react";
import SideMenu from "../../shared/SideMenu";
import RelatedPlacesFilter from "./filters/RelatedPlacesFilter";
import PlacesDuringEpochFilter from "./filters/PlacesDuringEpochFilter";

const PlacesFilters = (props) => {

  return (
      <SideMenu>
        <PlacesDuringEpochFilter {...props}/>
        <RelatedPlacesFilter {...props} />
      </SideMenu>
  );
};

export default PlacesFilters;
