import React, {useRef, useState} from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import ModalOverlayStyles from "components/shared/ModalOverlayStyles";
import Modal from "react-modal";
import {Description, H5} from "components/shared/style-guide/typography";
import {Input, SubmitButton} from "components/shared//FormElements";
import Icon from "components/shared/Icon";
import CloseModalButton from "components/shared/CloseModalButton";

const modalStyle = {
  content: {
    width: "350px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: ModalOverlayStyles
};

const ShareModal = ({
  linkUrl = "http://example.com",
  shareIconFill,
  iconSize
}) => {
  const linkTextRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(false);
  };

  const copyTextToClipBoard = e => {
    e.preventDefault();
    e.stopPropagation();

    linkTextRef.current.select();
    document.execCommand("copy");
    closeModal(e);
  };

  const onShareIconClick = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(true);
  };

  return (
    <>
      <Icon
        type="Share"
        size={iconSize}
        onClick={onShareIconClick}
        fill={shareIconFill}
      />
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={modalStyle}>
        <Wrapper>
          <TitleWrapper>
            <H5>share this item</H5>
          </TitleWrapper>
          <DescriptionWrapper>
            <Description>
              Link will only be viewable to other T3T members.
            </Description>
          </DescriptionWrapper>
          <LinkWrapper>
            <LinkText
              bgColor={colors.stone}
              ref={linkTextRef}
              value={linkUrl}
            />
            <LinkIcon type="Link" size={12} />
          </LinkWrapper>
          <SubmitButton onClick={copyTextToClipBoard}>copy link</SubmitButton>
          <CloseWrapper>
            <CloseModalButton onClose={closeModal} />
          </CloseWrapper>
        </Wrapper>
      </Modal>
    </>
  );
};

ShareModal.defaultProps = {
  shareIconFill: colors.white
};

const Wrapper = styled.div`
  padding: 30px 25px;
  position: relative;
`;

const LinkWrapper = styled.div`
  height: 38px;
  position: relative;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  border-radius: 19px;
  user-select: all;
  background-color: ${colors.stone};
`;

const LinkText = styled(Input)`
  font-size: 13px;
  pointer-events: none;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const LinkIcon = styled(Icon)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const TitleWrapper = styled.div`
  margin-bottom: 15px;
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 15px;
`;

export default ShareModal;
