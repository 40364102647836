import React from "react";
import defaultInky from "components/shared/images/_default-inky.png";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";

const AboutInky = ({ person }) => {
  return (
    <Wrapper>
      <Inky src={person.picture || defaultInky} />
      <Name>{person.name}</Name>
      <JobTitle>{person.jobTitle}</JobTitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  margin-bottom: 45px;
  padding: 0 10px;
`;

const Inky = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const Name = styled.div`
  font-family: SlateProBold;
  margin-bottom: 10px;
`;

const JobTitle = styled.div`
  font-family: SlateProRegular;
`;

export default AboutInky;
