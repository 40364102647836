import React, {useState} from "react";
import Slider from "react-slick";
import {useResource} from "hooks/useResource";
import Thumbnail from "components/shared/Thumbnail";
import SliderArrow from "../../shared/SliderArrow";
import styled from "@emotion/styled";
import MediaThumbnailMeta from "../../shared/MediaThumbnailMeta";
import {SectionTitle} from "../../shared/Layouts";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  draggable: false,
  arrows: false
};

const NewsCarousel = () => {
  const [sliderRef, setSliderRef] = useState({});
  const [news] = useResource({ path: "media_files/whats_new" });

  if (!news || news.total_count < 3) return null;

  return (
    <>
      <SectionTitle>what’ s new</SectionTitle>
      <div style={{ width: 1000 }}>
        <LeftArrow>
          <SliderArrow left={true} skipSlide={sliderRef.slickPrev} />
        </LeftArrow>
        <Slider {...settings} ref={ref => setSliderRef(ref)}>
          {news.items.map(item => (
            <div key={item.id}>
              <div style={{ paddingLeft: 15 }}>
                <Thumbnail file={item} />
                <MediaThumbnailMeta file={item} />
              </div>
            </div>
          ))}
        </Slider>
        <RightArrow>
          <SliderArrow skipSlide={sliderRef.slickNext} />
        </RightArrow>
      </div>
    </>
  );
};

const ArrowPosition = styled.div`
  position: absolute;
  cursor: pointer;
  top: 30%;
`;

const LeftArrow = styled(ArrowPosition)`
  left: -10%;
`;
const RightArrow = styled(ArrowPosition)`
  right: -10%;
`;

export default NewsCarousel;
