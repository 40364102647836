import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import PropTypes from "prop-types";
import FieldSeparator from "../pages/auth/shared/FieldSeparator";


const Tags = ({ label, tags, onTagSelect, large }) => {
  return (
    <TagsWrapper>
      <TagsFieldSet>
        <FieldSeparator padding={12} label={label} color={colors.reed} />
      </TagsFieldSet>
      <TagsList>
        {tags.map((tag, i) => (
          <TagEntry
            large={large}
            key={i}
            onClick={() => onTagSelect(tag.value)}
          >
            {tag.label}
          </TagEntry>
        ))}
      </TagsList>
    </TagsWrapper>
  );
};

const TagsWrapper = styled.div`
  color: ${colors.reed};
  text-transform: uppercase;
  font-family: SangBleuVersaillesRegular;
`;

const TagsFieldSet = styled.fieldset`
  //margin-bottom: 10px;
`;

const TagsTitle = styled.legend`
  font-size: 10px;
  padding-right: 5px;
`;

const TagsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 8px;
`;

const TagEntry = styled.div`
  font-family: SlateProRegular;
  font-size: 8px;
  letter-spacing: 0.12em;
  margin-right: 5px;
  margin-bottom: 6px;
  border: 1px solid ${colors.reed};
  padding: ${({ large }) => (large ? "10px 20px" : "3px 12px")};
  border-radius: 7px;
  cursor: pointer;
`;

Tags.defaultProps = {
  tags: [],
  large: false
};

Tags.propTypes = {
  large: PropTypes.bool,
  label: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  onTagSelect: PropTypes.func
};

export default Tags;
