import styled from "@emotion/styled";
import graphicStorieComingSoon from "components/pages/GraphicStories/graphic_stories_header.png";
import {PageBackground, PageContainer} from "components/shared/Layouts";
import PageBorder from "components/shared/PageBorder";
import {colors} from "config/StyleGuide";
import React from "react";

const GraphicStories = () => {
  return (
    <PageBackground>
      <PageContainer>
        <PageBorder>
          <ContentWrapper>
            <Label>tap three times presents</Label>
            <ImageWrapper>
              <Image src={graphicStorieComingSoon} />
            </ImageWrapper>
            <Label>coming soon</Label>
          </ContentWrapper>
        </PageBorder>
      </PageContainer>
    </PageBackground>
  );
};

const ContentWrapper = styled.div`
  margin: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 600px;
  height: auto;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
`;

const Label = styled.div`
  font-size: 20px;
  letter-spacing: 0.15em;
  font-family: SlateProRegular;
  color: ${colors.warmGraphite};
  text-transform: uppercase;
`;
export default GraphicStories;
