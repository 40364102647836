import {PageContainer, PageContent, RandomizeButton, Row} from "components/shared/Layouts";
import {useGenreFilters} from "hooks/filters/useGenreFilters";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {useResource} from "hooks/useResource";
import React from "react";
import {ENTITIES} from "../../../config/constants";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import Filters from "../../shared/filters/Filters";
import ClanFilters from "./ClanFilters";
import ClanHeader from "./ClanHeader";
import ClanMembers from "./ClanMembers";
import {useCollection} from "../../../hooks/useCollection";
import MediaFilesGrid from "components/shared/MediaFiles/MediaFilesGrid";
import {useDynamicGenres} from "../../../hooks/filters/useDynamicGenres";
import {withScroll} from "../../../services/utils";
import LoadingMediaCards from "../../shared/LoadingMediaCards";
import styled from "@emotion/styled";
import Loading from "../../shared/Loading";
import {LoaderContainer} from "../../shared/Layouts";

const Clan = ({match, location}) => {
  const clanId = match.params.id;
  const [clan] = useResource({path: `/clans/${clanId}`});

  useBreadCrumbs({label: clan, path: location.pathname});

  const {
    GenreFilters,
    genresQueryString,
    updateAvailableGenres,
    resetGenreFilters
  } = useGenreFilters({
    path: `/clans/genres/?clan_id=${clanId}`
  });

  const {
    facetFilters,
    clearFilter,
    resetFilters,
    setFilter,
    isActiveFilter,
    facetsQueryString,
    isFiltersApplied
  } = useFacetsFilters(resetGenreFilters);

  const buildQuery = (...queries) => queries.join("&");

  const query = buildQuery(
      facetsQueryString,
      genresQueryString,
      `${ENTITIES.CLAN}_id=${clanId}`
  );

  const {
    shuffle,
    collection: mediaFiles,
    hasMore,
    loadMore,
    genres,
    initialLoading,
    loading
  } = useCollection("/clans/media", query);

  useDynamicGenres(
      facetFilters,
      genres,
      updateAvailableGenres,
      resetGenreFilters
  );

  if(!clan) return (
      <LoaderContainer>
        <Loading/>
      </LoaderContainer>
  );

  return (
      <PageContainer>
        <Row>
          <div className="col-12">
            <ClanHeader clan={clan}/>
          </div>
        </Row>
        <Row>
          <div className="col-10 offset-1">
            <ClanMembers clan={clan}/>
          </div>
        </Row>

        <div className="row pt-5">
          <div className="col-2">

                <>
                  <RandomizeButton onClick={shuffle}>Randomize</RandomizeButton>
                  <Filters
                      values={facetFilters}
                      onClear={clearFilter}
                      onReset={resetFilters}
                  />

                  <ClanFilters
                      clan={clan}
                      onAdd={withScroll(setFilter, 750)}
                      onClear={clearFilter}
                      isActive={isActiveFilter}
                  />
                </>


          </div>

          {loading ? <LoadingMediaCards loading={loading}/> : (

              <MediaFilesGrid
                  mediaFiles={mediaFiles}
                  hasMore={hasMore}
                  loadMore={loadMore}
                  loading={initialLoading}
                  isFiltersApplied={isFiltersApplied}
              >
                <GenreFilters/>
              </MediaFilesGrid>
          )}
        </div>

        <PageContent/>
      </PageContainer>
  );
};


export default Clan;
