export const noop = _ => _;

// wrap a function and call window scroll
export function withScroll(fn, position) {
    return function (...args) {
        fn(...args);
        window.scrollTo({
            top: position,
            left: 0,
            behavior: "smooth"
        });
    }
}

export function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

