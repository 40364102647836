import React, {useContext} from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {BreadcrumbsContext} from "./BreadcrumbsProvider";
import {Link} from "react-router-dom";

const Breadcrumbs = () => {
  const { pathHistory } = useContext(BreadcrumbsContext);
  return (
    <BreadcrumbsWrapper>
      <BreadCrumb to="/">Home </BreadCrumb>
      {pathHistory.map((path, i) => (
        <span key={i}>
          / <BreadCrumb to={path.path}> {path.label} </BreadCrumb>
        </span>
      ))}
    </BreadcrumbsWrapper>
  );
};

const BreadCrumb = styled(Link)`
  text-decoration: none;
  color: ${colors.coolGray};

  &:visited {
    color: ${colors.coolGray};
  }

  &:hover {
    color: ${colors.warmGraphite};
  }
`;

const BreadcrumbsWrapper = styled.div`
  font-family: SlateProRegular, sans-serif;
  color: ${colors.coolGray};
  font-size: 12px;
  letter-spacing: 0.02em;
`;

export default Breadcrumbs;
