import React from 'react';
import Menu from "../../../shared/Menu";
import {ENTITIES} from "../../../../config/constants";
import {useResource} from "../../../../hooks/useResource";
import FilterMenuItemTree from "../../../shared/FilterMenuItemTree";
import {sortByName} from "../../../../services/filters-utils";

const ClanPlacesFilter = ({clan, onAdd, onClear, isActive}) => {
  const [places, isLoading] = useResource({path: `clans/places?clan_id=${clan.id}`});

  if(isLoading || !places || !places.length) return null;

  const renderRelatedPlace = (relatedPlace, idx) => {
    const placeFilter = {
      ...relatedPlace,
      entity: ENTITIES.LOCATION
    };

    return (
        <FilterMenuItemTree
            key={relatedPlace.id + idx}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={placeFilter}
            entity={ENTITIES.LOCATION}
        />
    );
  };


  return (
      <Menu label={`their places`} itemLength={places.length}>
        {places.sort(sortByName).map((place, idx) => renderRelatedPlace(place, idx))}
      </Menu>
  );
};

export default ClanPlacesFilter;
