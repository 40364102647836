import React, {useState} from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import Icon from "components/shared/Icon";

const CloseModalButton = ({ onClose }) => {
  const [active, setActive] = useState(false);
  const fill = active ? colors.gray : colors.coolGray;

  return (
    <StyledIcon
      onMouseIn={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      type="Close"
      size={12}
      onClick={onClose}
      fill={fill}
    />
  );
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export default CloseModalButton;
