import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";

const SideMenu = ({ children }) => {
  return <MenuContainer>{children}</MenuContainer>;
};

const MenuContainer = styled.div`
  padding: 0;
  margin: 0;
  border-top: 2px solid ${colors.black};
`;

export default SideMenu;
