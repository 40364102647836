import {useState} from "react";

export function useQueryFilter() {
  const [searchQuery, setSearchQuery] = useState("");

  const searchQueryString = searchQuery
    ? new URLSearchParams([["query", searchQuery]]).toString()
    : "";

  return { setSearchQuery, searchQueryString };
}
