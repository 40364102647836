import React, {useContext, useEffect, useRef} from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {Input, SubmitButton} from "components/shared/FormElements";

import events from "./piano-images/Dashboard_01-Events.jpg";
import people from "./piano-images/Dashboard_02-People.jpg";
import places from "./piano-images/Dashboard_03-Places.jpg";
import clans from "./piano-images/Dashboard_04-Clans.jpg";
import satchels from "./piano-images/Dashboard_06-Satchels.jpg";
import pom from "./piano-images/Key_PIecesofMind_v2.jpg";
import nyre from "./piano-images/Dashboard_08-NYRE.jpg";
import members from "./piano-images/Dashboard_09-Members.jpg";
import viewfinder from "./piano-images/Key_Viewfinder.jpg";
import graphicStories from "./piano-images/Dashboard_10-GraphicStories.jpg";
import {AuthContext} from "components/context-providers/Auth";
import {withRouter} from "react-router-dom";
import {useSearch} from "hooks/useSearch";
import {UnstyledLink} from "../../shared/UnstyledLink";

const Piano = ({ location }) => {
  const { currentUser } = useContext(AuthContext);
  const { submitSearch } = useSearch(location);

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = null;
    inputRef.current.blur();
  }, [location.search]);

  const onSearch = e => {
    if (e.keyCode === 13 || e.target.type === "submit") {
      submitSearch(inputRef.current.value);
    }
  };

  return (
    <PianoWrapper>
      <Tab img={events} text="Events" to="/events" />
      <Tab img={people} text="People" to="/people" />
      <Tab img={places} text="Places" to="/places" />
      <Tab img={clans} text="Clans" to="/clans" />
      {/*<Tab img={lifemilestones} text="Life Milestones" to="/milestones" />*/}
      <Tab img={satchels} text="Satchels" to="/satchels" />
      <Tab img={pom} text="Pieces of Mind" to="/pom" />
      <Tab img={viewfinder} text="Viewfinder No. 07" to="/viewfinder" />

      <Tab img={graphicStories} text="Graphic Stories" to="/stories" />
      <Tab img={members} text="Member Profiles" to="/members" />

      <ExternalTab
        img={nyre}
        text="New York Real Estate"
        href="http://159.203.117.68/"
        target="_blank"
      />

      <SearchContainer>
        <SearchContent>
          <Welcome>Welcome back, {currentUser.first_name}</Welcome>
          <Message>
            Looking for something <br /> in particular?
          </Message>
          <PianoSearchInput
            bgColor={colors.white}
            placeholder="Search"
            holderColor={colors.coolGray}
            defaultValue=""
            ref={inputRef}
            onKeyDown={onSearch}
          />
          <SubmitSearch onClick={onSearch}>EXPLORE THE ARCHIVE</SubmitSearch>
        </SearchContent>
      </SearchContainer>
    </PianoWrapper>
  );
};

const PianoSearchInput = styled(Input)`
  text-align: center;
  color: ${colors.warmGraphite};
  padding-left: 0;

  ::-webkit-input-placeholder {
    text-align: center;
  }
`;

const SubmitSearch = styled(SubmitButton)`
  margin-top: 20px;
  font-family: SlatePro, sans-serif;
  text-transform: uppercase;
`;

const PianoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
`;

const SearchContainer = styled.div`
  padding: 40px;
  background-color: ${colors.ink};
  display: flex;
  flex: 1;
  align-items: center;
`;

const SearchContent = styled.div`
  margin: 0 auto;
  width: 390px;
`;

const Tab = styled(UnstyledLink)`
  font-family: SangBleuVersaillesRegular, serif;
  font-size: 40px;
  letter-spacing: .01em;
  color: ${colors.white};
  height: 500px;
  width: 88px;
  background: url(${({ img }) => img});
  transition: all 0.2s linear;
  position: relative;
  
  &:before {
    position: absolute;
    bottom: 50px;
    content: '${props => props.text}';
    transform: rotate(-90deg);
    width: 100%;
    white-space: nowrap;
    color: ${colors.white};
  }

  &:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
`;

const ExternalTab = Tab.withComponent("a");

const Welcome = styled.p`
  font-family: SangBleuVersaillesRegularItalic, sans-serif;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: ${colors.white};
`;

const Message = styled.p`
  font-family: SangBleuVersaillesRegular, sans-serif;
  font-size: 30px;
  letter-spacing: 0.01em;
  color: ${colors.white};
  line-height: 38px;
  padding: 10px 0;
  margin-bottom: 25px;
`;

export default withRouter(Piano);
