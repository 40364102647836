import React from 'react';

const token = process.env.REACT_APP_MAPBOX_TOKEN;
const owner = 'bgoldhagen';
const styleId = 'ck0o6fyy017no1cnw456pbk3v';

const StaticMap = ({lon, lat, zoom, height, width}) => {
  if(!lon || !lat) return null;

  const url = `https://api.mapbox.com/styles/v1/${owner}/${styleId}/static/pin-l(${lon.trim()},${lat.trim()})/${lon.trim()},${lat.trim()},${zoom},0,0/${width}x${height}?access_token=${token}&fresh=true`;

  return (
      <div>
        <img src={url} alt=""/>
      </div>
  )

};

export default StaticMap;