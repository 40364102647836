import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {PageRow} from "../../../shared/Layouts";
import {withRouter} from "react-router-dom";


const SearchResultsHeader = ({searchQuery, resultCount}) => {
  return (
      <PageRow>
        <div className="col">
          <Container>
            <SearchLabel>Showing results for</SearchLabel>
            <SearchQuery>{searchQuery}</SearchQuery>
            <SearchLabel>{resultCount} results</SearchLabel>
          </Container>
        </div>
      </PageRow>
  );
}

const Container = styled.div`
  line-height: 1.4;
`;

const SearchLabel = styled.div`
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  font-family: SlateProRegular;
  font-size: 15px;
`;

const SearchQuery = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 50px;
  color: ${colors.vermillion};
`;

export default withRouter(SearchResultsHeader);
