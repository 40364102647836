import React, {useState} from "react";

export const BreadcrumbsContext = React.createContext();
const HISTORY_LIMIT = 5;

const BreadcrumbsProvider = ({ children }) => {
  const [pathHistory, setPathHistory] = useState([]);

  const appendPath = ({ label, path }) => {
    if (
      pathHistory.length &&
      path === pathHistory[pathHistory.length - 1].path
    ) {
      return;
    }

    if (pathHistory.length < HISTORY_LIMIT) {
      setPathHistory(pathHistory.concat({ label, path }));
    } else {
      setPathHistory([...pathHistory.slice(1), { label, path }]);
    }
  };

  return (
    <BreadcrumbsContext.Provider value={{ pathHistory, appendPath }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsProvider;
