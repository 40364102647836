import React, {useState} from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import InfiniteScroll from "react-infinite-scroller";
import Icon from "components/shared/Icon";
import useWindowScrollPosition from "@rehooks/window-scroll-position";

const onScroll = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });

const scrollListenerOptions = {
  throttle: 300
};

const MIN_POSITION_VISIBLE = 400;

const ScrollableInfiniteScroll = ({ children, ...rest }) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const { y: yPosition } = useWindowScrollPosition(scrollListenerOptions);

  if (yPosition > MIN_POSITION_VISIBLE && !isButtonVisible) {
    setIsButtonVisible(true);
  } else if (yPosition < MIN_POSITION_VISIBLE && isButtonVisible) {
    setIsButtonVisible(false);
  }

  return (
    <Wrapper className="col-12">
      <ScrollToTopWrapper onClick={onScroll} isVisible={isButtonVisible}>
        <BackToTopIcon
          type="BackToTop"
          height={120}
          width={60}
          fill={colors.reed}
        />
      </ScrollToTopWrapper>
      <InfiniteScroll {...rest}>{children}</InfiniteScroll>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const BackToTopIcon = styled(Icon)`
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
  }
`;

const ScrollToTopWrapper = styled.div`
  position: fixed;
  bottom: 100px;
  width: 1430px;
  left: calc(50% + 20px);
  z-index: 9999;
  transform: translateX(50%);
  cursor: pointer;
  transition: opacity 0.5s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

export default ScrollableInfiniteScroll;
