import React, {useState} from "react";
import {PageContainer, SectionTitle} from "../../shared/Layouts";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import {colors} from "config/StyleGuide";
import Icon from "components/shared/Icon";
import Region from "components/pages/places/Region";
import FeaturedPlace from "components/pages/places/FeaturedPlace";
import regionNorthAmerica from "./images/regions/region_na.jpg";
import regionEurope from "./images/regions/region_eu.jpg";
import regionOther from "./images/regions/region_other.jpg";
import regionIsrael from "./images/regions/region_israel.jpg";
import placeSummerCamp from "./images/featured-places/summer_camp_thumb.jpg";
import placeAntarctica from "./images/featured-places/antarctica_thumb.jpg";
import placeBukovina from "./images/featured-places/holocaust_in_bukovina_thumb.jpg";
import placeNormaTours from "./images/featured-places/norma_tours_thumb.jpg";
import placeNewYorkChildren from "./images/featured-places/children_ny_thumb.jpg";

import {useSearch} from "hooks/useSearch";
import {useStaticMediaFiles} from "../../../hooks/useStaticMediaFiles";

const FILES_IDS = [2958, 2959];

const Places = ({location}) => {
  const [mapName, setMapName] = useState('birthMap');
  const [activeMapName, setActiveName] = useState('Births');
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);

  useBreadCrumbs({label: "Places", path: location.pathname});

  const {submitSearch} = useSearch();

  const [File0, File1] = Files;


  const handleSearchQuery = ({which, target}) => {
    if(which === 13) {
      submitSearch(target.value, "places");
    }
  };

  const switchHeaderMap = (mapName) => {
    if(mapName === 'Births') {
      setActiveName('Births');
      setMapName('birthMap');
    }

    if(mapName === 'Deaths') {
      setActiveName('Deaths');
      setMapName('deathsMap');
    }
  };

  return (
      <PageContainer>
        <MediaViewer/>
        <HeaderRow className="row no-gutters">
          <HeroBackground className="col-6 offset-6"/>
          <div className="col-4">
            <HeroTitle>places</HeroTitle>
          </div>
          <div className="col-6 pr-0">
            <MapWrapper>
              <MapPlaceholder>
                {mapName === 'birthMap' ? <File1/> : <File0/>}
              </MapPlaceholder>
              <MapLinksContainer>
                <TopMapLinksContainer>
                  <MapLink
                      onClick={() => switchHeaderMap('Births')}
                      color={activeMapName === 'Births' ? colors.ink : colors.vermillion}
                  >
                    Births
                  </MapLink>
                  <MapLink
                      onClick={() => switchHeaderMap('Deaths')}
                      color={activeMapName === 'Deaths' ? colors.ink : colors.vermillion}
                  >
                    Final Resting Places
                  </MapLink>
                </TopMapLinksContainer>
                <div>
                  <MapLink>
                    <Icon type="PlaceSearch" size={18} fill={colors.vermillion}/>
                    <SearchInput
                        placeholder="Search places"
                        onKeyDown={handleSearchQuery}
                    />
                  </MapLink>
                </div>
              </MapLinksContainer>
            </MapWrapper>
          </div>
        </HeaderRow>

        <div className="row p-0 no-gutters">
          <MarginSectionTitle>Explore regions</MarginSectionTitle>
        </div>
        <div className="row p-0 no-gutters">
          <Region img={regionEurope} text="Europe" link="/places/11"/>
          <Region
              img={regionNorthAmerica}
              text="North America"
              link="/places/12"
          />
          <Region img={regionIsrael} text="Israel" link="/places/145"/>
          <Region img={regionOther} text="Other Places" link={"/places/295"}/>
        </div>

        <div className="row p-0 no-gutters">
          <MarginSectionTitle>Featured places in time</MarginSectionTitle>
        </div>
        <div className="row p-0 no-gutters">
          <FeaturedPlaceRow className="col-lg-4 offset-1 mt-0">
            <FeaturedPlace
                collectionId={8}
                startYear={1391}
                endYear={1948}
                img={placeBukovina}
                title="Holocaust in Bukovina"
                subtitle="The winds of war trapped Dresners and Goldhagens in the region. Some hid, some were deported, most perished, few survived."
            />
            <FeaturedPlace
                collectionId={10}
                img={placeSummerCamp}
                title="Maine Summer Camp Collections"
                subtitle="Three generations growing up amidst the evergreens and lakes of Southern Maine."
                startYear={1936}
                endYear={2014}
            />
            <FeaturedPlace
                collectionId={12}
                img={placeAntarctica}
                title="Antarctica"
                subtitle="Having done a marathon in 1988 in the Arctic, Ben journeys seven years later to the Antarctic to run the first ever marathon on that continent."
                startYear={1995}
            />
          </FeaturedPlaceRow>
          <FeaturedPlaceRow marginTop={120} className="col-lg-4 offset-1">
            <FeaturedPlace
                collectionId={9}
                img={placeNormaTours}
                startYear={1956}
                title="Norma Tours Europe Solo"
                subtitle="Norma (age 24) meanders three months in 1956 through France, the Pyrenees, Spain, Northern Italy, Switzerland, an assortment of Alps, Germany, Berlin, The English Channel, London, Scotland, and cities, towns villages, lakes and roadsides in a Renault 4CV that she picks up and drops off in Paris."
            />
            <FeaturedPlace
                collectionId={11}
                img={placeNewYorkChildren}
                title="Children of New York"
                subtitle="Birth, childhood, adolescense in New York City"
                startYear={1895}
                endYear={2019}
            />
          </FeaturedPlaceRow>
        </div>
      </PageContainer>
  );
};

const MarginSectionTitle = styled(SectionTitle)`
  margin: 82px 0;
`;

const HeaderRow = styled.div`
  height: 600px;
`;

const HeroBackground = styled.div`
  position: absolute;
  background-color: ${colors.ink};
  height: 600px;
`;

const HeroTitle = styled.div`
  font-family: SangBleuVersaillesRegular;
  color: ${colors.reed};
  font-size: 122px;
  text-transform: uppercase;
  word-break: break-word;
  line-height: 1.3;
  letter-spacing: 66px;
  opacity: 0.5;
`;

const MapWrapper = styled.div`
  padding: 10px;
  height: 450px;
  width: 780px;
  position: relative;
  margin-top: 70px;

  &::before,
  &:after {
    content: "";
    position: absolute;
    background-color: ${colors.white};
    height: 90px;
    width: 90px;
    z-index: 1;
  }

  &:before {
    top: -10px;
    left: -10px;
  }

  &:after {
    bottom: -10px;
    right: -10px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const MapPlaceholder = styled.div`
  background-color: ${colors.mapBackground};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

const MapLinksContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: -35px;
  z-index: 2;
  width: 210px;
  display: flex;
  flex-direction: column;
  color: ${colors.vermillion};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  transform: translateX(-50%);
`;

const TopMapLinksContainer = styled.div`
  margin-bottom: 35px;
`;

const mapLinkBorder = `2px solid ${colors.vermillion}`;

const MapLink = styled.div`
  cursor: pointer;
  font-family: SlateProMedium;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-decoration: none;
  height: 35px;
  border: ${mapLinkBorder};
  padding-left: 15px;
  display: flex;
  align-items: center;
  font-weight: 900;
  &:not(:last-child) {
    border-bottom: none;
  }

  ${({color}) =>
    color &&
    css`
      color: ${color};
    `};
`;

const SearchInput = styled.input`
  font-family: SlateProMedium;
  font-size: 12px;
  letter-spacing: 0.15em;
  flex: 1;
  height: 100%;
  background: transparent;
  border: 0;
  color: ${colors.warmGraphite};

  ::-webkit-input-placeholder {
    letter-spacing: 0.15em;
    font-family: SlateProMedium;
    font-size: 12px;
    text-transform: uppercase;
    color: ${colors.gray};
  }
  margin-left: 5px;
`;

const FeaturedPlaceRow = styled.div`
  ${({marginTop}) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `};
`;

export default Places;
