import React, {useState} from "react";
import {UnstyledLink} from "components/shared/UnstyledLink";
import {chunk} from "lodash/fp";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {Row} from "components/shared/Layouts";
import SatchelHoverContent from "./SatchelHoverContent";
import satchelsListItemImage from "./satchels_list_item.png";

const SatchelsList = ({ satchels }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const mockDataRowsChunks = chunk(3, satchels);

  const toggleSelectedSatchel = satchel => {
    hoveredItem === satchel ? setHoveredItem(null) : setHoveredItem(satchel);
  };

  return (
    <div className="col-8 offset-2">
      {mockDataRowsChunks.map((rowChunk, rowIndex) => {
        return (
          <React.Fragment key={rowIndex}>
            <Row>
              {rowChunk.map(satchel => {
                return (
                  <SatchelWrapper
                    key={satchel.id}
                    className="col-4"
                    onClick={() => toggleSelectedSatchel(satchel)}
                  >
                    <Row>
                      <div className="col-9" key={satchel.name}>
                        <ContentColumnWrapper>
                          <Image src={satchelsListItemImage} alt="" />
                          <Name>{satchel.person_name}</Name>
                        </ContentColumnWrapper>
                      </div>
                      <div className="col-3" />
                    </Row>
                  </SatchelWrapper>
                );
              })}
            </Row>
            <Row className="row no-gutters">
              {rowChunk.map(satchel => (
                <React.Fragment key={satchel.name}>
                  {hoveredItem === satchel && (
                    <HoverCol className="col-12" key={satchel.id}>
                      <FullRowHoverBackground />
                      <UnstyledLink to={`/satchels/${satchel.id}`}>
                        <HoveredItemWrapper>
                          <SatchelHoverContent satchel={satchel} />
                        </HoveredItemWrapper>
                      </UnstyledLink>
                    </HoverCol>
                  )}
                </React.Fragment>
              ))}
            </Row>
          </React.Fragment>
        );
      })}
    </div>
  );
};

// todo nir: fix color (couldn't find the real one)
const FullRowHoverBackground = styled.div`
  position: absolute;
  background-color: #e9e8da;
  width: 1400px;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  height: 250px;
`;
const HoverCol = styled.div`
  position: relative;
`;

const ContentColumnWrapper = styled.div`
  position: relative;
  height: 200px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
`;

const SatchelWrapper = styled.div`
  cursor: pointer;
`;

const Name = styled.div`
  font-family: SangBleuVersaillesRegular;
  font-size: 22px;
  letter-spacing: 0.2em;
  line-height: 30px;
  color: ${colors.white};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const HoveredItemWrapper = styled.div`
  height: 250px;
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
`;

export default SatchelsList;
