import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import {Date, Description, H4} from "./style-guide/typography";

const MediaThumbnailMeta = ({ file }) => (
  <MediaFileMeta>
    <H4>{file.title} </H4>
    <Date>{file.display_date || "No Display Date"}</Date>
    <ThumbnailDescription>{file.caption || "No Caption"}</ThumbnailDescription>
  </MediaFileMeta>
);

const ThumbnailDescription = styled(Description)`
  line-height: 18px;
`;

const MediaFileMeta = styled.div`
  color: ${colors.warmGraphite};
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.5em;
  word-break: break-word;
  padding-right: 25px;
`;

export default MediaThumbnailMeta;
