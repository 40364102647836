import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import {Router} from "react-router-dom";
import history from "config/history";
import Modal from "react-modal";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "bootstrap/dist/css/bootstrap-grid.css";

import BreadcrumbsProvider from "./components/shared/BreadcrumbsProvider";
import ScrollToTop from "./components/shared/ScrollToTop";
import {MyCollectionStatusProvider} from "hooks/useMyCollectionStatus";

Modal.setAppElement("#root");

ReactDOM.render(
  <Router history={history} >
    <ScrollToTop>
      <MyCollectionStatusProvider>
        <BreadcrumbsProvider>
          <App />
        </BreadcrumbsProvider>
      </MyCollectionStatusProvider>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);
