import React from "react";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import {useCollection} from "../../../hooks/useCollection";
import {useSearch} from "../../../hooks/useSearch";
import {PageRow, PageSideBar} from "../../shared/Layouts";
import NoResults from "../../shared/NoResults";
import SideMenu from "../../shared/SideMenu";
import ClansInkyGrid from "../clans/shared/ClansInkyGrid";
import SearchResultsCategorySelection from "./shared/SearchResultsCategorySelection";

const ClanSearchResults = ({ location, resultByTypeCount }) => {
  const { searchQuery, longQuery } = useSearch(location.search);

  const query = `query=${longQuery || searchQuery}`;

  const { collection: clans, loadMore, hasMore } = useCollection(
    "/clans/search",
      query
  );

  return (
    <>
      <SearchResultsCategorySelection category={"clans"} query={searchQuery} resultByTypeCount={resultByTypeCount} />
      <PageRow>
        <PageSideBar>
          <SideMenu />
        </PageSideBar>
        <div className="col-7 offset-1 pr-0">
          {clans && !!clans.length ? (
            <ScrollableInfiniteScroll
              key={query}
              hasMore={hasMore}
              loadMore={loadMore}
              useWindow={true}
            >
              <ClansInkyGrid clans={clans} col={4} />
            </ScrollableInfiniteScroll>
          ) : (
            <NoResults />
          )}
        </div>
      </PageRow>
    </>
  );
};

export default ClanSearchResults;
