import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import React from "react";

const ToggleButton = ({ value, onToggle }) => (
  <SwitchLabel>
    <Input type="checkbox" checked={value} onChange={onToggle} />
    <Slider checked={value} />
  </SwitchLabel>
);

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 65px;
  height: 26px;
  margin-left: 4px;
`;

// todo nir: add real style by styleguide (current not available)
const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  font-size: 12px;
  border: 1px solid ${colors.gray};
  font-size: 12px;
  letter-spacing: 0.04em;
  font-family: SlateProMedium;

  &:before, &:after {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;

  }
&:before {
  content: "";
  transition: .4s;
  height: 20px;
  width: 26px;
  left: 4px;
  background-color: ${({ checked }) => (checked ? colors.navy : colors.gray)};
  border-radius: 34px;
  transform: ${({ checked }) =>
    checked ? "translate(26px, -50%)" : "translateY(-50%)"};
}
&:after {
  left: ${({ checked }) => (checked ? "-8px" : "auto")};
  right: ${({ checked }) => (checked ? "auto" : "8px")};
  position: absolute;
  content: '${({ checked }) => (checked ? "Yes" : "No")}';
  color: ${({ checked }) => (checked ? colors.navy : colors.warmGraphite)};
  text-transform: uppercase;
  padding: 0 14px;
  width: 26px;
}
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked {
  }
`;

export default ToggleButton;
