import React from "react";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import PropTypes from "prop-types";

import manInky from "./images/default-man-inky.png";
import womanInky from "./images/default-woman-inky.png";

import FieldSeparator from "../pages/auth/shared/FieldSeparator";

const RelatedPeopleTags = ({ label, people, onTagSelect, large }) => {
  // there are cases of names that include more then
  // one word. Each word should be a new line (by design)
  const formatDisplayName = fullName => {
    const words = fullName.split(" ");
    return (
      <>
        {words.map((name, idx) => (
          <div key={idx}>{name}</div>
        ))}
      </>
    );
  };

  const getInky = person => {
    if (person.inky) return person.inky;
    return person.gender === "Female" ? womanInky : manInky;
  };

  return (
    <TagsWrapper>
      <TagsFieldSet>
        <FieldSeparator label={label} color={colors.reed} padding={12} />
      </TagsFieldSet>
      <TagsList>
        {people.map((person, i) => (
          <TagEntry
            large={large}
            key={person.id}
            onClick={() => onTagSelect(person.id)}
          >
            <InkyContainer>
              <Inky url={getInky(person)} />
              <DisplayName>
                {formatDisplayName(person.display_name)}
              </DisplayName>
            </InkyContainer>
          </TagEntry>
        ))}
      </TagsList>
    </TagsWrapper>
  );
};

const TagsWrapper = styled.div`
  color: ${colors.reed};
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: SangBleuVersaillesRegular;
`;
const Inky = styled.div`
  background: url(${({ url }) => url}) no-repeat;
  background-size: cover;
  height: 106px;
  width: 97px;
  margin-bottom: 10px;
`;

const InkyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TagsFieldSet = styled.fieldset`
  margin-bottom: 10px;
`;

const TagsTitle = styled.legend`
  font-size: 10px;
  padding-right: 5px;
`;

const TagsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 8px;
`;

const TagEntry = styled.div`
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const DisplayName = styled.div`
  font-family: SlateProRegular;
  font-size: 9px;
  color: ${colors.reed};
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  line-height: 12px;
`;

RelatedPeopleTags.defaultProps = {
  people: [],
  large: false
};

RelatedPeopleTags.propTypes = {
  large: PropTypes.bool,
  label: PropTypes.string,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.any,
      inky: PropTypes.string
    })
  ),
  onTagSelect: PropTypes.func
};

export default RelatedPeopleTags;
