import {CancelButton, Input, SubmitButton} from "components/shared/FormElements";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import {colors} from "config/StyleGuide";

// todo nir: fix color
export const AccountInput = styled(Input)`
  color: ${colors.tempGrayColor};
  border-color: ${({ invalid }) => (invalid ? "#ED1C24" : "#d3d9da")};
  border-width: 1px;
  border-style: solid;

  width: 330px;
  display: block;
  margin-bottom: 16px;
  &:first-child {
    margin-top: 16px;
  }
  ::-webkit-input-placeholder {
    color: #a9b4b8;
  }
`;

const AccountButton = css`
  width: 250px;
  padding: 0 20px;
  margin-right: 10px;
`;

export const AccountSubmitButton = styled(SubmitButton)`
  ${AccountButton};
`;

export const AccountCancelButton = styled(CancelButton)`
  ${AccountButton};
`;

export const AccountModalSubmitButton = styled(SubmitButton)` 
  padding: 0 20px;  
  margin-bottom: 16px;
`;

export const AccountModalCancelButton = styled(CancelButton)` 
  padding: 0 20px;  
  margin-bottom: 16px;
`;

export const EditProfileInput = styled(Input)`
  border: 1px solid ${colors.gray};
  color: ${colors.warmGraphite};
  letter-spacing: 0.02em;
  font-family: SlateProBook;
  font-size: 14px;
  display: block;
  ::-webkit-input-placeholder {
    color: #a9b4b8;
  }
`;

export const SuccessLabel = styled.div`
  color: ${colors.green};
  font-size: 14px;
  font-family: SlateProRegular;
`;

export const ErrorLabel = styled.div`
  color: ${colors.errorRed};
  font-size: 14px;
  font-family: SlateProRegular;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const AccountLabel = styled.div`
  color: ${colors.warmGraphite};
  letter-spacing: 0.02em;
  font-family: SlateProRegular;
  font-size: 16px;
`;
