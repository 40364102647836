import {useEffect, useLayoutEffect, useState} from "react";
import {mediaRangeWithinPersonAgeRange} from "../services/filters-utils";
import {useResource} from "./useResource";

export function useStagesWithinRange(ageRange) {
  const [stagesWithinRange, setStagesWithinRange] = useState(ageRange || []);

  useEffect(() => {
    if(ageRange) {
      setStagesWithinRange(mediaRangeWithinPersonAgeRange(ageRange));
    }
    return () => setStagesWithinRange([]);
  }, [ageRange]);

  return stagesWithinRange;
}
