import React from "react";
import {BodyCopy, H1} from "components/shared/style-guide/typography";
import styled from "@emotion/styled";
import {size} from "lodash/fp";
import {colors} from "config/StyleGuide";
import clanHeader from "./images/Ind_Clan_Header.jpg";

const ClanHeader = ({ clan }) => (
  <Wrapper>
    <div className="col-4">
      <H1>{` The ${clan.display_name} Clan`}</H1>
      <NumPeople>{size(clan.people)} People</NumPeople>
      <BodyCopy>{clan.caption}</BodyCopy>
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  background: url(${clanHeader});
  height: 375px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.warmGraphite};
`;

const NumPeople = styled.div`
  margin: 26px 0;
  font-family: SlateProMedium;
  letter-spacing: 0.15em;
`;

export default ClanHeader;
