import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import StripLegend, {Section} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";

const FILES_IDS = [2844, 2935];

const SoldiersStrip = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1] = Files;

  return (
    <StripContainer backgroundColor="#BE9E88">
      <MediaViewer />
      <RowOverlay>
        <div className="col-1 offset-10" style={{ marginTop: "535px" }}>
          <StripLegend title="Soldiers" color={colors.white} anchor={"soldiers"}>
            <Section>
              <div>left:</div>
              <div>Jona Dresner</div>
              <div>Austro-Hungarian Army</div>
            </Section>
            <Section>
              <div>right:</div>
              <div>Bernie Gold</div>
              <div>U.S. Army</div>
            </Section>
          </StripLegend>
        </div>
        <span id={"soldiers"}/>
      </RowOverlay>
      <RowOverlay>
        <div className="col-6 offset-3" style={{ marginTop: "105px" }}>
          <ImagesWrapper>
            <div style={{ marginRight: "75px" }}>
              <div style={{ width: "280px" }}>
                <File0 />
              </div>
            </div>
            <div style={{ marginTop: "70px" }}>
              <File1 width="350px" height="440px" />
            </div>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export default SoldiersStrip;
