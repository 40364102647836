import React, {useState} from "react";
import styled from "@emotion/styled";
import {isInteger} from "lodash/fp";
import Thumbnail from "../../shared/Thumbnail";
import MediaThumbnailMeta from "../../shared/MediaThumbnailMeta";
import MediaViewerComponent from "../../shared/media-viewer/MediaViewer";

const SatchelCollectionThumbnails = ({ mediaFiles }) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  return (
    <>
      <MediaViewerComponent
        key={isInteger(currentFileIndex) ? currentFileIndex : "noIndexKey"}
        mediaFiles={mediaFiles}
        currentFileIndex={currentFileIndex}
        onCloseModal={() => setCurrentFileIndex(null)}
      />
      <ThumbnailsWrapper>
        {mediaFiles.map((item, idx) => (
          <ItemContainer key={item.id} onClick={() => setCurrentFileIndex(idx)}>
            <Thumbnail file={item} />
            <MediaThumbnailMeta file={item} />
          </ItemContainer>
        ))}
      </ThumbnailsWrapper>
    </>
  );
};

const ThumbnailsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ItemContainer = styled.div`
  margin-bottom: 60px;
  text-decoration: none;
  color: #000;
  flex: 1;
`;

export default SatchelCollectionThumbnails;
