import React from "react";
import {Link} from "react-router-dom";
import styled from "@emotion/styled";
import {colors} from "../../config/StyleGuide";
import logoMark from './images/T3T-Logo-beta.png';

const Logo = () => <StyledLogo to="/">
  <img src={logoMark} alt=""  />
</StyledLogo>;

const StyledLogo = styled(Link)`
  text-decoration: none;
  color: ${colors.coolGray};
  font-size: 12px;
  display: inline-block;
  margin-right: 60px;  
`;

export default Logo;
