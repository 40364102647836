import {useEffect, useState} from "react";
import apiRequest from "../services/api";

/**
 * Returns a single object for an entity
 *
 * @param path
 * @returns {any[]}
 */
export function useResource({ path }) {
  const [resource, setResource] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getResource = async query => {
    const url = query ? `/${path}?q=${query}` : `/${path}`;
    setIsLoading(true);
    const apiResponse = await apiRequest({ path: url });
    setIsLoading(false);
    setResource(apiResponse.data);
  };

  useEffect(() => {
    getResource("");
  }, [path]);

  return [resource, isLoading];
}
