import React from "react";
import {PageContent, PageRow, Row} from "../../shared/Layouts";
import SearchResultsCategorySelection from "./shared/SearchResultsCategorySelection";
import {useSearch} from "../../../hooks/useSearch";
import {useCollection} from "../../../hooks/useCollection";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import PeopleInkyGrid from "../people/shared/PeopleInkyGrid";
import Filters from "../../shared/filters/Filters";
import PeopleFilters from "../people/PeopleFilters";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import {noop} from "../../../services/utils";

const PeopleSearchResults = ({ location, resultByTypeCount }) => {
  const { searchQuery, longQuery } = useSearch(location.search);

  const {
    clearFilter,
    setFilter,
    isActiveFilter,
    resetFilters,
    facetFilters,
    facetsQueryString
  } = useFacetsFilters(noop);

  const query = [facetsQueryString, `query=${longQuery || searchQuery}`].join("&");

  const { collection: people, loadMore, hasMore } = useCollection(
    `/people/search`,
    query
  );

  return (
    <>
      <SearchResultsCategorySelection category={"people"} query={searchQuery} resultByTypeCount={resultByTypeCount}/>

      <PageRow>
        <div className="col-2 pr-0">
          <Filters
            values={facetFilters}
            onClear={clearFilter}
            onReset={resetFilters}
          />

          <PeopleFilters
            people={people}
            onAdd={setFilter}
            onClear={clearFilter}
            isActive={isActiveFilter}
          />
        </div>

        <PageContent>
          <Row>
            {people && !!people.length && (
              <ScrollableInfiniteScroll
                key={query}
                hasMore={hasMore}
                loadMore={loadMore}
                useWindow={true}
              >
                <PeopleInkyGrid people={people} col={4} />
              </ScrollableInfiniteScroll>
            )}
          </Row>
        </PageContent>
      </PageRow>
    </>
  );
};

export default PeopleSearchResults;
