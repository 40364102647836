import React from "react";
import {
  PageBackground,
  PageContainer,
  PageContent,
  PageRow,
  PageSideBar,
  Row,
  RowOverlay
} from "components/shared/Layouts";
import {useBreadCrumbs} from "../../../hooks/useBreadCrumbs";
import ClansInkyGrid from "./shared/ClansInkyGrid";
import {useCollection} from "hooks/useCollection";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import styled from "@emotion/styled";
import clansHeaderImage from "./images/clan_header.png";
import AutoComplete from "../../shared/auto-complete/AutoComplete";
import {fetchSuggestions} from "../../../services/fetch-suggestions";
import {useQueryFilter} from "../../../hooks/filters/useQueryFilter";

const Clans = ({ location }) => {
  useBreadCrumbs({ label: "Clans", path: location.pathname });

  const { setSearchQuery, searchQueryString } = useQueryFilter();

  const { collection: clans, loadMore, hasMore } = useCollection(
    "/clans",
    searchQueryString
  );

  return (
    <PageBackground>
      <PageContainer>
        <Row>
          <Col className="col-8 offset-2">
            <Image src={clansHeaderImage} />
          </Col>
        </Row>
        <RowOverlay>
          <Col className="col-4 offset-4">
            <SearchWrapper>
              <AutoComplete
                fetchSuggestions={fetchSuggestions("clans/autocomplete")}
                labelKey="display_name"
                onSubmit={setSearchQuery}
                placeholder="Search all clans"
              />
            </SearchWrapper>
          </Col>
        </RowOverlay>
        <PageRow>
          <PageSideBar />
          <PageContent>
            <Row>
              {clans && !!clans.length && (
                <ScrollableInfiniteScroll
                  key={searchQueryString}
                  hasMore={hasMore}
                  loadMore={loadMore}
                  useWindow={true}
                >
                  <ClansInkyGrid clans={clans} col={4} />
                </ScrollableInfiniteScroll>
              )}
            </Row>
          </PageContent>
          <PageSideBar />
        </PageRow>
      </PageContainer>
    </PageBackground>
  );
};

const Col = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const SearchWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 160px;
  left: 0;
  z-index: 1000;
`;

export default Clans;
