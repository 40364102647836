import React from "react";
import styled from "@emotion/styled";
import family from "./shared/family_in.png";
import {PageContainer} from "../../shared/Layouts";
import Header from "../../shared/header/Header";
import {Description, HeroTitle} from "../../shared/style-guide/typography";
import Footer from "../../shared/Footer";

const PendingEmailConfirmation = () => (
  <>
    <Header />
    <PageContainer>
      <PendingContent>
        <HeroTitle>Your'e almost done!</HeroTitle>
        <br />
        <br />
        <Description>
          Check your email for a link to complete your application. <br />
          You should receive the email within 24 hours.
        </Description>
      </PendingContent>
      <StickyImage src={family} />
    </PageContainer>
    <Footer />
  </>
);

const PendingContent = styled.div`
  margin: 100px 0 80px 0;
  text-align: center;
`;

const StickyImage = styled.img`
  width: 1400px;
`;

export default PendingEmailConfirmation;
