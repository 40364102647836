import React, {useContext} from "react";
import styled from "@emotion/styled";
import family from "./shared/family_in.png";
import {PageContainer} from "../../shared/Layouts";
import Header from "../../shared/header/Header";
import {Description, HeroTitle} from "../../shared/style-guide/typography";
import Footer from "../../shared/Footer";
import {AuthContext} from "../../context-providers/Auth";

const PendingApproval = () => {
  const currentUser = useContext(AuthContext);

  console.log(currentUser);
  
  return (
      <>
        <Header />
        <PageContainer>
          <PendingContent>
            <HeroTitle>Thank you for your application!</HeroTitle>
            <br />
            <br />
            <Description>
              You should receive an email regarding your application status within a
              few days.
            </Description>
          </PendingContent>
          <StickyImage src={family} />
        </PageContainer>
        <Footer />
      </>
  )
};

const PendingContent = styled.div`
  margin: 100px 0 80px 0;
  text-align: center;
`;

const StickyImage = styled.img`
  width: 1400px;
`;

export default PendingApproval;
