import {get} from "lodash/fp";
import noImageUrl from "components/shared/images/no-image.png";

export const getFileType = file => get("media_type.id", file);

export const getThumbnailUrl = file => {
  const fileTypeId = getFileType(file);

  switch (fileTypeId) {
    case 5:
      // PDF
      return file.pdf_thumbnail || noImageUrl;
    case 4:
      // video
      return file.pdf_thumbnail || noImageUrl;
    // Image or arcgis
    case 6:
    case 2:
    default: {
      return file.thumbnail_url || noImageUrl;
    }
  }
};

export const getMediaFileUrl = file => {
  const fileTypeId = getFileType(file);

  switch (fileTypeId) {
    //arc_gis
    case 6:
      return file.arcgis_url;
    case 4:
      // video
      return file.video_url || noImageUrl;
    case 5:
      // PDF
      return file.pdf_url || noImageUrl;
    // Image
    case 2:
    default: {
      return file.url || noImageUrl;
    }
  }
};

/**
 * add cloudinary transformation params
 * to create a file that fit 386px width (for preview)
 * @param file
 */
export const getPreviewUrl = file => {
  const fileTypeId = getFileType(file);

  switch (fileTypeId) {
    case 4:
    case 5:
      // PDF or Video
      return file.pdf_thumbnail || noImageUrl;
    //  the default is image - which used for arcgis as well
    case 2:
    case 6:
    default: {
      return file.url || noImageUrl;
      // if (file.url) {
      //   const spittedUrl = file.url.split("/");
      //   spittedUrl.splice(spittedUrl.length - 2, 0, "w_500,c_fit");
      //   return spittedUrl.join("/");
      // } else {
      //   return noImageUrl;
      // }
    }
  }
};

export const getFileShareUrl = file => {
  const baseUrl = window.location.origin;
  return `${baseUrl}/media/${file.id}`;
};
