import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {Date, Description} from "components/shared/style-guide/typography";
import ButtonWithArrow from "components/shared/ButtonWithArrow";

const CollectionSearchResultsListItem = ({
  title,
  description,
  img,
  startYear,
  endYear
}) => (
  <Wrapper>
    {img ? <Image src={img} /> : <ImagePlaceholder />}
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    <Date>
      {startYear}
      {endYear && `-${endYear}`}
    </Date>
    <Description>{description}</Description>

    <ColectionButtonWrapper>
      <ButtonWithArrow label="Go to collection" />
    </ColectionButtonWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  color: ${colors.warmGraphite};
  margin-bottom: 90px;
`;

const TitleWrapper = styled.div`
  margin: 20px 0 10px;
`;

const ColectionButtonWrapper = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  font-family: SangBleuVersaillesRegular;
  font-size: 40px;
  letter-spacing: 0.02em;
  line-height: 44px;
  word-break: break-word;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const ImagePlaceholder = styled.div`
  border-top: 4px solid ${colors.warmGraphite};
  width: 100%;
  margin-bottom: 50px;
`;

export default CollectionSearchResultsListItem;
