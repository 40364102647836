import styled from "@emotion/styled";
import Icon from "components/shared/Icon";
import {colors} from "config/StyleGuide";
import React, {useEffect, useRef, useState} from "react";
import BirthdayPicker from "../../../../shared/BirthdayPicker";
import SocialMediaLinks from "components/shared/SocialMediaLinks";
import {EditProfileInput} from "components/pages/members/account/shared/AccountFormElements";
import {EditFormLabel, FormLabel} from "../../../../shared/FormElements";

const EMPTY_SOCIAL_MEDIA_ENTRY = {type: "website", url: ""};

const ProfileAccountForm = ({currentUser, onFormChange, FieldWrapper}) => {

  const middleNameInputRef = useRef();

  const [accountForm, setAccountForm] = useState({
    ...currentUser,
    social_links: Array.isArray(currentUser.social_links) ? currentUser.social_links : [EMPTY_SOCIAL_MEDIA_ENTRY]
  });

  const updateForm = (partialAccountData) => {
     setAccountForm({ ...accountForm, ...partialAccountData })
  };

  const handleBirthDate = (birthDate) => {
    if(birthDate) {
      setAccountForm({
        ...accountForm, birth_date: birthDate.toString()
      })
    }
  };

  const handleSocialLinks = (webLinks) => {
    setAccountForm({
      ...accountForm,
      social_links: webLinks
    });
  };

  const onAddSocialMediaLinkClick = () => {
    setAccountForm({
      ...accountForm,
      social_links: [...accountForm.social_links, EMPTY_SOCIAL_MEDIA_ENTRY]
    });
  };

  const handleMiddleNameChange = (evt) => {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      middleNameInputRef.current.blur()
    }
  }

  useEffect(() => {
    onFormChange(accountForm)
  },[accountForm]);

  return (
      <Wrapper>
        <FieldWrapper label="name">
          <FullLineWrapper>
            <FirstNameWrapper>
              <EditFormLabel required={true}>first name</EditFormLabel>
              <EditProfileInput readOnly={true} value={accountForm.first_name}/>
            </FirstNameWrapper>
            <MiddleNameWrapper>
              <EditFormLabel>middle name</EditFormLabel>
              <EditProfileInput autoFocus={true}
                                ref={middleNameInputRef}
                                value={accountForm.middle_name}
                                onChange={(e) => updateForm({ middle_name: e.target.value })}
                                placeholder="middle name"
                                onKeyDown={handleMiddleNameChange}
              />
            </MiddleNameWrapper>
          </FullLineWrapper>
          <FullLineWrapper>
            <EditFormLabel required={true}>last name</EditFormLabel>
            <EditProfileInput readOnly={true} value={accountForm.last_name} placeholder="last name"/>
          </FullLineWrapper>
        </FieldWrapper>
        <FieldWrapper label="birthday">
          <BirthdayPicker onChange={handleBirthDate} birthDate={accountForm.birth_date}/>
        </FieldWrapper>
        <FieldWrapper label="social media / personal websites">
          <SocialMediaLinks links={accountForm.social_links}
                            onChange={handleSocialLinks}
          />
          <AddSocialLinkButton onClick={onAddSocialMediaLinkClick}>
            <AddSocialLinkIcon type="Attachment" size={14}/>
            Add an additional link
          </AddSocialLinkButton>
        </FieldWrapper>
      </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

const FirstNameWrapper = styled(FieldWrapper)`
  flex: 1;
`;

const MiddleNameWrapper = styled(FieldWrapper)`
  flex: 1;
`;

const FullLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;



const AddSocialLinkButton = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-family: SlateProRegular;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: ${colors.coolGray};
  user-select: none;
`;

const AddSocialLinkIcon = styled(Icon)`
  margin-right: 10px;
`;

export default ProfileAccountForm;
