import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import Button from "components/shared/Button";
import Icon from "components/shared/Icon";

export const FormActionsContainer = styled.div`
  margin: 30px 0;
`;

export const FormWrapper = styled.form`
  width: 100%;
`;

export const FormGroup = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormLabel = styled.div`
  font-family: SlateProRegular;
  color: ${colors.coolGray};
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:after {
    content: ${({ required }) => (required ? "*" : "")};
  }  
`;

export const EditFormLabel = styled(FormLabel)`
  margin-bottom: 10px;
`;

export const TextArea = styled.textarea`
  font-family: SlateProRegular, sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  border-color: ${({ invalid }) => (invalid ? `${colors.errorRed}` : "none")};
  border-width: ${({ invalid }) => (invalid ? "1px" : "0")};
  color: ${colors.warmGraphite};

  ::-webkit-input-placeholder {
    color: ${colors.placeholderGray};
  }
  padding: 10px 22px;
  border-radius: 19px;
  resize: none;
`;

export const Input = styled.input`
  font-family: SlateProRegular, sans-serif;
  font-size: 16px;

  height: 38px;
  width: ${({ width }) => width || "100%"};

  letter-spacing: 0.02em;
  border-radius: 19px;

  border-color: ${({ invalid }) => (invalid ? `${colors.errorRed}` : "none")};
  border-width: ${({ invalid }) => (invalid ? "1px" : "0")};

  padding-left: 22px;

  outline: none;
  background-color: ${({ bgColor = `${colors.white}` }) => bgColor};

  color: ${colors.warmGraphite};

  ::-webkit-input-placeholder {
    color: ${colors.placeholderGray};
  }
`;

export const Select = styled.select`
  font-family: SlateProBook;
  -webkit-appearance: none;
  appearance: none;
  padding: 10px 20px 10px 15px;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  background-color: transparent;
`;

const SelectIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotateZ(90deg);
  pointer-events: none;
`;

// todo nir: fix color
const StyledSelectWrapper = styled.div`
  position: relative;
  height: 40px;
  background-color: ${colors.white};
  border-radius: 0;
  border-color: #d3d9da;
`;

// todo nir : add real arrow icon (the 'Arrow' icon isn't the same as the design for the form input)
export const SelectWrapper = ({ children }) => (
  <StyledSelectWrapper>
    <SelectIcon type="NextActive" size={20} />
    {children}
  </StyledSelectWrapper>
);

export const Option = styled.option``;

// used on auth pages
export const SemiTransparentInput = styled(Input)`
  background-color: rgba(255, 255, 255, 0.3);
  color: ${colors.white};

  ::-webkit-input-placeholder {
    color: ${colors.white};
  }
`;

// todo: move to a separate file with all the rest of the buttons?
export const BaseButton = styled(Button)`
  font-family: SlateProRegular, sans-serif;
  font-size: 10px;
  letter-spacing: 0.25em;
  height: 38px;

  min-width: 200px;
  width: 100%;

  border-radius: 19px;
  border: none;
  text-transform: uppercase;

  padding: 0 40px;
  color: ${colors.white};

  background-color: ${({ disabled }) =>
    disabled ? colors.cornFlower : colors.navy};

  user-select: none;

  &:hover {
    background-color: ${colors.sapphire};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  color: ${({ disabled }) => (disabled ? colors.stone : colors.warmGraphite)};

  background-color: ${({ disabled }) =>
    disabled ? colors.disabledStone : colors.stone};

  &:hover {
    background-color: ${colors.coolGray};
  }
`;

export const SubmitButton = styled(BaseButton)`
  background-color: ${colors.navy};
  color: ${colors.white};
`;

// todo nir: fix colors
// todo eilam: should it be part of the popup component?
export const CancelButton = styled(BaseButton)`
  background-color: #dce0e1;
  color: #30302e;
  &:hover {
    color: ${colors.white};
  }
`;

export const ErrorMessage = styled.div`
  font-family: SlateProRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: ${colors.errorRed};
  margin-top: 10px;
`;

export const Space = styled.div`
  height: ${({ value }) => value || "5px"};
`;
