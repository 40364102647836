import React from "react";
import styled from "@emotion/styled";

const StaticPageHeader = ({ children }) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  margin: 80px 0;
  text-align: center;
`;

export default StaticPageHeader;
