import {useContext, useEffect} from "react";
import {BreadcrumbsContext} from "../components/shared/BreadcrumbsProvider";

export function useBreadCrumbs({ label, path }) {
  const { appendPath } = useContext(BreadcrumbsContext);

  let displayLabel = null;

  // by default we provide the entity as a label. sometimes we need pass a string
  if (typeof label === "string") {
    displayLabel = label;
  } else if (label) {
    displayLabel = label.display_name || label.title;
  }

  useEffect(() => {
    if (!label) return;
    appendPath({ label: displayLabel, path });
  }, [label]);
}
