import React from "react";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import StripContainer from "./StripContainer";
import {RowOverlay} from "../../../shared/Layouts";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";
import {colors} from "../../../../config/StyleGuide";

const FILES_IDS = [2857, 2858];

const WomanAndChildStrip = ({children}) => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1] = Files;

  return (
    <StripContainer >
        <span id={"womanAndChild"}/>
      <MediaViewer />
      <RowOverlay>
        <div className="col-1" style={{ marginTop: "250px" }} >
          <StripLegend title="Woman and Child" color={colors.warmGraphite} anchor="womanAndChild">
            <Section>
              <div>left:</div>
              <div>Norma (27 yrs old) with Ben (her son)</div>
              <YearLabel>1958</YearLabel>
            </Section>
            <Section>
              <div>right:</div>
              <div>Norma (66 yrs old) with Thomas (her grandson)</div>
              <YearLabel>1997</YearLabel>
            </Section>
          </StripLegend>
        </div>
      </RowOverlay>
      <RowOverlay>
        <div className="col-8" style={{ marginTop: "70px", position: "absolute", right: 118 }}>
          <ImagesWrapper>
            <div style={{ width: "550px" }}>
              <File0 />
            </div>
            <div style={{ width: "375px" }}>
              <File1 />
            </div>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>

  );
};

const ActionsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default WomanAndChildStrip;
