import {STAGES} from "../config/constants";

export const getPronoun = gender => {
  switch (gender) {
    case "Male":
      return "His";
    case "Female":
      return "Her";
    default:
      return "Their";
  }
};

// extract the birth year and death year (look for 4 digits in the string )
export function getBirthAndDeathYears({date_of_birth, date_of_death}) {
  const birthYear = date_of_birth ? date_of_birth.match(/\b\d{4}\b/)[0] : "0";
  const deathYear = date_of_death ? date_of_death.match(/\b\d{4}\b/)[0] : String(new Date().getFullYear());

  return {birthYear: parseInt(birthYear), deathYear: parseInt(deathYear)};
}

export function mediaRangeWithinPersonAgeRange(ageRange){
  return STAGES.filter(stage => {
    const epochRange = getEpochMinAndMaxFromStrings(stage.value);

    if(stage.children && stage.children.length > 0) {
      stage.children = stage.children.filter(childStage => {
        const epochRange = getEpochMinAndMaxFromStrings(childStage.value);
        return stageWithinRange(ageRange, epochRange);
      });
    }

    return stageWithinRange(ageRange, epochRange);
  })
}

function stageWithinRange(ageRange, epochRange){
  const {min, max} = epochRange;
  const resultRange = range(min, max);

  return resultRange.some( value => ageRange.range.includes(value) )
}


function withinMaxAnMin(ageRange, epochRange) {
  // epoch should start after the person has born
  return epochRange.min >= ageRange.from_age && !(ageRange.to_age < epochRange.min)
}


export function formatValidDates(dates) {
  const mappedDates = dates.map(d => {
    const year = d.match(/\b\d{4}\b/);
    if(year) {
      return parseInt(year[0])
    }
  }).sort((a, b) => a - b);

  const {0: min, length, [length - 1]: max} = mappedDates;
  return range(min, max || 3000);
}

// helper for extracting a range from epoch string
// "1945 - 1967 " ---> [1945...1967]
export function convertEpochStringIntoRange(rangeString) {
  const [start, end] = rangeString.replace(/ /g, '').split('-').map(value => parseInt(value));
  return range(start, end);
}

function getEpochMinAndMaxFromStrings(rangeString) {
  const [start, end] = rangeString.replace(/ /g, '').split('-').map(value => parseInt(value));
  return {min:start, max:end}
}

export function getEpochStartAndEndYears(rangeString) {
  const [epochStart, epochEnd] = rangeString.replace(/ /g, '').split('-').map(value => parseInt(value));
  return {epochStart, epochEnd};
}

// todo: refactor after POC. No need to generate massive arrays for range checking
export function range(start, end) {
  let list = [];
  for (let i = start; i <= end; i++) {
    list.push(i);
  }
  return list;
}

// return true if one of the values from the sourceRange
// exist in the targetRange
export function withinRange(sourceRange, targetRange) {
  return sourceRange.some(value => targetRange.indexOf(value) > -1)
}

export function sortByName(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if(nameA < nameB) return -1;
  if(nameA > nameB) return 1;

  return 0;
};

export function sortPeopleByLastName(people) {
  return people.sort((a, b) => {
    if(a.last_name < b.last_name ) return -1;
    if(a.last_name > b.last_name   ) return 1;
    return 0;
  })
}
