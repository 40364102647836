import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {Date, Description} from "components/shared/style-guide/typography";
import {UnstyledLink} from "../../shared/UnstyledLink";
import Rule from "../../shared/Rule";
import ButtonWithArrow from "components/shared/ButtonWithArrow";

const FeaturePlace = ({
  title,
  subtitle,
  img,
  startYear,
  endYear,
  collectionId
}) => (
  <Wrapper to={`/collection/${collectionId}`}>
    <Image src={img} />
    <ContentWrapper>
      <HoverRule height={"3"} color={colors.warmGraphite} />
      <Title>{title}</Title>
      <Date>
        {startYear}
        {endYear && `-${endYear}`}
      </Date>
      <Description>{subtitle}</Description>
      <ColectionButtonWrapper>
        <ButtonWithArrow label="Go to collection" />
      </ColectionButtonWrapper>
    </ContentWrapper>
  </Wrapper>
);

const HoverRule = styled(Rule)`
  margin-bottom: 34px;
  opacity: 0;
  width: 98px;
`;

const Wrapper = styled(UnstyledLink)`
  color: ${colors.warmGraphite};
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-decoration: none;
`;

const ColectionButtonWrapper = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 290px;
  height: auto;
`;

const Title = styled.div`
  font-family: SangBleuVersaillesRegular;
  font-size: 40px;
  margin-bottom: 20px;
  line-height: 46px;
`;

const ContentWrapper = styled.div`
  margin-top: 110px;
  margin-left: 35px;
  padding-top: 11px;

  &:hover * {
    opacity: 1;
  }
`;

export default FeaturePlace;
