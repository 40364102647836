import React from 'react';
import SideMenu from "../../shared/SideMenu";
import MediaFilesRelatedPeopleFilter from "./filtres/MediaFilesRelatedPeopleFilter";
import MediaFilesRelatedPlacesFilter from "./filtres/MediaFilesRelatedPlacesFilter";

const MediaFilesSearchResultsFilters = (props) => {
  return (
      <SideMenu>
        <MediaFilesRelatedPeopleFilter {...props} />
        <MediaFilesRelatedPlacesFilter {...props} />
      </SideMenu>
  );
};

export default MediaFilesSearchResultsFilters;
