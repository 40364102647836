import React from "react";
import {PageContainer, PageRow} from "components/shared/Layouts";
import styled from "@emotion/styled";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import ViewProfile from "components/pages/members/profile/ViewProfile";
import {colors} from "config/StyleGuide";
import {useResource} from "../../../../hooks/useResource";
import MemberCollection from "./MemberCollection";


const Member = ({ location, match }) => {
  const memberId = match.params.id;
  const [member] = useResource({ path: `members/${memberId}` });

  useBreadCrumbs({ label: "Member Profile", path: location.pathname });

  if (!member) return null;

  return (
    <PageContainer>
      <PageRow>
        <Wrapper className="col-8 offset-2">
          <ViewProfile currentUser={member} />
        </Wrapper>
      </PageRow>

      <PageRow>
        <div className="col-8 offset-2">
          <MemberCollection member={member} />
        </div>
      </PageRow>

    </PageContainer>
  );
};

const Wrapper = styled.div`
  background-color: ${colors.white};
  margin-bottom: 25px;
`;

export default Member;
