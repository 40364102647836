import React from "react";
import {UnstyledLink} from "../../../shared/UnstyledLink";
import PersonInky from "./PersonInky";
import styled from "@emotion/styled";

const PeopleInkyGrid = ({ people, col = 4 }) => {
  return (
    <InkyContainer>
      {(people || []).map((person, idx) => (
        <div className={`col-${col}`} key={person.id + idx}>
          <UnstyledLink to={`/people/${person.id}`}>
            <PersonInky person={person} small={true} />
          </UnstyledLink>
        </div>
      ))}
    </InkyContainer>
  );
};

const InkyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export default PeopleInkyGrid;
