/* eslint-disable no-unused-vars */
import React, {useState} from "react";

import {
  BaseButton,
  ErrorMessage,
  FormActionsContainer,
  FormGroup,
  FormWrapper,
  SemiTransparentInput,
  Space
} from "../../shared/FormElements";
import {Col12, Col4, Col8} from "../../shared/Grid";
import AuthHeader from "./shared/AuthHeader";
import styled from "@emotion/styled";
import FieldSeparator from "./shared/FieldSeparator";
import {withRouter} from "react-router-dom";
import {PageContainer, Row} from "components/shared/Layouts";
import WelcomeMessage from "./shared/styled";
import {Description, TextLink} from "../../shared/style-guide/typography";
import {colors} from "../../../config/StyleGuide";
import apiRequest from "../../../services/api";
import loginVideo from "./shared/t3t_login_vid.mp4";
import {VideoBackground} from "./shared/VideoBackground";

const ResetPassword = ({history}) => {
  const [email, setEmail] = useState();
  const [error, setError] = useState([]);
  const [message, setMessage] = useState([]);

  const recoverPassword = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    // email is required
    if(!email) {
      setError("Email is required");
      return;
    }

    // redirect_url is required by the server
    const response = await apiRequest({
      path: "/auth/password",
      method: "POST",
      data: {email, redirect_url: `${process.env.REACT_APP_REDIRECT_URL_HOST}/new-password`}
    });

    // in case of error
    if(response.status !== 200) {
      return setError(response.response.data.error[0]);
    }

    // in case of server validation message
    if(response.message) {
      return setMessage(response.message);
    }

    history.push("/password-reset");
  };

  return (
      <>
        <VideoBackground src={loginVideo} type="video/mp4" autoPlay muted loop/>
        <PageContainer>
          <AuthHeader/>
          <div>
            <Row>
              <div className="col-3">
                <WelcomeMessage/>
                <Space value="40px"/>
                <FormWrapper onSubmit={recoverPassword}>
                  <Col12>
                    <FormGroup>
                      <FieldSeparator label="Password reset"/>
                      <ResetInstructions>
                        Please enter your email address and we'll send you
                        instructions to reset your password
                      </ResetInstructions>
                      <SemiTransparentInput
                          placeholder="Email Address"
                          onChange={({target}) => setEmail(target.value)}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </FormGroup>
                  </Col12>
                  <Col12>
                    <FormGroup>
                      <FormActionsContainer>
                        <Col8>
                          <BaseButton type={"button"} onClick={recoverPassword}>
                            SUBMIT
                          </BaseButton>
                        </Col8>
                        <CancelCol/>
                      </FormActionsContainer>
                      <TextLink to="/login">Cancel</TextLink>
                    </FormGroup>
                  </Col12>
                </FormWrapper>
              </div>
            </Row>
          </div>
        </PageContainer>
      </>
  );
};

const ResetInstructions = styled(Description)`
  color: ${colors.white};
  padding: 5px 0 15px 0;
`;

const CancelCol = styled(Col4)`
  padding-top: 10px;
`;

export default withRouter(ResetPassword);
