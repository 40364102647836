import React, {useContext} from "react";
import styled from "@emotion/styled";
import {MenuItem, MenuWrapper} from "./shared/HeaderMenu";
import apiRequest from "../../../services/api";
import {AuthContext} from "../../context-providers/Auth";

const ProfilePopup = ({ onClose }) => {
  const { setCurrentUser } = useContext(AuthContext);

  const signOut = async event => {
    event.preventDefault();

    const response = await apiRequest({
      path: "/auth/sign_out",
      method: "DELETE"
    });

    if (response.data.success) {
      setCurrentUser(null);
    }
  };

  return (
    <PopupMenuWrapper onClick={onClose} width={200} top={-20} right={15}>
      <ul>
        <MenuItem to="/profile">My Profile</MenuItem>
        <MenuItem to="/account">My Account</MenuItem>
        <MenuItem to="/logout" onClick={signOut}>
          Logout
        </MenuItem>
      </ul>
    </PopupMenuWrapper>
  );
};

const PopupMenuWrapper = styled(MenuWrapper)`
  top: 40px;
  right: 0;
`;

export default ProfilePopup;
