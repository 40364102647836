import React, {useState} from 'react';
import MediaViewer from "../../../shared/media-viewer/MediaViewer";
import styled from "@emotion/styled";
import {colors} from "../../../../config/StyleGuide";
import Thumbnail from "../../../shared/Thumbnail";
import MediaThumbnailMeta from "../../../shared/MediaThumbnailMeta";
import {useCollection} from "../../../../hooks/useCollection";

// todo: if collection is private show the words "Nir Kaufman's collection - Private"
const MemberCollection = ({ member }) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  const { first_name, last_name } = member;

  const { collection: mediaFiles } = useCollection(
      "/members/media",
      `&member_id=${member.id}`
  );

  if(!mediaFiles) return null;

  if(mediaFiles.length === 0) {
    return (
        <Wrapper>
          <Header>
            <Title>{
              `${first_name} ${last_name}'s Collection - Private.`
            }</Title>
          </Header>
        </Wrapper>
    )
  }

  return (
      <>
        {currentFileIndex !== null && (
            <MediaViewer
                onCloseModal={() => setCurrentFileIndex(null)}
                mediaFiles={[]}
                currentFileIndex={currentFileIndex}
                hasMore={false}
            />
        )}

        <Wrapper>
          <Header>
            <Title>{
              `${first_name} ${last_name}'s Collection`
            }</Title>
          </Header>

          <MyMediaFilesPreview>
            {mediaFiles.map((mediaFile, mediaFileIndex) => (
                <ListItem
                    key={mediaFile.id}
                    onClick={() => setCurrentFileIndex(mediaFileIndex)}
                >
                  <Thumbnail
                      wide
                      key={mediaFile}
                      file={mediaFile}
                      isInMyCollection={true}
                  />
                  <MediaThumbnailMeta file={mediaFile} />
                </ListItem>
            ))}
          </MyMediaFilesPreview>

        </Wrapper>

      </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 300px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 3px solid ${colors.warmGraphite};
  color: ${colors.warmGraphite};
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: 36px;
`;

const MyMediaFilesPreview = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 75px;
  padding: 0 40px;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 390px;
`;

export default MemberCollection;
