import React, {useState} from "react";
import {UnstyledLink} from "components/shared/UnstyledLink";
import Slider from "react-slick";
import styled from "@emotion/styled";
import {get, map, min, size} from "lodash/fp";
import {colors} from "config/StyleGuide";
import SliderArrow from "../../shared/SliderArrow";

import manInky from "components/shared/images/default-man-inky.png";
import womanInky from "components/shared/images/default-man-inky.png";

const settings = {
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  draggable: false,
  arrows: false
};

const ClanMembersSlider = ({ clan }) => {
  const [sliderRef, setSliderRef] = useState({});

  const getDefaultInky = person => {
    if (person.inky.url) return person.inky.url;
    return person.gender === "Female" ? womanInky : manInky;
  };

  const slidesToShow = min([5, size(get("people", clan))]);

  return (
    <Wrapper>
      <LeftArrow>
        <SliderArrow left={true} skipSlide={sliderRef.slickPrev} />
      </LeftArrow>
      <Slider
        {...settings}
        slidesToShow={slidesToShow}
        ref={ref => setSliderRef(ref)}
      >
        {map(
          clanMember => (
            <UnstyledLink key={clanMember.id} to={`/people/${clanMember.id}`}>
              <ClanMemberWrapper>
                <MemberImage
                  src={getDefaultInky(clanMember)}
                  alt={clanMember.display_name}
                />
                <MemberName> {clanMember.display_name}</MemberName>
              </ClanMemberWrapper>
            </UnstyledLink>
          ),
          clan.people
        )}
      </Slider>
      <RightArrow>
        <SliderArrow skipSlide={sliderRef.slickNext} />
      </RightArrow>
    </Wrapper>
  );
};

const ClanMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const Wrapper = styled.div``;

const ArrowPosition = styled.div`
  position: absolute;
  cursor: pointer;
  top: 30%;
`;

const LeftArrow = styled(ArrowPosition)`
  left: -10%;
`;
const RightArrow = styled(ArrowPosition)`
  right: -10%;
`;

const MemberImage = styled.img`
  width: auto;
  height: 200px;
  margin-bottom: 20px;
`;

// todo : need styleguide - these props are taken from a different page stylegudie
const MemberName = styled.div`
  font-family: SangBleuVersaillesBook;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  text-transform: capitalize;
`;

export default ClanMembersSlider;
