import React from "react";
import {colors} from "config/StyleGuide";
import Piano from "components/pages/dashboard/Piano";
import NewsCarousel from "./NewsCarousel";
import MyCollectionCarousel from "./MyCollectionCarousel";
import Rule from "components/shared/Rule";
import {Space} from "components/shared/FormElements";
import Explore from "./Explore";
import {PageBackground, PageContainer, Row} from "components/shared/Layouts";
import FirstTimeUserPopup from "components/pages/dashboard/FirstTimeUserPopup";

const Home = () => {
  return (
    <PageBackground>
      <FirstTimeUserPopup />
      <PageContainer>
        <Space value="50px" />
        <div>
          <Row>
            <Piano />
          </Row>

          <Space value="50px" />

          <Row>
            <div className="col-8 offset-2">
              <NewsCarousel />
              <Rule height={1} color={colors.pink} />
            </div>
          </Row>

          <Space value="50px" />

          <Row>
            <div className="col-8 offset-2">
              <div style={{ width: 1000 }}>
                <MyCollectionCarousel />
              </div>
              <Space value="90px" />
              <Rule height={1} color={colors.pink} />
            </div>
          </Row>

          <Explore />
        </div>
      </PageContainer>
    </PageBackground>
  );
};

export default Home;
