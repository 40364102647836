import React from "react";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import {Row} from "components/shared/Layouts";
import {isEmpty} from "lodash/fp";
import MediaFiles from "components/shared/MediaFiles/MediaFiles";
import NoResults from "components/shared/NoResults";
import LoadingMediaCards from "../LoadingMediaCards";
import styled from "@emotion/styled";
import {colors} from "../../../config/StyleGuide";

const MediaFileGrid = ({
                         children,
                         mediaFiles,
                         hasMore,
                         loadMore,
                         loading,
                         isFiltersApplied,
                         reloading,
                       }) => {

  const renderNoResults = () => {
    const appliedfilter = isFiltersApplied ? isFiltersApplied() : {status: false, filterNames: []};


    if(!reloading && appliedfilter.status === false) {
      return (
          <NoResults>
            Sorry, at this time the archive does not have media for this subject.
          </NoResults>
      );
    }

    if(!reloading && appliedfilter.status === true && mediaFiles.length === 0) {
      return (
          <NoResults>
            Sorry, nothing in the archives has ALL the terms you requested:<br />
            <NoResultList>
              {appliedfilter.filterNames.map( (filterName, idx) => <li key={idx}><StyledSpan>•</StyledSpan> {filterName}</li> )}
            </NoResultList>
            Please try again.
          </NoResults>
      )
    }
  };

  return (
      <div className="col-7 offset-1 p-0">
        {mediaFiles.length > 0 && <Row>{children}</Row>}
        <Row>
          {loading ? (
              <Container>
                <LoadingMediaCards/>
              </Container>
          ) : !isEmpty(mediaFiles) ? (
              <ScrollableInfiniteScroll
                  hasMore={hasMore}
                  loadMore={loadMore}
                  useWindow={true}
              >
                <MediaFiles
                    mediaFiles={mediaFiles}
                    hasMore={hasMore}
                    loadMore={loadMore}
                />
              </ScrollableInfiniteScroll>
          ) : renderNoResults()}
        </Row>
      </div>
  );
};

const Container = styled.div`
  padding-bottom: 100px;
`;

const StyledList = styled.ol`
  list-style: lower-roman;
`;

const NoResultList = styled.ul`
  margin: 15px 0;


  li {
    font-family: SlateProBold,serif;
    font-size: 16px;
    padding: 10px 0;
    color: ${colors.warmGraphite};  
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
  margin-right: 15px ;
`

export default MediaFileGrid;
