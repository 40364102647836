export const ENTITIES = {
    PERSON: "person",
    EVENT: "event",
    LOCATION: "location",
    CHILD_LOCATION: "child_location",
    EPOCHS: "epochs",
    AGE: "age",
    MAJOR_EVENTS: "major_events",
    CLAN: "clan",
    STAGES: "stages",
    TOPICS: "topic"
};

export const EPOCHS = [
    {id: 0, name: "X - 1918", value: "0 - 1918"},
    {id: 2, name: "1919 - 1939", value: "1919 - 1939"},
    {id: 3, name: "1939 - 1945", value: "1939 - 1945" },
    {id: 4, name: "1945 - 1957", value: "1945 - 1957"},
    {id: 5, name: "1958 - 2000", value: "1958 - 2000"},
    {id: 6, name: "2000 - present", value: "2000 - 3000"}
];


export const STAGES = [
    {id: 1, name: "Baby", value: "0 - 1"},
    {id: 2, name: "Child", value: "2 - 11"},
    {id: 3, name: "Adolescent", value: "12 - 17"},
    {
        id: 4, name: "Adult", value:"18 - 999", children: [
            {id: 5, name: "18 - 35", value: "18 - 35"},
            {id: 6, name: "36 - 65", value: "36 - 65"},
            {id: 7, name: "66 +", value: "66 - 999"}
        ]
    },
];

export const GENRES = {
    video: {icon: "Video"},
    photo: {icon: "Photograph"},
    article: {icon: "Article"},
    artwork: {icon: "Artwork"},
    audio: {icon: "Audio Recording"},
    book: {icon: "Book"},
    blog: {icon: "Blog"},
    "e/letter": {icon: "Correspondence"},
    document: {icon: "Document"},
    "fam tree": {icon: "Family Tree"},
    "gov doc": {icon: "Government Document"},
    interview: {icon: "Document"},
    Manuscript: {icon: "Document"},
    map: {icon: "Map"},
    magazine: {icon: "Magazine"},
    newspaper: {icon: "Newspaper"},
    delete: {icon: "Photo Album"},
    postcard: {icon: "Postcard"},
    satchel: {icon: "Satchel"},
    link: {icon: "Website"},
    note: {icon: "Note"},
    "photo albm": {icon: "Photo Album"}
};

export const SEARCHABLE_TYPES = {
    MEDIA_FILE: "MediaFile",
    PERSON: "Person",
    PLACE: "Place",
    CLAN: "Clan",
    COLLECTION: "Collection"
};
