import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Auth from "./context-providers/Auth";

import Header from "./shared/header/Header";
import Footer from "./shared/Footer";

import Register from "./pages/auth/Register";
import PendingApproval from "./pages/auth/PendingApproval";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Clans from "./pages/clans/Clans";
import Members from "./pages/members/Members/Members";
import Member from "./pages/members/Member/Member";
import Profile from "./pages/members/profile/Profile";
import PiecesOfMind from "./pages/pieces-of-mind/PiecesOfMind";
import Account from "./pages/members/account/Account";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordConfirmation from "./pages/auth/ResetPasswordConfirmation";
import Clan from "./pages/clans/Clan";
import GraphicStories from "./pages/GraphicStories/GraphicStories";
import Places from "components/pages/places/Places";
import Place from "components/pages/places/Place";
import Events from "./pages/events/Events";
import Event from "./pages/events/Event";
import People from "./pages/people/People";
import Person from "./pages/people/Person";
import FAQ from "./pages/FAQ";
import MediaFile from "./pages/media-files/MediaFile";
import About from "./pages/About/About";
import Contact from "./pages/Contact";
import Viewfinder from "./pages/Viewfinder/Viewfinder";
import Satchels from "./pages/Satchels/Satchels";
import Collection from "./pages/collections/Collection";
import Satchel from "./pages/Satchel/Satchel";
import {PageBackground} from "./shared/Layouts";
import SearchResultsRouter from "components/pages/search-results/shared/SearchResultsRouter";
import EventSatchel from "./pages/events/EventSatchel";
import NewPasswordForm from "./pages/auth/NewPasswordForm";
import GenreCollection from "./pages/collections/GenreCollection";
import AllMediaFiles from "./pages/debug/AllMediaFiles";
import PendingEmailConfirmation from "./pages/auth/PendingEmailConfirmation";

const authRedirect = currentUser => {
  if (!currentUser || currentUser.active === false) {
    return (
      <>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/password" component={ResetPassword} />
          <Route path="/password-reset" component={ResetPasswordConfirmation} />
          <Route path="/new-password" component={NewPasswordForm} />
          <Redirect from="*" to="/login" />
        </Switch>
      </>
    );
  }

  if (currentUser && !currentUser.approved) {
    return (
      <>
        <Switch>
          {/*<Route path="/pending" component={PendingEmailConfirmation} />*/}
          <Route path="/pending" component={PendingApproval} />
          <Redirect from="*" to="/pending" />
        </Switch>
      </>
    );
  }

  if (currentUser && !currentUser.agreed_to_terms) {
    return (
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Redirect from="*" to="/" />
        </Switch>
    )
  }

  return (
    <PageBackground>
      <Header />
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path={"/search"} component={SearchResultsRouter} />

        <Route path={"/genre/:id"} component={GenreCollection} />

        <Route path="/clans" exact component={Clans} />
        <Route path="/clans/:id" exact component={Clan} />

        <Route path="/members" exact component={Members} />
        <Route path="/members/:id" exact component={Member} />

        <Route path="/events" exact component={Events} />
        <Route path="/events/satchel/:id" exact component={EventSatchel} />
        <Route path="/events/:id" exact component={Event} />

        <Route path="/profile" exact component={Profile} />
        <Route path="/account" exact component={Account} />

        <Route path="/places" exact component={Places} />
        <Route path="/places/:id" exact component={Place} />

        <Route path="/people" exact component={People} />
        <Route path="/people/:id" exact component={Person} />

        <Route path="/media/:id" exact component={MediaFile} />

        <Route path="/stories" exact component={GraphicStories} />
        <Route path="/pom" exact component={PiecesOfMind} />

        <Route path="/satchels" exact component={Satchels} />
        <Route path="/satchels/:id" exact component={Satchel} />

        <Route path="/collection/:id" exact component={Collection} />

        {/*<Route path="/milestones" exact component={LifeMilestones} />*/}
        {/*<Route path="/milestones/:id" exact component={LifeMilestones} />*/}

        <Route path="/faq" exact component={FAQ} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />

        <Route path="/viewfinder" exact component={Viewfinder} />

        <Route path="/all" exact component={AllMediaFiles} />
        <Redirect from="*" to="/" />
      </Switch>
      <Footer />
    </PageBackground>
  );
};

const App = () => {
  return <Auth>{authRedirect}</Auth>;
};

export default App;
