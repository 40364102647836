import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";

const NameInitialsCircle = ({ initials, imageSource, radius, fontSize }) => {
  return (
    <Circle radius={radius}>
      {imageSource ? (
        <ImageContent source={imageSource} />
      ) : (
        <TextContent fontSize={fontSize}>{initials}</TextContent>
      )}
    </Circle>
  );
};

NameInitialsCircle.defaultProps = {
  radius: 250,
  fontSize: 16
};

const Circle = styled.div`
  width: ${({ radius }) => radius}px;
  height: ${({ radius }) => radius}px;
  background-color: ${colors.stone};
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const TextContent = styled.div`
  font-family: SlateProRegular, sans-serif;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  letter-spacing: 0.04em;
  color: ${colors.gray};
  text-transform: uppercase;
`;

const ImageContent = styled.div`
  background: url(${({ source }) => source}) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

export default NameInitialsCircle;
