import styled from "@emotion/styled";
import defaultInky from "components/shared/images/default_clan_inky.png";
import {colors} from "config/StyleGuide";
import React from "react";

const ClanInky = ({ clan }) => {
  return (
    <ClanInkyContainer url={clan.inky || defaultInky}>
      <ClanName>{clan.display_name}</ClanName>
      <PeopleCount>{clan.people} people</PeopleCount>
    </ClanInkyContainer>
  );
};

const ClanInkyContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background: url(${({ url }) => url}) no-repeat;
  background-size: cover;
  color: ${colors.white};
  width: 240px;
  height: 340px;
  margin-bottom: 35px;
  box-shadow: none;
  transition: transform 0.2s ease-in-out;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    &:after {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &:after {
    transition: background-color 0.2s ease-in-out;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ClanName = styled.div`
  margin: 150px 0 75px;
  font-family: SangBleuVersaillesRegular;
  font-size: 26px;
  letter-spacing: 0.02em;
`;

const PeopleCount = styled.div`
  font-family: SlateProMedium;
  font-size: 10px;
  letter-spacing: 0.15em;
`;

export default ClanInky;
