import React from 'react';
import FilterMenuItem from "../../../shared/FilterMenuItem";
import Menu from "../../../shared/Menu";
import {ENTITIES} from "../../../../config/constants";

const ClanEventsFilter = ({clan, onAdd, onClear, isActive}) => {
  if(!clan.events || !clan.events.length) return null;

  const renderEvent = (event, idx) => {
    const eventFilter = {...event, entity: ENTITIES.EVENT};
    return (
        <FilterMenuItem
            key={event.id + idx}
            onAdd={onAdd}
            onClear={onClear}
            isActive={isActive}
            filter={eventFilter}
        />
    );
  };


  return (
      <Menu
          label={`Their Events`}
          itemsLength={clan.events.length}
      >
        {clan.events.map((event, idx) => renderEvent(event, idx))}
      </Menu>

  );
};

export default ClanEventsFilter;
