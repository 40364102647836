import React, {useContext, useState} from "react";
import {AuthContext} from "components/context-providers/Auth";
import apiRequest from "services/api";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import ProfileAccountForm from "./ProfileAccountForm";
import EditProfilePicture from "./EditProfilePicture";
import {CancelButton, FormWrapper, SubmitButton} from "components/shared/FormElements";
import {ProfileBox, ProfileBoxLeftSide, ProfileBoxRightSide, Title} from "../shared/ProfileBoxElements";
import ProfileFieldBox from "../shared/ProfileFieldBox";
import {getCurrentUserInitials} from "services/user-utils";

const EditProfile = ({ currentUser, onSave, onCancel }) => {
  const [accountDetails, setAccountDetails] = useState(currentUser);
  const [profilePicture, setProfilePicture] = useState(
      currentUser.profile_image_link
  );

  const { setCurrentUser } = useContext(AuthContext);

  const onEditProfileFormChange = formData => {
    setAccountDetails(formData);
  };

  const onChangeProfilePicture = profilePictureUrl => {
    setProfilePicture(profilePictureUrl);
  };

  const onSubmit = async event => {
    event.preventDefault();

    const data = {
      ...accountDetails,
      birth_date:accountDetails.birth_date.toString(),
      social_links: accountDetails.social_links.filter(link => link.url.length > 0),
      profile_image_link: profilePicture
    };

    await apiRequest({
      path: `/members/${currentUser.id}`,
      method: "PUT",
      data
    });


    setCurrentUser(data);
    return onSave({profilePicture, data});
  };

  return (
    <Wrapper onSubmit={onSubmit}>
      <ProfileHeader>
        <Title>member profile</Title>
        <EditProfileActions>
          <EditProfileCancelButton onClick={onCancel}>
            cancel
          </EditProfileCancelButton>
          <EditProfileSubmitButton onClick={onSubmit}>
            save changes
          </EditProfileSubmitButton>
        </EditProfileActions>
      </ProfileHeader>
      <EditProfileBox>
        <ProfileBoxLeftSide>
          <EditProfilePicture
            currentUser={currentUser}
            initials={getCurrentUserInitials(currentUser)}
            currentProfilePicture={profilePicture}
            onChangeProfilePicture={onChangeProfilePicture}
          />
        </ProfileBoxLeftSide>
        <ProfileBoxRightSide>
          <ProfileAccountForm
            currentUser={accountDetails}
            onFormChange={onEditProfileFormChange}
            FieldWrapper={ProfileFieldBox}
          />
        </ProfileBoxRightSide>
      </EditProfileBox>
    </Wrapper>
  );
};

const Wrapper = styled(FormWrapper)`
  padding: 15px;
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 15px;
  align-items: center;
`;

const EditProfileActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-beween;
`;

const EditProfileBox = styled(ProfileBox)`
  height: 490px;
  overflow-y: auto;
`;

const BaseButton = css`
  width: 180px;
`;

const EditProfileSubmitButton = styled(SubmitButton)`
  ${BaseButton}
`;

const EditProfileCancelButton = styled(CancelButton)`
  ${BaseButton};
  margin-right: 10px;
`;

export default EditProfile;
