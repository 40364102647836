import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import Rule from "components/shared/Rule";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {map} from "lodash/fp";
import {PageRow} from "components/shared/Layouts";
import SatchelCollectionThumbnails from "../Satchel/SatchelCollectionThumbnails";
import {H4} from "../../shared/style-guide/typography";

const GoToCollectionLink = ({ id }) => (
  <StyledLink to={`/events/${id}`}>go to collection ></StyledLink>
);

const EventSatchelCollections = ({ collections }) => {
  const counter = useRef(0);

  useEffect(() => {
    counter.current = 0;
  }, []);

  return (
    <>
      {map(collection => {
        counter.current = counter.current + 1;
        return (
          <Wrapper key={collection.id}>
            <PageRow>
              <div className="col-1 offset-1">
                <CollectionId>{("0" + counter.current).slice(-2)}</CollectionId>
              </div>
              <div className="col-3 offset-1">
                <CollectionTitle>{collection.name}</CollectionTitle>
              </div>
              <div className="col-5">
                <CollectionDescription>
                  {collection.description || <H4>No Description.</H4>}
                </CollectionDescription>
                <GoToCollectionLink id={collection.id} />
              </div>
            </PageRow>
            <PageRow>
              <div className="col-8 offset-3">
                {!collection.media_files.length ? (
                  <H4>No Media Files.</H4>
                ) : (
                  <SatchelCollectionThumbnails
                    mediaFiles={collection.media_files}
                  />
                )}
              </div>
            </PageRow>
            <PageRow>
              <div className="col-10 offset-1">
                <div style={{ margin: "40px 0" }}>
                  <Rule height={1} color={colors.reed} />
                </div>
              </div>
            </PageRow>
          </Wrapper>
        );
      }, collections)}
    </>
  );
};

const Wrapper = styled.div`
  color: ${colors.warmGraphite};
`;
const CollectionId = styled.div`
  font-size: 24px;
`;
const CollectionTitle = styled.div`
  font-size: 32px;
  height: 80px;
  padding-right: 35px;
`;

const CollectionYear = styled.div`
  font-size: 20px;
`;
const CollectionDescription = styled.div`
  font-size: 16px;
  line-height: 1.5;
  height: 80px;
`;

const StyledLink = styled(Link)`
  color: ${colors.vermillion};
  text-decoration: none;
  border-bottom: 2px solid ${colors.vermillon};
  text-transform: uppercase;
  padding-bottom: 4px;
  font-family: SlateProRegular;
  font-size: 11px;
  letter-spacing: 0.2em;
`;

export default EventSatchelCollections;
