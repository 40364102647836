import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import NameInitialsCircle from "../profile/NameInitialsCircle";
import {getCurrentUserFullName, getCurrentUserInitials} from "services/user-utils";

const MemberInky = ({ member, small = false }) => {


  return (
    <MemberInkyContainer>
      <NameInitialsCircle
        radius={200}
        imageSource={member.picture}
        initials={getCurrentUserInitials(member)}
        fontSize={60}
      />
      <PersonName>{getCurrentUserFullName(member)}</PersonName>
      {member.joined && <MemberSince>Since {member.joined.toLocaleString()}</MemberSince> }
    </MemberInkyContainer>
  );
};

const MemberInkyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
`;

const PersonName = styled.span`
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: SangBleuVersaillesBook;
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: ${colors.warmGraphite};
  text-transform: capitalize;
`;

const MemberSince = styled.p`
  font-family: SangBleuVersaillesBook;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${colors.gray};
`;

export default MemberInky;
