import React from "react";
import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";
import {H5} from "components/shared/style-guide/typography";

const AccountSection = ({ label, children }) => {
  return (
    <SectionWrapper>
      <Fieldset>
        <Legend>
          <H5>{label}</H5>
        </Legend>
      </Fieldset>
      {children}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  color: ${colors.coolGray};
`;

const Fieldset = styled.fieldset`
  border-top: 1px solid ${colors.lightGray};
  margin-top: 45px;
  margin-bottom: 30px;
`;

const Legend = styled.legend`
  padding-right: 10px;
  text-transform: uppercase;
`;

export default AccountSection;
