import React from "react";
import AppliedFilters from "./AppliedFilters";
import propTypes from "prop-types";
import ResetLink from "./ResetFiltersLink";

const Filters = ({ values, onClear, onReset }) => {
  return (
    <>
      {!!values.length && (
        <>
          <AppliedFilters filters={values} onClose={onClear} />
          <ResetLink onReset={onReset} />
        </>
      )}
    </>
  );
};

Filters.defaultProps = {
  values: []
};

Filters.propTypes = {
  values: propTypes.array,
  onRemoveFilter: propTypes.func,
  onReset: propTypes.func
};

export default Filters;
