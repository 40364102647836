import Icon from "components/shared/Icon";
import {colors} from "config/StyleGuide";
import React from "react";
import styled from "@emotion/styled";

const ButtonWithArrow = ({ label }) => {
  return (
    <Wrapper>
      {label}
      <ButtonArrow
        type="CollectionArrow"
        fill={colors.vermillion}
        stroke={colors.vermillion}
        size={18}
      />
    </Wrapper>
  );
};

const ButtonArrow = styled(Icon)`
  margin-left: 6px;
  margin-top: 2px;
`;

const Wrapper = styled.div`
  font-family: SlateProRegular;
  font-size: 11px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.vermillion};
  display: flex;
  align-items: center;
`;

export default ButtonWithArrow;
