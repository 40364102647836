import styled from "@emotion/styled";
import {colors} from "config/StyleGuide";

const profileBoxBorder = `2px solid ${colors.stone}`;

export const ProfileBox = styled.div`
  border: ${profileBoxBorder};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const ProfileBoxLeftSide = styled.div`
  width: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ProfileBoxRightSide = styled.div`
  border-left: ${profileBoxBorder};
  flex: 1;
`;

export const Title = styled.div`
  font-family: SlateProMedium;
  font-size: 18px;
  color: ${colors.coolGray};
  letter-spacing: 0.2em;
`;

// export const FormLabel = styled.div`
//   font-family: SlateProRegular;
//   color: ${colors.coolGray};
//   text-transform: uppercase;
//   font-size: 12px;
//   letter-spacing: 2px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   &:after {
//     content: ${({ required }) => (required ? "*" : "")};
//   }
// `;
