import React, {useRef} from "react";
import {PageContainer, PageSideBar, Row, RowOverlay} from "components/shared/Layouts";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import styled from "@emotion/styled";
import PeopleFilters from "./PeopleFilters";
import Filters from "../../shared/filters/Filters";
import {useCollection} from "../../../hooks/useCollection";
import ScrollableInfiniteScroll from "components/shared/ScrollableInfiniteScroll";
import {useFacetsFilters} from "../../../hooks/filters/useFacetFilters";
import {useQueryFilter} from "../../../hooks/filters/useQueryFilter";
import PeopleInkyGrid from "./shared/PeopleInkyGrid";
import peopleHeader from "./shared/people-header.png";
import AutoComplete from "../../shared/auto-complete/AutoComplete";
import {fetchSuggestions} from "../../../services/fetch-suggestions";
import {noop, shuffle, withScroll} from "../../../services/utils";
import LoadingMediaCards from "../../shared/LoadingMediaCards";

const People = ({location}) => {
  useBreadCrumbs({label: "People", path: location.pathname});
  const firstLoad = useRef(true);

  const {searchQueryString, setSearchQuery} = useQueryFilter();

  const {
    clearFilter,
    resetFilters,
    setFilter,
    isActiveFilter,
    facetFilters,
    facetsQueryString
  } = useFacetsFilters(noop);
  //
  const query = [facetsQueryString, searchQueryString].join("&");
  //
  const {collection: people, loadMore, hasMore, initialLoading} = useCollection(
      "people",
      query
  );

  const shufflePeople = (peopleArray) => {
    if(peopleArray.length > 0 && firstLoad.current) {
       firstLoad.current = false;
       return shuffle(peopleArray);
    } else {
      return peopleArray;
    }
  };

  return (
      <PageContainer>
        <PeopleHeaderBg src={peopleHeader} alt=""/>

        <RowOverlay>
          <Col className="col-4 offset-4">
            <SearchWrapper>
              <AutoComplete
                  fetchSuggestions={fetchSuggestions("people/autocomplete")}
                  labelKey="display_name"
                  onSubmit={setSearchQuery}
                  placeholder="Search all people"
              />
            </SearchWrapper>
          </Col>
        </RowOverlay>
        <Row>
          <div className="col-2 ml-3 pr-0">
            <Filters
                values={facetFilters}
                onClear={clearFilter}
                onReset={resetFilters}
            />

            <PeopleFilters
                people={shufflePeople(people)}
                onAdd={withScroll(setFilter, 350)}
                onClear={clearFilter}
                isActive={isActiveFilter}
            />
          </div>


          <div className="col-7 offset-1 p-0">
            <div className="row">
              {initialLoading ? <LoadingMediaCards/> : (
                  <ScrollableInfiniteScroll
                      key={query}
                      hasMore={hasMore}
                      loadMore={loadMore}
                      useWindow={true}
                  >
                    <PeopleInkyGrid people={people}/>
                  </ScrollableInfiniteScroll>
              )}
            </div>
          </div>

          <PageSideBar/>
        </Row>
      </PageContainer>
  );
};

const Col = styled.div`
  position: relative;
`;

const PeopleHeaderBg = styled.img`
  margin: 50px 0 90px 0;
`;

const SearchWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 190px;
  left: 0;
  z-index: 1000;
`;

export default People;
