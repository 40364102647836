import React from "react";
import {UnstyledLink} from "components/shared/UnstyledLink";
import styled from "@emotion/styled";
import ClanMembersSlider from "./ClanMembersSlider";
import {colors} from "../../../config/StyleGuide";

const ClanMembers = ({ clan }) => {
  return (
    <Wrapper>
      <ClanMembersSlider clan={clan} />
      <AlignSelfRight>
        <UnstyledLink to={`/search/people?query=${clan.display_name}`}>
          {/*<ButtonWithArrow label="SEE ALL  CLAN MEMBERS" />*/}
        </UnstyledLink>
      </AlignSelfRight>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.pink};
`;

const AlignSelfRight = styled.div`
  margin-top: 40px;
  align-self: flex-end;
`;

export default ClanMembers;
