import React from "react";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import StripLegend, {Section, YearLabel} from "./StripLegend";
import {RowOverlay} from "../../../shared/Layouts";
import StripContainer from "./StripContainer";
import {useStaticMediaFiles} from "hooks/useStaticMediaFiles";

const JustMarriedImageWrapper = ({ children }) => (
  <div style={{ marginRight: "20px" }}>
    <ImageWrapper>{children}</ImageWrapper>
  </div>
);

const FILES_IDS = [2861, 2849, 2845, 2860];

// todo nir: fix background color
const JustMarried = () => {
  const [MediaViewer, Files] = useStaticMediaFiles(FILES_IDS);
  const [File0, File1, File2, File3] = Files;

  return (
    <StripContainer backgroundColor="#be9e88">
      <MediaViewer />
      <RowOverlay>
        <span id={"justMarried"}/>
        <div className="col-1 offset-9" style={{ marginTop: "475px" }} >
          <StripLegend title="Just Married" color={colors.white} anchor="justMarried">
            <Section>
              <div>left to right:</div>
              <div>Winnie & Ben</div>
              <YearLabel>1996</YearLabel>
            </Section>
            <Section>
              <div>Eric & Norma</div>
              <YearLabel>1957</YearLabel>
            </Section>
            <Section>
              <div>Abraham & Sara</div>
              <YearLabel>1929</YearLabel>
            </Section>
            <Section>
              <div>Marc & Ethel</div>
              <YearLabel>1994</YearLabel>
            </Section>
          </StripLegend>
        </div>
      </RowOverlay>
      <RowOverlay>
        <div
          className="col-10 offset-1"
          style={{ marginTop: "160px", marginLeft: "40px" }}
        >
          <ImagesWrapper>
            <div style={{ display: "flex", marginTop: "150px" }}>
              <JustMarriedImageWrapper>
                <File0 />
              </JustMarriedImageWrapper>
              <JustMarriedImageWrapper>
                <File1 />
              </JustMarriedImageWrapper>
            </div>

            <JustMarriedImageWrapper>
              <File2 />
            </JustMarriedImageWrapper>
            <JustMarriedImageWrapper>
              <File3 />
            </JustMarriedImageWrapper>
          </ImagesWrapper>
        </div>
      </RowOverlay>
    </StripContainer>
  );
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  width: 290px;
`;

export default JustMarried;
