import React from "react";
import {PageBackground, PageContainer, Row} from "components/shared/Layouts";
import {useResource} from "../../../hooks/useResource";
import Details from "./FileDetails";
import FilePreview from "./FilePreview";
import FileOptions from "./FileOptions";
import {useBreadCrumbs} from "../../../hooks/useBreadCrumbs";

const MediaFile = ({ match, location }) => {
  const fileId = match.params.id;
  const [media_file] = useResource({ path: `media_files/${fileId}` });

  useBreadCrumbs({ label: media_file, path: location.pathname });

  if (!media_file) return null;

  return (
    <div>
      <PageBackground>
        <PageContainer>
          <div>
            <Row>
              <FilePreview mediaFile={media_file} />
              <Details mediaFile={media_file} />
              <FileOptions mediaFile={media_file} />
            </Row>
          </div>
        </PageContainer>
      </PageBackground>
    </div>
  );
};

export default MediaFile;
