import React from "react";
import {css} from "@emotion/core";
import styled from "@emotion/styled";

const getActualHeight = ({ height }) => (height ? `${height}px` : "900px");

const StripContainer = ({ children, backgroundColor, height, id }) => (
  <StyledContainer backgroundColor={backgroundColor} height={height} id={id} >
    {children}
    <FullBackground backgroundColor={backgroundColor} />
  </StyledContainer>
);

const FullBackground = styled.div`
  ${({ backgroundColor, img }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
      position: absolute;      
      left: calc((-100vw + 1400px) / 2);
      width: 100vw;
      top: 0;
      height: 100%;
    `};
`;

const StyledContainer = styled.div`
  display: block;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
  height: ${getActualHeight};
  position: relative;
`;

export default StripContainer;
