import React from "react";
import {LoaderContainer, PageBackground, PageContainer, PageRowWithVerticalPadding} from "components/shared/Layouts";
import {useResource} from "hooks/useResource";
import {useBreadCrumbs} from "hooks/useBreadCrumbs";
import {useCollection} from "../../../hooks/useCollection";
import MediaFilesGrid from "../../shared/MediaFiles/MediaFilesGrid";
import {useGenreFilters} from "../../../hooks/filters/useGenreFilters";
import EventCollectionHeader from "./EventCollectionHeader";
import background from "../auth/shared/auth-bacground.png";
import {colors} from "../../../config/StyleGuide";
import {ENTITIES} from "../../../config/constants";
import {useDynamicGenres} from "../../../hooks/filters/useDynamicGenres";
import Loading from "../../shared/Loading";

const Event = ({ match, location }) => {
  const eventId = match.params.id;
  const [event] = useResource({ path: `/events/${eventId}` });

  useBreadCrumbs({ label: event, path: location.pathname });

  const {
    GenreFilters,
    genresQueryString,
    updateAvailableGenres,
    resetGenreFilters
  } = useGenreFilters({
    path: `/events/genres?event_id=${eventId}`
  });

  const buildQuery = (...queries) => queries.join("&");

  const query = buildQuery(
    genresQueryString,
    `${ENTITIES.EVENT}_id=${eventId}`
  );

  const {
    collection: mediaFiles,
    loadMore,
    hasMore,
    isLoading,
    genres
  } = useCollection("/events/media", query);

  useDynamicGenres([], genres, updateAvailableGenres, resetGenreFilters);

  if (!event || !mediaFiles.length) return (
      <LoaderContainer>
        <Loading/>
      </LoaderContainer>
  );

  return (
    <PageBackground>
      <PageContainer>
        <EventCollectionHeader
          event={event}
          backgroundImage={background}
          backgroundColor={colors.ink}
          textColor={colors.white}
        />

        <PageRowWithVerticalPadding>
          <div className="col-2" />

          <MediaFilesGrid
            mediaFiles={mediaFiles}
            hasMore={hasMore}
            loadMore={loadMore}
            loading={isLoading}
          >
            <GenreFilters />
          </MediaFilesGrid>
        </PageRowWithVerticalPadding>
      </PageContainer>
    </PageBackground>
  );
};

export default Event;
