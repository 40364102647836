import React, {useState} from "react";
import {isEmpty} from "lodash/fp";
import {colors} from "config/StyleGuide";
import styled from "@emotion/styled";
import {openInNewWindow, Wrapper} from "./link-utils";

const BookAndText = ({
  bookPhoto,
  year,
  alignText,
  width,
  hoverTextLines,
  imageId
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Wrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      width={width}
      alignText={alignText}
      onClick={() => openInNewWindow(imageId)}
    >
      <BookImage src={bookPhoto} />
      <Year alignText={alignText}>{year}</Year>
      {isHovered && !isEmpty(hoverTextLines) && (
        <HoverTextWrapper>
          {hoverTextLines.map((textLine, index) =>
            textLine ? (
              <div key={textLine || index}>{textLine}</div>
            ) : (
              <EmptyLine>___</EmptyLine>
            )
          )}
        </HoverTextWrapper>
      )}
    </Wrapper>
  );
};

BookAndText.defaultProps = {
  alignText: "right"
};

const EmptyLine = styled.div`
  visibility: hidden;
`;

const BookImage = styled.img`
  width: 100%;
  height: auto;
`;

const Year = styled.div`
  font-size: 28px;
  font-family: SangBleuVersaillesRegular;
  transform: ${({ alignText }) =>
    alignText === "right"
      ? "rotate(-90deg) translate(-50%, -50%)"
      : "rotate(-90deg) translate(-50%, 50%)"};
`;

// todo nir: fix line height and font size (i do not see them on the design)
const HoverTextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  padding: 0 20px;
`;

export default BookAndText;
